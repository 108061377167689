import { Button, TextField, Typography } from "@mui/material";
import React from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import useStyles from "../style";

import { useAdminOperations } from "../../../hooks";

type Inputs = {
    voters: string;
};

type Props = {
    handleClose: () => void;
};

const MinimumVotations: React.FC<Props> = ({ handleClose }) => {
    const classes = useStyles();

    const { createChangeThresholdRequest } = useAdminOperations();

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<Inputs>();

    const onSubmit: SubmitHandler<Inputs> = async data => {
        if (createChangeThresholdRequest) {
            const newThreshold = parseInt(data.voters);
            await createChangeThresholdRequest(newThreshold);
            handleClose();
        }
    };

    return (
        <form
            onSubmit={handleSubmit(onSubmit)}
            className={classes.flexContainer}
        >
            <div className={classes.inputsContainer}>
                <div className={classes.inputFullBlock}>
                    <TextField
                        fullWidth
                        label="Numero votanti"
                        id="fullWidth"
                        type={"number"}
                        InputProps={{
                            inputProps: { min: 1 },
                        }}
                        {...register("voters", { required: true })}
                    />

                    <Typography
                        className={classes.error}
                        variant="h6"
                        gutterBottom
                        component="div"
                    >
                        {errors.voters && <span>This field is required</span>}
                    </Typography>
                </div>
                <div className={classes.inputsContainer}>
                    <Button
                        variant="contained"
                        type="submit"
                        className={classes.button}
                    >
                        Invia richiesta
                    </Button>
                </div>
            </div>
        </form>
    );
};

export default MinimumVotations;
