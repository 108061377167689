import {
    Button,
    Chip,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@mui/material";
import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { useDiscounts } from "../../../hooks";
import ModalCreateDiscount from "./ModalCreateDiscount";
import useStyles from "./style";

type Props = {
    showCreateDiscountButton: boolean;
};

const DiscountsTable: React.FC<Props> = ({ showCreateDiscountButton }) => {
    const classes = useStyles();
    const [showCreateDiscount, setShowCreateDiscount] = useState(false);

    const currentPath = useLocation().pathname;
    const splittedPath = currentPath.split("/");
    const address = splittedPath[4];

    const { offers } = useDiscounts(address);

    return (
        <>
            {showCreateDiscount ? (
                <ModalCreateDiscount
                    open={showCreateDiscount}
                    handleClose={() => setShowCreateDiscount(false)}
                    merchantAddress={address}
                />
            ) : (
                <>
                    <TableContainer>
                        <Table className={classes.table}>
                            <TableHead>
                                <TableRow className={classes.tableRowHeader}>
                                    <TableCell className={classes.tableCell}>
                                        ID
                                    </TableCell>
                                    <TableCell className={classes.tableCell}>
                                        Sconto
                                    </TableCell>
                                    <TableCell className={classes.tableCell}>
                                        Livello VIP
                                    </TableCell>
                                    <TableCell className={classes.tableCell}>
                                        Descrizione
                                    </TableCell>
                                    <TableCell className={classes.tableCell}>
                                        Data di scadenza
                                    </TableCell>
                                    <TableCell className={classes.tableCell}>
                                        Stato
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {offers.map((row: any) => (
                                    <TableRow
                                        key={row.offerId}
                                        sx={{
                                            "&:last-child td, &:last-child th":
                                                {
                                                    border: 0,
                                                },
                                        }}
                                    >
                                        <TableCell>{row.offerId}</TableCell>
                                        <TableCell>
                                            {row.discountAmount}
                                        </TableCell>
                                        <TableCell>{row.vipLevel}</TableCell>
                                        <TableCell>{row.description}</TableCell>
                                        <TableCell>
                                            {row.expirationDate}
                                        </TableCell>
                                        <TableCell>
                                            {row.status ? (
                                                <Chip
                                                    label="Scaduto"
                                                    color="error"
                                                />
                                            ) : (
                                                <Chip
                                                    label="Valido"
                                                    color="success"
                                                />
                                            )}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    {showCreateDiscountButton && (
                        <div className={classes.buttonContainer}>
                            <Button
                                variant="contained"
                                onClick={() =>
                                    setShowCreateDiscount(
                                        prevState => !prevState,
                                    )
                                }
                                className={classes.button}
                            >
                                Aggiungi sconto
                            </Button>
                        </div>
                    )}
                </>
            )}
        </>
    );
};

export default DiscountsTable;
