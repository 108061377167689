import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { User } from "../../store/user/types";

import { ethers } from "ethers";
import { toast } from "react-toastify";

import { provider } from "../../constants";
import { VipLevel } from "../../utils/types";

import { vipStatus, mitToken } from "../../contracts";

const vipStatusContract = new ethers.Contract(
    vipStatus.contract_address,
    vipStatus.abi,
    provider,
);

const mitTokenContract = new ethers.Contract(
    mitToken.contract_address,
    mitToken.abi,
    provider,
);

const overrides = {
    gasLimit: 4000000,
    gasPrice: 0,
};

type InitializationValues = {
    vipLevels: VipLevel[];
    lockingPeriod: number;
};

const defaultInitializationValues = {
    vipLevels: [],
    lockingPeriod: 0,
};

const useManageVipStatus = () => {
    const [loader, setLoader] = useState(false);
    const [initializationValues, setInitializationValues] =
        useState<InitializationValues>(defaultInitializationValues);

    const { vipLevels, lockingPeriod } = initializationValues;

    const user = useSelector(({ user }: { user: User }) => user);

    const {
        wallet: { privateKey },
    } = user;

    if (!privateKey) {
        toast.error(
            "La chiave privata non é valida, le operazioni sulla blockchain sono bloccate",
            {
                toastId: "privateKeyError",
            },
        );
        return {
            ...defaultInitializationValues,
            createVipLevel: () => null,
            editVipLevel: () => null,
            deleteVipLevel: () => null,
        };
    }

    const signerWallet = new ethers.Wallet(privateKey, provider);
    const contractWithSigner = vipStatusContract.connect(signerWallet);

    // Get locking period from the blockchain
    const getLockingPeriod = async () => {
        const lockingPeriod = await mitTokenContract.getLockingPeriod();
        const formattedLockingPeriod = lockingPeriod.toNumber();
        return formattedLockingPeriod;
    };

    // Get vip levels from the blockchain
    const getVipLevels = async () => {
        const loadingToast = toast.loading("Caricamento dati in corso");
        const vipLevels = await vipStatusContract.getVipLevels();
        const formattedVipLevels = vipLevels.map(
            (vipLevel: VipLevel, i: number) => {
                const threshold = parseInt(
                    ethers.utils.formatEther(vipLevel.threshold.toString()),
                    10,
                );
                return {
                    level: i,
                    name: vipLevel.name,
                    threshold,
                };
            },
        );
        formattedVipLevels.shift();

        toast.dismiss(loadingToast);

        return formattedVipLevels;
    };

    const initialize = async () => {
        const initializationValues = await Promise.all([
            getVipLevels(),
            getLockingPeriod(),
        ]);

        setInitializationValues({
            vipLevels: initializationValues[0],
            lockingPeriod: initializationValues[1],
        });
    };

    useEffect(() => {
        // const controller = new AbortController();
        initialize();

        // Events listeners
        vipStatusContract.on("NewVipLevelCreated", async () => {
            initialize();
        });
        vipStatusContract.on("VipLevelUpdated", async () => {
            initialize();
        });
        vipStatusContract.on("VipLevelDeleted", async () => {
            initialize();
        });

        mitTokenContract.on("LockingPeriodSet", async () => {
            initialize();
        });

        return () => {
            vipStatusContract.removeAllListeners();
            mitTokenContract.removeAllListeners();
            // controller.abort();
        };
    }, []);

    // Function to create new vip level
    const createVipLevel = async (name: string, threshold: number) => {
        if (loader) {
            toast.warning("Attendere l'operazione in corso");
            return;
        }

        setLoader(true);
        const loadingToast = toast.loading("Operazione in corso");

        const formattedThreshold = ethers.utils
            .parseUnits(threshold.toString())
            .toString();

        const txn = await contractWithSigner.createVipLevel(
            name,
            formattedThreshold,
            overrides,
        );

        try {
            await txn.wait();

            toast.success(`Vip level ${name} creato con successo!`);
        } catch {
            toast.error(
                `Errore durante la creazione del vip level ${name}! Riprova più tardi.`,
            );
        }

        toast.dismiss(loadingToast);
        setLoader(false);
    };

    // Function to update a vip Level
    const editVipLevel = async (
        index: number,
        name: string,
        threshold: number,
    ) => {
        if (loader) {
            toast.warning("Attendere l'operazione in corso");
            return;
        }

        setLoader(true);
        const loadingToast = toast.loading("Operazione in corso");

        const formattedIndex = index + 1;

        const formattedThreshold = ethers.utils
            .parseUnits(threshold.toString())
            .toString();

        const txn = await contractWithSigner.updateVipLevel(
            formattedIndex,
            name,
            formattedThreshold,
            overrides,
        );

        try {
            await txn.wait();

            toast.success(`Vip level ${name} aggiornato!`);
        } catch {
            toast.error(
                `Errore durante l'aggiornamento del vip level ${name}! Riprova più tardi.`,
            );
        }

        toast.dismiss(loadingToast);
        setLoader(false);
    };

    // Function to delete the last vip level
    const deleteVipLevel = async (name: string) => {
        if (loader) {
            toast.warning("Attendere l'operazione in corso");
            return;
        }

        setLoader(true);
        const loadingToast = toast.loading("Operazione in corso");

        const txn = await contractWithSigner.deleteVipLevel();

        try {
            await txn.wait();

            toast.success(`Vip level ${name} eliminato!`);
        } catch {
            toast.error(
                `Errore durante l'eliminazione del vip level ${name}! Riprova più tardi.`,
            );
        }

        toast.dismiss(loadingToast);
        setLoader(false);
    };

    // Get current locked amount from the blockchain
    const getLockInfo = async (address: string) => {
        const lockInfo = await mitTokenContract.stakes(address);
        const formattedLockInfo = {
            lockDate: lockInfo.stakeDate.toNumber(),
            amount: parseInt(
                ethers.utils.formatEther(lockInfo.value.toString()),
                10,
            ),
        };
        return formattedLockInfo;
    };

    // Function to calculate current vip level
    const calculateCurrentVipLevel = async (address: string) => {
        const lockInfo = await getLockInfo(address);

        const currentLevel =
            vipLevels.find(vipLevel => vipLevel.threshold === lockInfo.amount)
                ?.level || 0;

        return currentLevel;
    };

    return {
        loader,
        vipLevels,
        lockingPeriod,
        createVipLevel,
        editVipLevel,
        deleteVipLevel,
        calculateCurrentVipLevel,
    };
};

export default useManageVipStatus;
