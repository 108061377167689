import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@mui/material";
import { useLocation } from "react-router-dom";
import React, { useEffect } from "react";
import useStyles from "./style";
import { useSelector } from "react-redux";
import { getUsersState } from "../../../store/users/users.slice";

import { useAppDispatch } from "../../../utils/hooks";
import { fetchPayments, resetPayments } from "../../../store/users/users.slice";

const PurchaseTable: React.FC = () => {
    const classes = useStyles();

    const currentPath = useLocation().pathname;
    const splittedPath = currentPath.split("/");
    const accountTypology = splittedPath[2];
    const address = splittedPath[4];

    const dispatch = useAppDispatch();

    const { list: users } = useSelector(getUsersState);
    const user = users.find(user => user.walletAddress === address);

    const collectionName =
        accountTypology === "merchant" ? "merchants" : "consumers";

    useEffect(() => {
        if (user?.id) {
            const fetchData = { collectionName, userId: user.id };
            dispatch(fetchPayments(fetchData));
        }
        return () => {
            if (user?.id) {
                dispatch(resetPayments(user.id));
            }
        };
    }, []);

    return (
        <>
            <TableContainer>
                <Table className={classes.table}>
                    <TableHead>
                        <TableRow className={classes.tableRowHeader}>
                            <TableCell className={classes.tableCell}>
                                ID Transazione
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                                Modalità pagamento
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                                Importo MIT
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                                Importo Euro
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                                Data
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {user?.payments.map((row: any) => (
                            <TableRow
                                key={row.transactionId}
                                sx={{
                                    "&:last-child td, &:last-child th": {
                                        border: 0,
                                    },
                                }}
                            >
                                <TableCell>{row.transactionId}</TableCell>
                                <TableCell>{row.paymentMethod}</TableCell>
                                <TableCell>{row.mitAmount}</TableCell>
                                <TableCell>{row.euroAmount}</TableCell>
                                <TableCell>{row.date}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
};

export default PurchaseTable;
