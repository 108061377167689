import CloseIcon from "@mui/icons-material/Close";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Fab from "@mui/material/Fab";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import React from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import useStyles from "./style";

type Filter = {
    key: string;
    text: string;
    inputType: string;
};

type FilterBarProps = {
    filters: Filter[];
    setFilters: (filters: any) => void;
};

const FilterBar: React.FC<FilterBarProps> = ({ filters, setFilters }) => {
    const classes = useStyles();

    const inputs = {} as any;
    filters.forEach(filter => {
        inputs[filter.key] = "string";
    });

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
    } = useForm<typeof inputs>();

    const onSubmit: SubmitHandler<typeof inputs> = data => {
        setFilters(data);
    };

    const resetFilters = () => {
        reset({
            address: "",
            name: "",
            email: "",
        });
        setFilters({});
    };

    return (
        <div>
            <Box
                sx={{
                    maxWidth: "100%",
                    marginBottom: "20px",
                    marginTop: "30px",
                }}
            >
                <form
                    onSubmit={handleSubmit(onSubmit)}
                    className={classes.flexContainer}
                >
                    <div className={classes.inputsContainer}>
                        {filters.map(filter => (
                            <div
                                key={filter.key}
                                className={classes.inputBlock}
                            >
                                <TextField
                                    fullWidth
                                    label={filter.text}
                                    {...register(filter.key)}
                                />

                                <Typography
                                    sx={{
                                        color: "red",
                                        marginBottom: 0,
                                        marginTop: "10px",
                                        fontSize: "15px",
                                        width: "100%",
                                    }}
                                    variant="h6"
                                    gutterBottom
                                    component="div"
                                >
                                    {errors.jwtToken && (
                                        <span>This field is required</span>
                                    )}
                                </Typography>
                            </div>
                        ))}
                    </div>

                    <div className={classes.flexContainer}>
                        <Button
                            sx={{ color: "#ffff", marginRight: "20px" }}
                            variant="contained"
                            type="submit"
                        >
                            Applica filtri
                        </Button>
                        <Fab
                            color="primary"
                            aria-label="add"
                            onClick={resetFilters}
                            sx={{
                                width: "38px",
                                height: "38px",
                            }}
                        >
                            <CloseIcon
                                sx={{
                                    fontSize: "28px",
                                    cursor: "pointer",
                                    fill: "#ffffff",
                                }}
                            />
                        </Fab>
                    </div>
                </form>
            </Box>
        </div>
    );
};
export default FilterBar;
