import { createTheme } from "@mui/material/styles";
// A custom theme for this app
const theme = {
    palette: {
        primary: {
            main: "#44c1be",
            light: "#ffffff",
        },
        error: {
            main: "#ff4d4d",
            light: "rgba(255, 0, 0, 0.2)",
        },
    },
    drawerWidth: 280,
} as const;

type CustomTheme = {
    [Key in keyof typeof theme]: typeof theme[Key];
};
declare module "@mui/material/styles/createTheme" {
    interface Theme extends CustomTheme {}
    interface ThemeOptions extends CustomTheme {}
}
export default createTheme(theme);
