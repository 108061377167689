import React, { useEffect, useState } from "react";

import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Tab,
    Tabs,
} from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import PersonalDataForm from "../../components/InfoWalletForms/PersonalDataForm";
import RegulationsForm from "../../components/InfoWalletForms/RegulationsForm";
import DiscountsTable from "../../components/InfoWalletForms/DiscountsTable";
import { TabContext, TabPanel } from "@mui/lab";
import { useLocation, useNavigate } from "react-router-dom";
import PurchaseTable from "../../components/InfoWalletForms/Wallet/PurchaseTable";
import NoteDocuments from "../../components/InfoWalletForms/NoteDocuments";
import TransactionsTable from "../../components/InfoWalletForms/Wallet/TransactionsTable";
import CompanyInfoForm from "../../components/InfoWalletForms/CompanyInfoForm";
import CheckKycBanner from "../../components/CheckKycBanner";
import FormDialog from "../../components/MTFormDialog";
import ShowCopy from "../../components/ShowCopy";

import useManageUser from "../../hooks/blockchain/useManageUser";
import { useManageVipStatus } from "../../hooks";

import useStyles from "./style";

import { useSelector } from "react-redux";
import { getUsersState } from "../../store/users/users.slice";
import { doc, getDoc } from "firebase/firestore";
import { firestore } from "../../firebase";
import { useCallbackState } from "../../hooks";
import DocumentForm from "../../components/InfoWalletForms/DocumentForm";
import { useSendEmailNotificationApiMutation } from "../../services/firestoreRestApi";
import { toast } from "react-toastify";

type AccordionType =
    | "personalData"
    | "document"
    | "companyData"
    | "discount"
    | "wallet"
    | "kyc"
    | "noteDocuments"
    | "";

type Promotion = {
    userUid: string;
    promotionTier: number;
};

const UserDetail: React.FC = () => {
    const classes = useStyles();
    const [activeTab, setActiveTab] = useState("president");
    const [notes, setNotes] = useCallbackState("");
    const [notesLoader, setNotesLoader] = useState(true);
    const [activeWalletTab, setActiveWalletTab] = useState("transactions");
    const [activeAccordion, setActiveAccordion] = useState<AccordionType>("");
    const [promotion, setPromotion] = useState<Promotion>({
        userUid: "",
        promotionTier: 0,
    });
    const [rejection, setRejection] = useState({
        userUid: "",
        tierToReject: 0,
    });
    const [request, setRequest] = useState({
        userUid: "",
    });

    const [currentVipLevel, setCurrentVipLevel] = useState<number>(0);

    const navigate = useNavigate();

    const [sendEmailNotification] = useSendEmailNotificationApiMutation();

    const { promoteTier, rejectTier } = useManageUser();
    const { calculateCurrentVipLevel, vipLevels } = useManageVipStatus();

    const currentPath = useLocation().pathname;
    const splittedPath = currentPath.split("/");
    const accountTypology = splittedPath[2];
    const address = splittedPath[4];

    const { list: users, loader } = useSelector(getUsersState);
    const user = users.find(user => user.walletAddress === address);

    const tierToPromote =
        user?.tierOne && !user.tierTwo
            ? 1
            : user?.tierOne && user.tierTwo
            ? 2
            : 0;

    const checkCitizenship = (citizenship: string) =>
        citizenship === "Italian" || citizenship === "Italiana";

    const promoteUserTier = () => {
        if (user?.id && promoteTier) {
            const collectionRef =
                accountTypology === "merchant" ? "merchants" : "consumers";

            let lang = "en";
            if (user["tierOne"]) {
                lang = checkCitizenship(user?.tierOne?.citizenship)
                    ? "it"
                    : "en";
            }

            if (user?.tierOne !== undefined) {
                promoteTier(user.id, collectionRef, tierToPromote, lang);
            }
        }

        closeDialog();
    };

    const rejectUserTier = async () => {
        if (user && rejectTier) {
            const collectionRef =
                accountTypology === "merchant" ? "merchants" : "consumers";

            let lang = "en";
            if (user["tierOne"]) {
                lang = checkCitizenship(user?.tierOne?.citizenship)
                    ? "it"
                    : "en";
            }

            if (user?.tier !== undefined && user?.tierOne !== undefined) {
                await rejectTier(
                    user.id,
                    collectionRef,
                    tierToPromote,
                    user?.tier,
                    user?.tierOne?.email,
                    lang,
                );
            }

            if (tierToPromote === 1) {
                navigate("/users");
            }
        }
        closeDialog();
    };

    const requestCall = async () => {
        try {
            if (user) {
                console.log(user);
                sendEmailNotification({
                    notificationType: `callRequest_it`,
                    uid: user.id,
                });
                console.log(user.tierOne?.email);
                toast.success("Email inviata!");
            } else {
                toast.error("Email non presente!");
            }
        } catch (e) {
            toast.success("Email non inviata!");
        }

        closeDialog();
    };

    const closeDialog = () => {
        setPromotion({ userUid: "", promotionTier: 0 });
        setRejection({ userUid: "", tierToReject: 0 });
        setRequest({ userUid: "" });
    };

    const handleChangeTab = (event: React.SyntheticEvent, newValue: string) => {
        newValue === "delegate" || newValue === "president"
            ? setActiveTab(newValue)
            : setActiveWalletTab(newValue);
    };

    const handleChangeAccordion = (label: AccordionType) => () => {
        setActiveAccordion(activeAccordion === label ? "" : label);
    };

    const getNotesForUser = async () => {
        if (user && user["id"]) {
            const docRef = doc(firestore, "adminNotes", user?.id);
            const docSnap = await getDoc(docRef);
            const data = docSnap.data();
            const userNotes = data?.notes.note || "";
            if (userNotes) {
                setNotes(userNotes, () => {
                    setNotesLoader(false);
                });
            } else {
                setNotesLoader(false);
            }
        }
    };

    const getCurrentVipLevel = async () => {
        if (calculateCurrentVipLevel && user?.walletAddress) {
            const currentVipLevel = await calculateCurrentVipLevel(
                user?.walletAddress,
            );

            setCurrentVipLevel(currentVipLevel);
        }
    };

    useEffect(() => {
        getNotesForUser();
    }, [user]);

    useEffect(() => {
        getCurrentVipLevel();
    }, [vipLevels, user]);

    return notesLoader || loader || !user ? (
        <div></div>
    ) : (
        <>
            <>
                <Box>
                    <div className={classes.flexEndContainer}>
                        <Box className={classes.mitAmountContainer}>
                            <div className={classes.titleMitAmount}>
                                Address wallet
                            </div>
                            <div className={classes.mitAmount}>
                                <ShowCopy valueToCopy={address} />
                            </div>
                        </Box>
                        <Box className={classes.mitAmountContainer}>
                            <div className={classes.titleMitAmount}>
                                Saldo wallet
                            </div>
                            <div className={classes.mitAmount}>
                                {user.mitBalance} MIT
                            </div>
                        </Box>
                        <Box className={classes.mitAmountContainer}>
                            <div className={classes.titleMitAmount}>Tier</div>
                            <div className={classes.mitAmount}>{user.tier}</div>
                        </Box>
                        {user.category === "consumer" && (
                            <Box className={classes.mitAmountContainer}>
                                <div className={classes.titleMitAmount}>
                                    Vip level
                                </div>
                                <div className={classes.mitAmount}>
                                    {currentVipLevel}
                                </div>
                            </Box>
                        )}
                    </div>
                    <Accordion
                        sx={{
                            backgroundColor: "#f4f4f4",
                            boxShadow: "none",
                        }}
                        onChange={handleChangeAccordion("personalData")}
                        expanded={activeAccordion === "personalData"}
                    >
                        <AccordionSummary
                            expandIcon={
                                <ExpandMoreIcon sx={{ color: "#44c1be" }} />
                            }
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography
                                sx={{
                                    color: "#44c1be",
                                    fontWeight: "bold",
                                }}
                            >
                                Dati anagrafici
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            {user.category === "merchant" &&
                            user.tierTwo?.beneficialOwner ? (
                                <TabContext value={activeTab}>
                                    <Tabs
                                        value={activeTab}
                                        onChange={handleChangeTab}
                                        variant="fullWidth"
                                    >
                                        <Tab
                                            label="Soggetto Tier One"
                                            value="president"
                                        />
                                        <Tab
                                            label="Soggetto Tier Two"
                                            value="delegate"
                                        />
                                    </Tabs>
                                    <TabPanel value="delegate">
                                        <PersonalDataForm
                                            userData={
                                                user.tierTwo?.beneficialOwner
                                            }
                                        />
                                    </TabPanel>
                                    <TabPanel value="president">
                                        <PersonalDataForm
                                            userData={user.tierOne}
                                        />
                                    </TabPanel>
                                </TabContext>
                            ) : (
                                <PersonalDataForm userData={user.tierOne} />
                            )}
                        </AccordionDetails>
                    </Accordion>
                    {user.category === "merchant" && (
                        <Accordion
                            sx={{
                                backgroundColor: "#f4f4f4",
                                boxShadow: "none",
                            }}
                            onChange={() =>
                                setActiveAccordion(
                                    activeAccordion === "companyData"
                                        ? ""
                                        : "companyData",
                                )
                            }
                            expanded={activeAccordion === "companyData"}
                        >
                            <AccordionSummary
                                expandIcon={
                                    <ExpandMoreIcon sx={{ color: "#44c1be" }} />
                                }
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography
                                    sx={{
                                        color: "#44c1be",
                                        fontWeight: "bold",
                                    }}
                                >
                                    Dati azienda
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <CompanyInfoForm
                                    userData={user?.tierOne?.business}
                                />
                            </AccordionDetails>
                        </Accordion>
                    )}
                    <Accordion
                        sx={{
                            backgroundColor: "#f4f4f4",
                            boxShadow: "none",
                        }}
                        onChange={() =>
                            setActiveAccordion(
                                activeAccordion === "document"
                                    ? ""
                                    : "document",
                            )
                        }
                        expanded={activeAccordion === "document"}
                    >
                        <AccordionSummary
                            expandIcon={
                                <ExpandMoreIcon sx={{ color: "#44c1be" }} />
                            }
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography
                                sx={{
                                    color: "#44c1be",
                                    fontWeight: "bold",
                                }}
                            >
                                Documento
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            {user.category === "merchant" &&
                            user.tierTwo?.beneficialOwner &&
                            user.tierOne?.identificationDocument ? (
                                <TabContext value={activeTab}>
                                    <Tabs
                                        value={activeTab}
                                        onChange={handleChangeTab}
                                        variant="fullWidth"
                                    >
                                        <Tab
                                            label="Soggetto Tier One"
                                            value="president"
                                        />
                                        <Tab
                                            label="Soggetto Tier Two"
                                            value="delegate"
                                        />
                                    </Tabs>
                                    <TabPanel value="delegate">
                                        <DocumentForm
                                            userData={
                                                user.tierTwo?.beneficialOwner
                                                    ?.identificationDocument
                                            }
                                            userUid={user.id}
                                            isDelegate
                                        />
                                    </TabPanel>
                                    <TabPanel value="president">
                                        <DocumentForm
                                            userData={
                                                user.tierOne
                                                    ?.identificationDocument
                                            }
                                            userUid={user.id}
                                        />
                                    </TabPanel>
                                </TabContext>
                            ) : (
                                user.tierOne?.identificationDocument && (
                                    <DocumentForm
                                        userData={
                                            user.tierOne.identificationDocument
                                        }
                                        userUid={user.id}
                                    />
                                )
                            )}
                        </AccordionDetails>
                    </Accordion>
                    <Accordion
                        sx={{
                            backgroundColor: "#f4f4f4",
                            boxShadow: "none",
                        }}
                        onChange={() =>
                            setActiveAccordion(
                                activeAccordion === "kyc" ? "" : "kyc",
                            )
                        }
                        expanded={activeAccordion === "kyc"}
                    >
                        <AccordionSummary
                            expandIcon={
                                <ExpandMoreIcon sx={{ color: "#44c1be" }} />
                            }
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography
                                sx={{
                                    color: "#44c1be",
                                    fontWeight: "bold",
                                }}
                            >
                                Normative antiriciclaggio
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <RegulationsForm
                                userData={user}
                                userType={user.category}
                            />
                        </AccordionDetails>
                    </Accordion>
                    {accountTypology === "merchant" && (
                        <Accordion
                            sx={{
                                backgroundColor: "#f4f4f4",
                                boxShadow: "none",
                            }}
                            onChange={() =>
                                setActiveAccordion(
                                    activeAccordion === "discount"
                                        ? ""
                                        : "discount",
                                )
                            }
                            expanded={activeAccordion === "discount"}
                        >
                            <AccordionSummary
                                expandIcon={
                                    <ExpandMoreIcon sx={{ color: "#44c1be" }} />
                                }
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography
                                    sx={{
                                        color: "#44c1be",
                                        fontWeight: "bold",
                                    }}
                                >
                                    Sconti
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <DiscountsTable
                                    showCreateDiscountButton={
                                        accountTypology === "merchant"
                                    }
                                />
                            </AccordionDetails>
                        </Accordion>
                    )}
                    <Accordion
                        sx={{
                            backgroundColor: "#f4f4f4",
                            boxShadow: "none",
                        }}
                        expanded={activeAccordion === "wallet"}
                        onChange={() =>
                            setActiveAccordion(
                                activeAccordion === "wallet" ? "" : "wallet",
                            )
                        }
                    >
                        <AccordionSummary
                            expandIcon={
                                <ExpandMoreIcon sx={{ color: "#44c1be" }} />
                            }
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography
                                sx={{
                                    color: "#44c1be",
                                    fontWeight: "bold",
                                }}
                            >
                                Gestione wallet
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <TabContext value={activeWalletTab}>
                                <Tabs
                                    value={activeWalletTab}
                                    onChange={handleChangeTab}
                                    variant="fullWidth"
                                >
                                    <Tab
                                        label="Transazioni"
                                        value="transactions"
                                    />
                                    <Tab label="Acquisti" value="purchases" />
                                </Tabs>
                                <TabPanel value="transactions">
                                    <TransactionsTable />
                                </TabPanel>
                                <TabPanel value="purchases">
                                    <PurchaseTable />
                                </TabPanel>
                            </TabContext>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion
                        sx={{
                            backgroundColor: "#f4f4f4",
                            boxShadow: "none",
                        }}
                        expanded={activeAccordion === "noteDocuments"}
                        onChange={() =>
                            setActiveAccordion(
                                activeAccordion === "noteDocuments"
                                    ? ""
                                    : "noteDocuments",
                            )
                        }
                    >
                        <AccordionSummary
                            expandIcon={
                                <ExpandMoreIcon sx={{ color: "#44c1be" }} />
                            }
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography
                                sx={{
                                    color: "#44c1be",
                                    fontWeight: "bold",
                                }}
                            >
                                Archivio note e documenti
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <NoteDocuments
                                userId={user.id}
                                userType={user.category}
                                initialData={notes}
                            />
                        </AccordionDetails>
                    </Accordion>
                </Box>
                {user.pendingVerificationRequest && (
                    <CheckKycBanner
                        requestedTier={tierToPromote}
                        handleApprove={() =>
                            setPromotion({
                                userUid: user.id,
                                promotionTier: tierToPromote,
                            })
                        }
                        handleDisapprove={() =>
                            setRejection({
                                userUid: user.id,
                                tierToReject: tierToPromote,
                            })
                        }
                        handleCallRequest={() =>
                            setRequest({ userUid: user.id })
                        }
                    />
                )}
            </>

            <FormDialog
                inputVisibility={false}
                title={"Vuoi davvero promuovere l'utente?"}
                visibility={promotion.userUid}
                onSubmitFunc={promoteUserTier}
                onCloseFunc={closeDialog}
            />

            <FormDialog
                inputVisibility={false}
                title={`Vuoi davvero cancellare il tier ${tierToPromote} dell'utente?`}
                visibility={rejection.userUid}
                onSubmitFunc={rejectUserTier}
                onCloseFunc={closeDialog}
            />

            <FormDialog
                inputVisibility={false}
                title={"Confermi di voler richiedere una call con l'utente?"}
                visibility={request.userUid}
                onSubmitFunc={requestCall}
                onCloseFunc={closeDialog}
            />
        </>
    );
};

export default UserDetail;
