import { userSlice } from "./user/user.slice";
import { usersSlice } from "./users/users.slice";
import { transfersSlice } from "./transfers/transfers.slice";
import { requestsSlice } from "./requests/requests.slice";
import { settingsSlice } from "./settings/settings.slice";

const reducers = {
    user: userSlice.reducer,
    users: usersSlice.reducer,
    transfers: transfersSlice.reducer,
    requests: requestsSlice.reducer,
    settings: settingsSlice.reducer,
};

export default reducers;
