import LockIcon from "@mui/icons-material/Lock";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import ManageSearchIcon from "@mui/icons-material/ManageSearch";
import { CircularProgress } from "@mui/material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { Box } from "@mui/system";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import useStyles from "./style";

type TableProps = {
    tableHead: string[];
    tableRows: any[];
    lockUser: (address: string, action: string, userType: string) => void;
    unlockUser: (address: string, action: string, userType: string) => void;
};

const MTTable: React.FC<TableProps> = ({
    tableHead,
    tableRows,
    lockUser,
    unlockUser,
}) => {
    const classes = useStyles();
    const navigate = useNavigate();

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(25);

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement>,
    ) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    return (
        <Paper
            sx={{
                height: "calc(100% - 170px)",
                width: "100%",
                overflow: "hidden",
            }}
        >
            <TableContainer
                sx={{ height: "calc(100% - 50px)" }}
                component={Paper}
            >
                <Table sx={{ minWidth: 700 }} stickyHeader>
                    <TableHead>
                        <TableRow>
                            {tableHead.map(columnTitle => (
                                <TableCell
                                    className={classes.tableCell}
                                    key={columnTitle}
                                >
                                    {columnTitle}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    {tableRows ? (
                        <TableBody>
                            {tableRows
                                .slice(
                                    page * rowsPerPage,
                                    page * rowsPerPage + rowsPerPage,
                                )
                                .map(row => (
                                    <TableRow
                                        className={`${classes.tableRow} ${
                                            [2].includes(row.status)
                                                ? "pending"
                                                : ""
                                        }`}
                                        key={row.walletAddress}
                                    >
                                        <TableCell
                                            className={classes.tableCell}
                                            align="left"
                                        >
                                            {row.category}
                                        </TableCell>

                                        <TableCell
                                            className={classes.tableCell}
                                            align="left"
                                        >
                                            {row.walletAddress}
                                        </TableCell>

                                        <TableCell
                                            className={classes.tableCell}
                                            align="left"
                                        >
                                            {`${
                                                row?.tierOne?.firstName || "-"
                                            } ${row?.tierOne?.lastName || "-"}`}
                                        </TableCell>

                                        <TableCell
                                            className={classes.tableCell}
                                            align="left"
                                        >
                                            {row?.tierOne?.fiscalCode || "- -"}
                                        </TableCell>

                                        <TableCell
                                            className={classes.tableCell}
                                            align="left"
                                        >
                                            {row.mitBalance} MIT
                                        </TableCell>

                                        <TableCell
                                            className={classes.tableCell}
                                            align="left"
                                        >
                                            <div
                                                className={
                                                    row.status === 1
                                                        ? classes.active
                                                        : classes.locked
                                                }
                                            >
                                                {row.status === 1
                                                    ? "Attivo"
                                                    : "Bloccato"}
                                            </div>
                                        </TableCell>
                                        <TableCell
                                            className={classes.tableCell}
                                            align="left"
                                        >
                                            <div
                                                className={
                                                    classes.actionsContainer
                                                }
                                            >
                                                {row.tierOne ? (
                                                    <div
                                                        className={
                                                            classes.action
                                                        }
                                                    >
                                                        <ManageSearchIcon />
                                                        <div
                                                            className={
                                                                classes.info
                                                            }
                                                            onClick={() =>
                                                                navigate(
                                                                    `/users/${row.category.toLocaleLowerCase()}/info-wallet/${
                                                                        row.walletAddress
                                                                    }`,
                                                                )
                                                            }
                                                        >
                                                            Info dettaglio
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <div
                                                        className={
                                                            classes.voidAction
                                                        }
                                                    />
                                                )}

                                                <div className={classes.action}>
                                                    {row.status === 1 ? (
                                                        <LockIcon />
                                                    ) : (
                                                        <LockOpenIcon />
                                                    )}
                                                    <div
                                                        className={classes.info}
                                                        onClick={
                                                            row.status === 1
                                                                ? () =>
                                                                      lockUser(
                                                                          row.walletAddress,
                                                                          "lock",
                                                                          row.category,
                                                                      )
                                                                : () =>
                                                                      unlockUser(
                                                                          row.walletAddress,
                                                                          "unlock",
                                                                          row.category,
                                                                      )
                                                        }
                                                    >
                                                        {row.status === 1
                                                            ? "Blocca"
                                                            : "Sblocca"}
                                                    </div>
                                                </div>
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                ))}
                        </TableBody>
                    ) : (
                        <TableBody sx={{ height: 400 }}>
                            <TableRow>
                                <TableCell colSpan={8} rowSpan={2}>
                                    <Box className={classes.tableLoader}>
                                        <CircularProgress />
                                    </Box>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    )}
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[25, 50, 100]}
                component="div"
                count={tableRows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </Paper>
    );
};

export default MTTable;
