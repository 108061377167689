import React from "react";
import { Box, IconButton, Modal } from "@mui/material";

import useStyles from "./style";
import { CloseIcon } from "../../assets/icons";
import ThumbButton from "../MTThumbButton";

import { RequestData } from "../../store/requests/types";

import { adminOperations, adminOperationsArgs } from "../../constants";

import { useSelector } from "react-redux";
import { User } from "../../store/user/types";
import { ethers } from "ethers";

type Props = {
    request: RequestData | undefined;
    open: boolean;
    handleClose: () => void;
};

const RequestDetailModal: React.FC<Props> = ({
    open,
    handleClose,
    request,
}) => {
    const classes = useStyles();
    const {
        wallet: { address },
    } = useSelector(({ user }: { user: User }) => user);

    const formatRequest = (request: RequestData) => {
        const id = request.id;
        const data = request as RequestData;

        const submitDate =
            data.submitDate instanceof Date
                ? new Date()
                : new Date(data.submitDate.seconds * 1000);

        return {
            id,
            typology: adminOperations[data.type],
            date: submitDate,
            requestingAdmin: data.requestingAdmin,
            voters: `${data.signatures.length}/${data.threshold}`,
            status: data.status,
            myVoteStatus:
                data.signatures.find(signature => {
                    return signature.signer === address;
                })?.vote || "pending",
        };
    };

    const formatArgs = (argsValues: any) => {
        const formattedArgs: any = {};

        if (argsValues.receiver) {
            formattedArgs.receiver =
                argsValues.receiver.slice(0, 10).concat("....") +
                argsValues.receiver.slice(38);
        }

        if (argsValues.newThreshold) {
            formattedArgs.newThreshold = argsValues.newThreshold;
        }

        if (argsValues.amount) {
            formattedArgs.amount = `${ethers.utils.formatEther(
                argsValues.amount,
            )} MIT`;
        }

        if (argsValues.newLimit) {
            formattedArgs.newLimit = `${ethers.utils.formatEther(
                argsValues.newLimit,
            )} MIT`;
        }

        if (argsValues.resetTime) {
            formattedArgs.resetTime = `${argsValues.resetTime} min`;
        }

        return formattedArgs;
    };

    const formattedRequest = request ? formatRequest(request) : null;

    const formattedArgs = formatArgs(request?.argsValues);

    return (
        formattedRequest && (
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box className={classes.modal}>
                    <div className={classes.headerFlex}>
                        <IconButton onClick={handleClose}>
                            <CloseIcon />
                        </IconButton>
                    </div>
                    <h1 className={classes.titleHeader}>Dettagli richiesta</h1>
                    <div>
                        <div className={classes.flexContainer}>
                            <div className={classes.inputsContainer}>
                                <div className={classes.inputBlock}>
                                    <p className={classes.title}>
                                        Tipologia richiesta
                                    </p>
                                    <p>{formattedRequest.typology || "-"}</p>
                                </div>
                                <div className={classes.inputBlock}>
                                    <p className={classes.title}>
                                        Admin richiedente
                                    </p>
                                    <p>
                                        {formattedRequest?.requestingAdmin ||
                                            "-"}
                                        {/* {formattedRequest?.requestingAdmin
                                            .slice(0, 10)
                                            .concat("....") +
                                            formattedRequest?.requestingAdmin.slice(
                                                38,
                                            ) || "-"} */}
                                    </p>
                                </div>
                                <div className={classes.inputBlock}>
                                    <p className={classes.title}>
                                        Data inoltro richiesta
                                    </p>
                                    <p>
                                        {formattedRequest.date.toLocaleDateString()}
                                    </p>
                                </div>
                                <div className={classes.inputBlock}>
                                    <p className={classes.title}>Il mio voto</p>
                                    <p>
                                        {formattedRequest.myVoteStatus ===
                                        "pending"
                                            ? "In attesa"
                                            : formattedRequest.myVoteStatus ===
                                              "accepted"
                                            ? "Accettato"
                                            : "Rifiutato"}
                                    </p>
                                </div>
                                <div className={classes.inputBlock}>
                                    <p className={classes.title}>
                                        Dettagli della richiesta
                                    </p>

                                    {formattedArgs &&
                                        Object.keys(formattedArgs).map(key => {
                                            return (
                                                <p
                                                    key={key}
                                                >{`${adminOperationsArgs[key]}: ${formattedArgs[key]}`}</p>
                                            );
                                        })}
                                </div>
                            </div>
                        </div>
                        <div className={classes.flexContainer}>
                            <div className={classes.inputsContainer}>
                                {request?.signatures.map(signature => (
                                    <div
                                        className={classes.addressBlock}
                                        key={signature.signer}
                                    >
                                        {signature.signer
                                            .slice(0, 10)
                                            .concat("....") +
                                            signature.signer.slice(38)}
                                        -
                                        {signature.vote !== undefined ? (
                                            <ThumbButton
                                                isButton={false}
                                                thumbDirection={
                                                    signature.vote ===
                                                    "accepted"
                                                        ? "up"
                                                        : signature.vote ===
                                                          "rejected"
                                                        ? "down"
                                                        : null
                                                }
                                                label={
                                                    signature.vote === "pending"
                                                        ? "In attesa"
                                                        : signature.vote ===
                                                          "accepted"
                                                        ? "Accettato"
                                                        : "Rifiutato"
                                                }
                                            />
                                        ) : (
                                            <div>-</div>
                                        )}
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </Box>
            </Modal>
        )
    );
};

export default RequestDetailModal;
