import React from "react";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@mui/material";

import { useLocation } from "react-router-dom";

import { getTransfers } from "../../../store/transfers/transfers.slice";
import { useSelector } from "react-redux";

import useStyles from "./style";

function createData(
    typology: "incoming" | "outcoming",
    id: string,
    from: string,
    to: string,
    amount: number,
    euroAmount: number,
    date: Date,
) {
    return {
        typology,
        id,
        from,
        to,
        amount,
        euroAmount,
        date,
    };
}

const TransactionsTable: React.FC = () => {
    const classes = useStyles();

    const currentPath = useLocation().pathname;
    const splittedPath = currentPath.split("/");
    const address = splittedPath[4];

    const { list: transfers } = useSelector(getTransfers);

    const rows = [...transfers]
        .reverse()
        .filter(
            transfer => transfer.from === address || transfer.to === address,
        )
        .map(transfer => {
            const typology =
                transfer.from === address ? "outcoming" : "incoming";

            return createData(
                typology,
                transfer.id,
                transfer.from,
                transfer.to,
                transfer.amount,
                transfer.amount / 10,
                new Date(transfer.timestamp * 1000),
            );
        });

    return (
        <TableContainer>
            <Table className={classes.table}>
                <TableHead>
                    <TableRow className={classes.tableRowHeader}>
                        <TableCell className={classes.tableCell}>
                            Tipologia
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                            ID Transazione
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                            Wallet Mittente
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                            Wallet Destinatario
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                            Importo MIT
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                            Importo Euro
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                            Data
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map(row => (
                        <TableRow
                            key={row.id}
                            sx={{
                                "&:last-child td, &:last-child th": {
                                    border: 0,
                                },
                            }}
                        >
                            <TableCell>
                                {row.typology === "outcoming"
                                    ? "In uscita"
                                    : "In entrata"}
                            </TableCell>
                            <TableCell>
                                {row.id?.slice(0, 4).concat("....") +
                                    row.id?.slice(38)}
                            </TableCell>
                            <TableCell>
                                {row.from?.slice(0, 4).concat("....") +
                                    row.from?.slice(38)}
                            </TableCell>
                            <TableCell>
                                {row.to?.slice(0, 4).concat("....") +
                                    row.to?.slice(38)}
                            </TableCell>
                            <TableCell>{row.amount}</TableCell>
                            <TableCell>{row.euroAmount}</TableCell>
                            <TableCell>{row.date.toISOString()}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default TransactionsTable;
