import { ethers } from "ethers";
import { createListenerMiddleware } from "@reduxjs/toolkit";
import { fetchUsers, updateUserBalance } from "../users/users.slice";
import { logout } from "../user/user.slice";
import {
    fetchTransfers,
    addTransfer,
    setFromBlock,
} from "../transfers/transfers.slice";
import { mitTokenContract, provider } from "../../utils/utils";
import { formatTransfer } from "./fetchUtils";

import { UserInfo } from "../users/types";

import { RootState } from "../store";

const transfersMiddleware = createListenerMiddleware();

const transferEvent = mitTokenContract.filters.Transfer();

transfersMiddleware.startListening({
    actionCreator: fetchUsers.fulfilled,
    effect: async (_, listenerApi) => {
        listenerApi.dispatch(fetchTransfers());
    },
});

transfersMiddleware.startListening({
    actionCreator: fetchTransfers.fulfilled,
    effect: async (_, listenerApi) => {
        // Blockchain listeners
        mitTokenContract.on(transferEvent, async (from, to, amount, event) => {
            const formattedTransfer = await formatTransfer(event);
            const currentBlock = await provider.getBlockNumber();
            listenerApi.dispatch(setFromBlock(currentBlock + 1));
            listenerApi.dispatch(addTransfer(formattedTransfer));

            const rootState = listenerApi.getState() as RootState;
            const mitBalance = rootState.users.list.find(
                (user: UserInfo) => user.walletAddress === to,
            )?.mitBalance;

            if (mitBalance) {
                listenerApi.dispatch(
                    updateUserBalance({
                        walletAddress: to,
                        mitBalance:
                            mitBalance +
                            parseInt(ethers.utils.formatEther(amount)),
                    }),
                );
            }
        });
    },
});

transfersMiddleware.startListening({
    actionCreator: logout,
    effect: async () => {
        mitTokenContract.removeAllListeners();
    },
});

export default transfersMiddleware;
