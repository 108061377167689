import { collection, onSnapshot, QuerySnapshot } from "@firebase/firestore";
import { createListenerMiddleware, Unsubscribe } from "@reduxjs/toolkit";
import { firestore } from "../../firebase";
import { personalDataContract } from "../../utils/utils";

import { logout, setJwtToken } from "../user/user.slice";
import {
    reset,
    updateUserData,
    fetchUsers,
    addUser,
    updateUserStatus,
} from "./users.slice";

import { formatUser } from "./fetchUtils";
import { UserInfo } from "./types";

const usersMiddleware = createListenerMiddleware();

let unsubConsumers: Unsubscribe;
let unsubMerchants: Unsubscribe;

usersMiddleware.startListening({
    actionCreator: setJwtToken,
    effect: async (_, listenerApi) => {
        listenerApi.dispatch(fetchUsers());
    },
});

usersMiddleware.startListening({
    actionCreator: fetchUsers.fulfilled,
    effect: async (action, listenerApi) => {
        // Blockchain listeners
        personalDataContract.on("UserIsActivated", address => {
            const changes = {
                walletAddress: address,
                status: 1,
            };
            listenerApi.dispatch(updateUserStatus(changes));
        });

        personalDataContract.on("UserIsSuspended", address => {
            const changes = {
                walletAddress: address,
                status: 2,
            };
            listenerApi.dispatch(updateUserStatus(changes));
        });

        personalDataContract.on("UserIsReActivated", address => {
            const changes = {
                walletAddress: address,
                status: 1,
            };
            listenerApi.dispatch(updateUserStatus(changes));
        });

        // Firestore listeners
        unsubConsumers = onSnapshot(
            collection(firestore, "consumers"),
            (snapshot: QuerySnapshot) => {
                snapshot.docChanges().forEach(async change => {
                    if (change.type === "added") {
                        // Ignore first cycle of added documents
                        if (
                            !action.payload.find(
                                user => user.id === change.doc.id,
                            )
                        ) {
                            const newDocData = change.doc.data();
                            const newDoc = {
                                id: change.doc.id,
                                category: "consumer",
                                ...newDocData,
                            };
                            const formattedDoc = await formatUser(newDoc);
                            listenerApi.dispatch(addUser(formattedDoc));
                        }
                    }
                    if (change.type === "modified") {
                        const editedDocumentData = change.doc.data();
                        const editedDoc = {
                            id: change.doc.id,
                            ...editedDocumentData,
                        } as UserInfo;
                        listenerApi.dispatch(updateUserData(editedDoc));
                    }
                });
            },
        );

        unsubMerchants = onSnapshot(
            collection(firestore, "merchants"),
            (snapshot: QuerySnapshot) => {
                snapshot.docChanges().forEach(async change => {
                    if (change.type === "added") {
                        // Ignore first cycle of added documents
                        if (
                            !action.payload.find(
                                user => user.id === change.doc.id,
                            )
                        ) {
                            const newDocData = change.doc.data();
                            const newDoc = {
                                id: change.doc.id,
                                category: "merchant",
                                ...newDocData,
                            };
                            const formattedDoc = await formatUser(newDoc);
                            listenerApi.dispatch(addUser(formattedDoc));
                        }
                    }
                    if (change.type === "modified") {
                        const editedDocumentData = change.doc.data();
                        const editedDoc = {
                            id: change.doc.id,
                            ...editedDocumentData,
                        } as UserInfo;
                        listenerApi.dispatch(updateUserData(editedDoc));
                    }
                });
            },
        );
    },
});

usersMiddleware.startListening({
    actionCreator: logout,
    effect: async (_, listenerApi) => {
        listenerApi.dispatch(reset());
        personalDataContract.removeAllListeners();
        unsubConsumers();
        unsubMerchants();
    },
});

export default usersMiddleware;
