import { Theme } from "@mui/material/styles";
import { createStyles, makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        title: {
            color: theme.palette.primary.main,
            fontWeight: "bold",
            marginBottom: "20px",
        },
        flexContainer: {
            width: "100%",
            height: "100%",
            display: "flex",
            flexWrap: "wrap",
            flexDirection: "column",
            marginTop: "10px",
        },
        form: {
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            flexWrap: "wrap",
        },
        inputsContainer: {
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            // justifyContent: "space-between",
            flexWrap: "wrap",
        },
        inputBlock: {
            width: "calc(33.3% - 1.33rem)",
            marginBottom: "28px",
            "&:nth-child(3n -1)": {
                marginLeft: "2rem",
                marginRight: "2rem",
            },
        },
        inputFullBlock: {
            width: "100%",
            marginBottom: "2rem",
        },
        birthDate: {
            width: "100%",
        },
        button: {
            width: "150px",
            marginTop: "50px",
            color: theme.palette.primary.light,
            alignSelf: "end",
        },
        error: {
            cursor: "pointer",
            color: "red",
            marginBottom: 0,
            marginTop: "10px",
            fontSize: "18px",
        },
        mitAmountContainer: {
            backgroundColor: "#fff",
            borderRadius: 30,
            width: 400,
            paddingTop: 5,
            paddingBottom: 5,
            paddingLeft: 20,
            paddingRight: 10,
            fontSize: 26,
            margin: 30,
            border: "solid 3px #E0E0E0",
        },
        mitAmount: {
            fontWeight: "bold",
            textAlign: "right",
            fontSize: 40,
            marginTop: 20,
            marginRight: 20,
        },
        titleMitAmount: {
            marginTop: 15,
        },
        flexEndContainer: {
            display: "flex",
            justifyContent: "center",
            marginBottom: "30px",
        },
    }),
);

export default useStyles;
