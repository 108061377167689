import { Theme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) => ({
    inputsContainer: {
        width: "calc(100% - 200px)",
        display: "flex",
    },
    flexContainer: {
        display: "flex",
        alignItems: "center",
        flexWrap: "wrap",
        justifyContent: "space-between",
        marginBottom: "10px",
    },
    inputBlock: {
        marginRight: "30px",
        width: "250px",
    },
    closeIcon: {
        fill: theme.palette.primary.main,
    },
}));

export default useStyles;
