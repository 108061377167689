import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
    container: {
        height: "100%",
    },
    filterContainer: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginBottom: "30px",
    },
}));

export default useStyles;
