import React from "react";
import { Button } from "@mui/material";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";

import useStyles from "./style";

type ButtonProps = {
    isButton?: boolean;
    thumbDirection: "up" | "down" | null;
    label: string;
    handleClick?: () => void;
};

const ThumbButton: React.FC<ButtonProps> = ({
    isButton,
    thumbDirection,
    label,
    handleClick,
}) => {
    const classes = useStyles();
    let colorText = "inherit";
    if (!isButton) {
        colorText =
            thumbDirection === "up"
                ? "#09A822"
                : thumbDirection === "down"
                ? "#DB1508"
                : "orange";
    }
    return (
        <Button className={classes.capitalizeText} onClick={handleClick}>
            {thumbDirection === "down" ? (
                <ThumbDownIcon className={classes.thumbdown} />
            ) : thumbDirection === "up" ? (
                <ThumbUpIcon className={classes.thumbsup} />
            ) : (
                <div></div>
            )}
            <div style={{ color: colorText }}>{label}</div>
        </Button>
    );
};

ThumbButton.defaultProps = {
    isButton: true,
};

export default ThumbButton;
