import { StyledEngineProvider } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import React from "react";
import ReactDOM from "react-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { Provider } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";

import App from "./app/App";
import reportWebVitals from "./reportWebVitals";
import store, { persistor } from "./store/store";
import { PersistGate } from "redux-persist/integration/react";
import "./styles/index.scss";
import theme from "./theme";

const queryClient = new QueryClient();

ReactDOM.render(
    <React.StrictMode>
        <ToastContainer />
        <ThemeProvider theme={theme}>
            <Provider store={store}>
                <PersistGate loading={null} persistor={persistor}>
                    <QueryClientProvider client={queryClient}>
                        <StyledEngineProvider injectFirst>
                            <App />
                        </StyledEngineProvider>
                    </QueryClientProvider>
                </PersistGate>
            </Provider>
        </ThemeProvider>
    </React.StrictMode>,
    document.getElementById("root"),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
