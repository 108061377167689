import { createStyles, makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => {
    return createStyles({
        title: {
            fontWeight: "bold",
        },
        modal: {
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 1200,
            height: 780,
            backgroundColor: "white",
            textAlign: "center",
            overflowY: "scroll",
        },
        headerFlex: {
            display: "flex",
            justifyContent: "end",
            alignItems: "center",
        },
        titleHeader: {
            marginTop: 0,
        },
        flexContainer: {
            width: "100%",
            height: "100%",
            display: "flex",
            flexWrap: "wrap",
            flexDirection: "column",
            marginTop: "50px",
        },
        inputBlock: {
            width: "calc(33.3% - 1.33rem)",
            "&:nth-child(3n -1)": {
                marginLeft: "2rem",
                marginRight: "2rem",
            },
        },
        addressBlock: {
            width: "calc(33.3% - 1.33rem)",
            marginBottom: "18px",
            "&:nth-child(3n -1)": {
                marginLeft: "2rem",
                marginRight: "2rem",
            },
        },
        inputsContainer: {
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            flexWrap: "wrap",
        },
    });
});

export default useStyles;
