import { useEffect, useState } from "react";
import { ethers } from "ethers";
import { provider } from "../../utils/utils";

import { mitToken, allowance } from "../../contracts/";

import Safe, { ContractNetworksConfig } from "@gnosis.pm/safe-core-sdk";
import { SafeTransactionDataPartial } from "@gnosis.pm/safe-core-sdk-types";
import EthersAdapter from "@gnosis.pm/safe-ethers-lib";

import { toast } from "react-toastify";

import { User } from "../../store/user/types";
import { useSelector } from "react-redux";

import { cloneDeep } from "lodash";

import { addDoc, collection, doc, updateDoc } from "firebase/firestore";
import { firestore } from "../../firebase";

import {
    RequestData,
    RequestType,
    ArgsValues,
} from "../../store/requests/types";

import {
    safeProxyAddress,
    multiSendAddress,
    safeMasterCopyAddress,
    safeProxyFactoryAddress,
    chainId,
} from "../../constants";

const useManageUser = () => {
    const [loader, setLoader] = useState<boolean>(false);
    const [safeSdk, setSafeSdk] = useState<Safe>();
    const [currentThreshold, setCurrentThreshold] = useState<number>();
    const [adminNumber, setAdminNumber] = useState<number>();

    const {
        wallet: { privateKey, address },
    } = useSelector(({ user }: { user: User }) => user);

    if (!privateKey) {
        toast.error(
            "La chiave privata non é valida, le operazioni sulla blockchain sono bloccate",
            {
                toastId: "privateKeyError",
            },
        );
        return {
            loader: false,
            executeTransfer: () => null,
        };
    }

    useEffect(() => {
        let mounted = true;

        (async () => {
            const adminWallet = new ethers.Wallet(privateKey, provider);

            const myEthAdapter = new EthersAdapter({
                ethers,
                signer: adminWallet,
            });

            const safeSdkInit = await Safe.create({
                ethAdapter: myEthAdapter,
                safeAddress: safeProxyAddress,
                isL1SafeMasterCopy: true,
                contractNetworks,
            });

            const currentThresholdInit = await safeSdkInit.getThreshold();
            const adminNumberInit = await safeSdkInit.getOwners();

            if (mounted) {
                setSafeSdk(safeSdkInit);
                setCurrentThreshold(currentThresholdInit);
                setAdminNumber(adminNumberInit.length);
            }
        })();

        return () => {
            mounted = false;
        };
    }, []);

    const contractNetworks: ContractNetworksConfig = {
        [chainId]: {
            multiSendAddress: multiSendAddress,
            safeMasterCopyAddress: safeMasterCopyAddress,
            safeProxyFactoryAddress: safeProxyFactoryAddress,
            multiSendCallOnlyAddress: "",
        },
    };

    const formatRequest = async (
        type: RequestType,
        signature: string,
        argsValues?: ArgsValues,
    ) => {
        if (!safeSdk) return;

        const request: RequestData = {
            type: type,
            requestingAdmin: address,
            submitDate: new Date(),
            status: "pending",
            threshold: currentThreshold || 0,
            signatures: [
                {
                    vote: "accepted",
                    signer: address,
                    signature: signature,
                },
            ],
        };

        if (argsValues) {
            request.argsValues = argsValues;
        }

        return request;
    };

    const saveRequest = async (request: RequestData) => {
        const collectionRef = collection(firestore, "adminOperationsRequests");

        try {
            await addDoc(collectionRef, request);
            toast.success("Richiesta inserita!");
        } catch {
            toast.error("Inserimento richiesta fallito");
        }
    };

    const getSafeSignature = async (
        txnType: RequestType,
        argsValues?: ArgsValues,
    ) => {
        if (!safeSdk) return;

        let signature;

        switch (txnType) {
            case "CHANGE_THRESHOLD": {
                if (argsValues?.newThreshold) {
                    const safeTransaction =
                        await safeSdk.createChangeThresholdTx(
                            argsValues.newThreshold,
                        );

                    const txHash = await safeSdk.getTransactionHash(
                        safeTransaction,
                    );

                    signature = await safeSdk.signTransactionHash(txHash);
                }

                break;
            }
            case "EXECUTE_TRANSFER": {
                if (argsValues?.receiver) {
                    const to = mitToken.contract_address;
                    const value = "0";
                    const iface = new ethers.utils.Interface(mitToken.abi);
                    const data = iface.encodeFunctionData("transfer", [
                        argsValues.receiver,
                        argsValues.amount,
                    ]);

                    const safeTransactionData: SafeTransactionDataPartial = {
                        to,
                        data,
                        value,
                    };

                    const safeTransaction = await safeSdk.createTransaction({
                        safeTransactionData,
                    });

                    const txHash = await safeSdk.getTransactionHash(
                        safeTransaction,
                    );

                    signature = await safeSdk.signTransactionHash(txHash);
                }

                break;
            }
            case "SET_ALLOWANCE": {
                if (argsValues?.receiver) {
                    const to = allowance.contract_address;
                    const value = "0";
                    const iface = new ethers.utils.Interface(allowance.abi);
                    const data = iface.encodeFunctionData("setAllowance", [
                        argsValues.receiver,
                        mitToken.contract_address,
                        argsValues.newLimit,
                        argsValues.resetTime,
                        Math.round(new Date().getTime() / (1000 * 60)),
                    ]);

                    const safeTransactionData: SafeTransactionDataPartial = {
                        to,
                        data,
                        value,
                    };

                    const safeTransaction = await safeSdk.createTransaction({
                        safeTransactionData,
                    });

                    const txHash = await safeSdk.getTransactionHash(
                        safeTransaction,
                    );

                    signature = await safeSdk.signTransactionHash(txHash);
                }

                break;
            }
        }

        return signature;
    };

    const createChangeThresholdRequest = async (newThreshold: number) => {
        if (!adminNumber || !currentThreshold) return;

        if (newThreshold > adminNumber) {
            toast.error(
                "La threshold non può essere maggiore del numero di admin esistenti",
            );
            return;
        }
        if (newThreshold === currentThreshold) {
            toast.error(
                "La threshold richiesta non può essere uguale a quella corrente",
            );
            return;
        }

        if (loader) {
            toast.warning("Attendere l'operazione in corso");
            return;
        }

        setLoader(true);

        const loadingToast = toast.loading("Operazione in corso");

        const argsValues = {
            newThreshold,
        };

        const signature = await getSafeSignature(
            "CHANGE_THRESHOLD",
            argsValues,
        );

        const request = await formatRequest(
            "CHANGE_THRESHOLD",
            signature?.data || "",
            argsValues,
        );

        if (request) {
            await saveRequest(request);
        }

        toast.dismiss(loadingToast);
        setLoader(false);

        return request;
    };

    const createTransferRequest = async (receiver: string, amount: string) => {
        if (loader) {
            toast.warning("Attendere l'operazione in corso");
            return;
        }

        setLoader(true);

        const loadingToast = toast.loading("Operazione in corso");

        const formattedAmount = ethers.utils.parseEther(amount).toString();

        const argsValues = {
            receiver,
            amount: formattedAmount,
        };

        const signature = await getSafeSignature(
            "EXECUTE_TRANSFER",
            argsValues,
        );

        const request = await formatRequest(
            "EXECUTE_TRANSFER",
            signature?.data || "",
            argsValues,
        );

        if (request) {
            await saveRequest(request);
        }

        toast.dismiss(loadingToast);
        setLoader(false);

        return request;
    };

    const createEditPlatfondRequest = async (
        receiver: string,
        newLimit: string,
        resetTime: string,
    ) => {
        if (loader) {
            toast.warning("Attendere l'operazione in corso");
            return;
        }

        setLoader(true);

        const loadingToast = toast.loading("Operazione in corso");
        const formattedLimit = ethers.utils.parseEther(newLimit).toString();

        const argsValues = {
            receiver,
            newLimit: formattedLimit,
            resetTime: resetTime,
        };

        const signature = await getSafeSignature("SET_ALLOWANCE", argsValues);

        const request = await formatRequest(
            "SET_ALLOWANCE",
            signature?.data || "",
            argsValues,
        );

        if (request) {
            await saveRequest(request);
        }

        toast.dismiss(loadingToast);
        setLoader(false);

        return request;
    };

    const confirmOperation = async (requestData: RequestData) => {
        if (loader) {
            toast.warning("Attendere l'operazione in corso");
            return;
        }

        setLoader(true);

        const loadingToast = toast.loading("Operazione in corso");

        const signature = await getSafeSignature(
            requestData.type,
            requestData.argsValues,
        );

        const signatures = [
            ...requestData.signatures,
            {
                vote: "accepted",
                signer: address,
                signature: signature?.data,
            },
        ];

        if (requestData?.id) {
            const docRef = doc(
                firestore,
                "adminOperationsRequests",
                requestData.id,
            );

            try {
                await updateDoc(docRef, { signatures: signatures });
                toast.success("Operazione firmata con successo!");
            } catch {
                toast.error("Firma operazione fallita");
            }
        }

        toast.dismiss(loadingToast);
        setLoader(false);
    };

    const rejectOperation = async (requestData: RequestData) => {
        if (loader) {
            toast.warning("Attendere l'operazione in corso");
            return;
        }

        setLoader(true);

        const loadingToast = toast.loading("Operazione in corso");

        const updatedRequestData = cloneDeep(requestData);

        const signatures = [
            ...requestData.signatures,
            {
                vote: "rejected",
                signer: address,
            },
        ];

        if (requestData?.id) {
            const docRef = doc(
                firestore,
                "adminOperationsRequests",
                requestData.id,
            );

            try {
                await updateDoc(docRef, { signatures: signatures });
                toast.success("Operazione firmata con successo!");
            } catch {
                toast.error("Firma operazione fallita");
            }
        }

        toast.dismiss(loadingToast);
        setLoader(false);

        return updatedRequestData;
    };

    return {
        createChangeThresholdRequest,
        createTransferRequest,
        createEditPlatfondRequest,
        confirmOperation,
        rejectOperation,
    };
};

export default useManageUser;
