import ManageSearchIcon from "@mui/icons-material/ManageSearch";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import * as React from "react";
import { explorerUrl } from "../../constants";
import useStyles from "./style";
import { FormattedTransfer } from "../../store/transfers/types";

type TableProps = {
    tableHead: string[];
    tableRows: FormattedTransfer[];
};

const MTTable: React.FC<TableProps> = ({ tableHead, tableRows }) => {
    const classes = useStyles();

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(25);

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement>,
    ) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    return (
        <Paper
            sx={{
                height: "calc(100% - 115px)",
                width: "100%",
                overflow: "hidden",
            }}
        >
            <TableContainer
                sx={{ height: "calc(100% - 50px)" }}
                component={Paper}
            >
                <Table
                    sx={{ minWidth: 700 }}
                    stickyHeader
                    aria-label="sticky table"
                >
                    <TableHead>
                        <TableRow>
                            {tableHead.map(columnTitle => (
                                <TableCell
                                    className={classes.tableCell}
                                    key={columnTitle}
                                >
                                    {columnTitle}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {tableRows &&
                            tableRows
                                .slice(
                                    page * rowsPerPage,
                                    page * rowsPerPage + rowsPerPage,
                                )
                                .map(row => (
                                    <TableRow
                                        className={classes.tableRow}
                                        key={row.id}
                                    >
                                        <TableCell
                                            className={classes.tableCell}
                                            align="left"
                                        >
                                            {`${row.id.substring(0, 30)}...`}
                                        </TableCell>
                                        <TableCell
                                            className={classes.tableCell}
                                            align="left"
                                        >
                                            {row.from}
                                        </TableCell>
                                        <TableCell
                                            className={classes.tableCell}
                                            align="left"
                                        >
                                            {row.to}
                                        </TableCell>
                                        <TableCell
                                            className={classes.tableCell}
                                            align="left"
                                        >
                                            {`${row.amount} MIT`}
                                        </TableCell>
                                        <TableCell
                                            className={classes.tableCell}
                                            align="left"
                                        >
                                            <a
                                                href={`${explorerUrl}/tx/${row.id}`}
                                                target="_blank"
                                            >
                                                <div
                                                    className={
                                                        classes.detailsLink
                                                    }
                                                >
                                                    <ManageSearchIcon />
                                                    <div
                                                        className={classes.info}
                                                    >
                                                        Info dettaglio
                                                    </div>
                                                </div>
                                            </a>
                                        </TableCell>
                                    </TableRow>
                                ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[25, 50, 100]}
                component="div"
                count={tableRows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </Paper>
    );
};

export default MTTable;
