import React from "react";
import { Button, TextField, Typography } from "@mui/material";
import { SubmitHandler, useForm } from "react-hook-form";
import useStyles from "../style";

import { useAdminOperations } from "../../../hooks";

type Inputs = {
    amount: string;
    addressReceiver: string;
};

type Props = {
    handleClose: () => void;
};

const AccreditMit: React.FC<Props> = ({ handleClose }) => {
    const classes = useStyles();

    const { createTransferRequest } = useAdminOperations();

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<Inputs>();

    const onSubmit: SubmitHandler<Inputs> = async data => {
        if (createTransferRequest) {
            const { addressReceiver, amount } = data;

            await createTransferRequest(addressReceiver, amount);
            handleClose();
        }
    };

    return (
        <form
            onSubmit={handleSubmit(onSubmit)}
            className={classes.flexContainer}
        >
            <div className={classes.inputsContainer}>
                <div className={classes.inputFullBlock}>
                    <TextField
                        fullWidth
                        label="Importo"
                        id="fullWidth"
                        type={"number"}
                        InputProps={{
                            inputProps: { min: 1 },
                        }}
                        {...register("amount", { required: true })}
                    />

                    <Typography
                        className={classes.error}
                        variant="h6"
                        gutterBottom
                        component="div"
                    >
                        {errors.amount && <span>This field is required</span>}
                    </Typography>
                </div>
                <div className={classes.inputFullBlock}>
                    <TextField
                        fullWidth
                        label="Wallet destinatario"
                        id="fullWidth"
                        {...register("addressReceiver", { required: true })}
                    />

                    <Typography
                        className={classes.error}
                        variant="h6"
                        gutterBottom
                        component="div"
                    >
                        {errors.addressReceiver && (
                            <span>This field is required</span>
                        )}
                    </Typography>
                </div>
                <div className={classes.inputsContainer}>
                    <Button
                        variant="contained"
                        type="submit"
                        className={classes.button}
                    >
                        Invia richiesta
                    </Button>
                </div>
            </div>
        </form>
    );
};

export default AccreditMit;
