import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { RootState } from "../store/store";

const currentEnv = process.env.REACT_APP_ENV;
const apiUrl = process.env[`REACT_APP_FIREBASE_API_END_POINT_${currentEnv}`];

export const firestoreRestApi = createApi({
    reducerPath: "firestoreRestApi",
    tagTypes: ["Contacts"],
    baseQuery: fetchBaseQuery({
        baseUrl: apiUrl,
        prepareHeaders: (headers, { getState }) => {
            const token = (getState() as RootState).user.jwtToken;

            if (token) {
                headers.set("Authorization", `Bearer ${token}`);
            }

            return headers;
        },
    }),
    endpoints: builder => ({
        resetPassword: builder.mutation({
            query: body => ({ url: `resetPassword`, method: "POST", body }),
        }),
        sendEmailNotificationApi: builder.mutation({
            query: body => ({
                url: `sendEmailNotification`,
                method: "POST",
                body,
            }),
        }),
    }),
});

export const { useResetPasswordMutation, useSendEmailNotificationApiMutation } =
    firestoreRestApi;
