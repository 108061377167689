import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { User } from "./types";

import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { toast } from "react-toastify";

import { userReducer } from "./user.reducer";
import { RootState } from "../store";

/*createSlice - a function that accepts an initial state, an object full of reducer 
functions, and a slice name that automatically generates action creators and action 
types that correspond to the reducers and state.  Internally it uses createAction and createReducer*/

export const userInitialState: User = {
    jwtToken: "",
    email: "",
    wallet: {
        address: "",
        privateKey: "",
        mnemonic: "",
    },
};

// 0xc87509a1c067bbde78beb793e6fa76530b6382a4c0241e5e4a9ec0a0f44dc0d3
// 0xae6ae8e5ccbfb04590405997ee2d52d2b330726137b875053c36d94e974d162f
// 0xbb17d0b63151547b41227aa71e65454f636c2950a74542af04c46fc9601f73a6

export const userSlice = createSlice({
    name: "user", // name used in action types
    initialState: userInitialState, // initial state for the reducer
    reducers: userReducer,
});

export const {
    actions: {
        // action creators exported from usersSlice.actions available out of the box
        setWallet,
        setJwtToken,
        setEmail,
        logout,
    },
} = userSlice;

export const selectJwtToken = (state: RootState): string => state.user.jwtToken;

export const getWallet = (
    state: RootState,
): { address: string; privateKey: string; mnemonic: string } =>
    state.user.wallet;

// Async thunks
export const login = createAsyncThunk<
    void,
    { email: string; password: string }
>("user/login", async ({ email, password }, { rejectWithValue }) => {
    const auth = getAuth();
    signInWithEmailAndPassword(auth, email, password)
        .then(() => {
            toast.success("Login effettuato!");
        })
        .catch(error => {
            toast.error("Credenziali non valide!");
            rejectWithValue(error);
        });
});
