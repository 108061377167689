import { ethers, Event } from "ethers";

export const formatTransfer = async (transfer: Event) => {
    const block = await transfer.getBlock();
    const amount = Number(ethers.utils.formatEther(transfer.args?.value));

    return {
        id: transfer.transactionHash,
        from: transfer.args?.from,
        to: transfer.args?.to,
        timestamp: block.timestamp,
        amount,
        reasonForPayment: "",
    };
};
