import React from "react";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { usersReducer } from "./users.reducer";
import { UserInfo, Users, Payment } from "./types";
import { RootState } from "../store";

import { getUsers, getPayments } from "./fetchUtils";

// Define initial state
export const usersInitialState: Users = {
    loader: true,
    list: [],
};

// State slice
export const usersSlice = createSlice({
    name: "users",
    initialState: usersInitialState,
    reducers: usersReducer,
    extraReducers: builder => {
        let loadingToast: React.ReactText;

        //users
        builder.addCase(fetchUsers.pending, () => {
            loadingToast = toast.loading("Caricamento utenti in corso");
        });

        builder.addCase(fetchUsers.fulfilled, (state, { payload }) => {
            state.list = state.list.concat(payload);

            toast.dismiss(loadingToast);
            toast.success("Dati utenti caricati!");

            state.loader = false;
        });

        builder.addCase(fetchUsers.rejected, state => {
            toast.dismiss(loadingToast);
            toast.error("Errore caricamento dati utenti");

            state.loader = false;
        });

        // payments
        builder.addCase(fetchPayments.pending, () => {
            loadingToast = toast.loading("Caricamento pagamenti in corso");
        });

        builder.addCase(fetchPayments.fulfilled, (state, { payload }) => {
            const userToEdit = state.list.find(
                user => user.id === payload.userId,
            );

            if (userToEdit?.payments) {
                userToEdit.payments = payload.payments;
            }

            toast.dismiss(loadingToast);
            toast.success("Dati pagamenti caricati!");

            state.loader = false;
        });

        builder.addCase(fetchPayments.rejected, state => {
            toast.dismiss(loadingToast);
            toast.error("Errore caricamento dati pagamenti");

            state.loader = false;
        });
    },
});

// Action creators
export const {
    actions: {
        setLoader,
        addUser,
        addPayment,
        updateUserData,
        updatePaymentData,
        reset,
        resetPayments,
        updateUserStatus,
        updateUserBalance,
    },
} = usersSlice;

// Getters
export const getUsersState = (state: RootState): Users => state.users;
export const getUsersLoader = (state: RootState): boolean => state.users.loader;

// Async thunks
export const fetchUsers = createAsyncThunk<UserInfo[], void>(
    "users/fetchUsers",
    async (_, { rejectWithValue }) => {
        let users: UserInfo[] = [];

        try {
            users = await getUsers();
        } catch (error) {
            rejectWithValue(error);
        }

        return users;
    },
);

export const fetchPayments = createAsyncThunk<
    { collectionName: string; userId: string; payments: Payment[] },
    { collectionName: string; userId: string }
>(
    "users/fetchPayments",
    async ({ collectionName, userId }, { rejectWithValue }) => {
        let payments: Payment[] = [];
        try {
            payments = await getPayments(collectionName, userId);
        } catch (error) {
            rejectWithValue(error);
        }

        return { collectionName, userId, payments };
    },
);
