import * as React from "react";
import { Button, Stack } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { signOut } from "firebase/auth";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { FileExportSVG, StopCircleSVG } from "../../assets/icons";
import { pagesPaths } from "../../constants";
import { auth } from "../../firebase";
import { logout } from "../../store/user/user.slice";
import theme from "../../theme";
import { useAppDispatch } from "../../utils/hooks";
import CreateRequestModal from "../CreateRequestModal";
import ExportCSV from "../ExportCSV";
import useStyles from "./style";
import FormDialog from "../MTFormDialog";
import { getUsersState } from "../../store/users/users.slice";
import { useSelector } from "react-redux";
import { useManageUser } from "../../hooks";
import { UserInfo } from "../../store/users/types";

type HeaderInfo = {
    text: string;
    fullPath?: string;
};

const MTHeader: React.FC = () => {
    const classes = useStyles();
    const currentPath = useLocation().pathname;
    const splittedPath = currentPath.split("/");
    const dispatch = useAppDispatch();
    const { list: users } = useSelector(getUsersState);
    const [userAddress, setUserAddress] = useState("");
    const { manageUserStatus } = useManageUser();
    const [headerInfo, setHeaderInfo] = useState<HeaderInfo>({
        text: "",
    });
    const [showRequestModal, setShowRequestModal] = useState(false);
    const [visibilityCsvDialog, setVisibilityCsvDialog] =
        useState<boolean>(false);
    const [user, setUser] = useState<UserInfo>();

    const manageLockUser = (data: { motivation: string }) => {
        if (user?.id) {
            manageUserStatus(
                user.id,
                `${user.category}s`,
                splittedPath[4],
                "lock",
                data.motivation,
            );
        }

        closeDialog();
    };

    const manageUnlockUser = () => {
        if (user?.id) {
            manageUserStatus(
                user.id,
                `${user.category}s`,
                splittedPath[4],
                "unlock",
            );
        }

        closeDialog();
    };

    const closeDialog = () => {
        setUserAddress("");
    };

    const logOut = () => {
        signOut(auth)
            .then(() => {
                dispatch(logout());
            })
            .catch(error => {
                console.log(error);
            });
    };

    useEffect(() => {
        const result: HeaderInfo = {
            text: "",
        };

        if (splittedPath.length > 2) {
            const pathToCompare = splittedPath.slice(0, -1).join("/");

            const pathInfo = pagesPaths.find(
                option => option.path === pathToCompare,
            );

            result.text = pathInfo?.text ? pathInfo?.text : "";
            result.fullPath = pathInfo?.fullPath ? pathInfo?.fullPath : "";
        } else {
            const pathInfo = pagesPaths.find(
                option => option.path === currentPath,
            );

            result.text = pathInfo?.text ? pathInfo?.text : "";
        }

        setHeaderInfo(result);
    }, [currentPath]);

    useEffect(() => {
        let userSelected: any = undefined;
        if (splittedPath[1]) {
            userSelected = users.find(
                user => user.walletAddress === splittedPath[4],
            );
        }
        setUser(userSelected);
    }, [currentPath, users]);

    return (
        <>
            <CreateRequestModal
                open={showRequestModal}
                handleClose={() => setShowRequestModal(false)}
            />
            <Box sx={{ flexGrow: 1 }}>
                <Box className={classes.topSpace}>
                    <Typography
                        sx={{
                            textDecoration: "underline",
                            cursor: "pointer",
                        }}
                        variant="h6"
                        gutterBottom
                        component="div"
                        onClick={logOut}
                    >
                        Log out
                    </Typography>
                </Box>
                <AppBar
                    sx={{
                        backgroundColor: theme.palette.primary.light,
                    }}
                    position="static"
                    className={classes.appBar}
                >
                    <div>
                        <Typography
                            sx={{
                                marginBottom: 0,
                                fontSize: "30px",
                            }}
                            variant="h4"
                            gutterBottom
                            component="div"
                        >
                            {headerInfo?.text}
                        </Typography>
                        {headerInfo.fullPath && (
                            <Typography
                                sx={{
                                    marginBottom: 0,
                                    fontSize: "16px",
                                    marginLeft: "2px",
                                    marginTop: "5px",
                                }}
                                variant="h6"
                                gutterBottom
                                component="div"
                            >
                                {headerInfo?.fullPath}
                            </Typography>
                        )}
                    </div>
                    {splittedPath[3] === "info-wallet" && (
                        <Stack spacing={2} direction="row">
                            <Button
                                variant="outlined"
                                style={{
                                    width: "190px",
                                    display: "flex",
                                    justifyContent: "space-between",
                                }}
                                onClick={() => setUserAddress(splittedPath[4])}
                            >
                                {user?.status === 1
                                    ? "Blocca account"
                                    : "Sblocca account"}
                                <StopCircleSVG style={{ fontSize: 40 }} />
                            </Button>
                            <Button
                                variant="outlined"
                                style={{
                                    width: "160px",
                                    display: "flex",
                                    justifyContent: "space-between",
                                }}
                                onClick={() => setVisibilityCsvDialog(true)}
                            >
                                Esporta dati <FileExportSVG />
                            </Button>
                        </Stack>
                    )}
                    {splittedPath[1] === "admin-panel" && (
                        <Stack spacing={2} direction="row">
                            <Button
                                variant="outlined"
                                style={{
                                    width: "180px",
                                    display: "flex",
                                    justifyContent: "space-between",
                                }}
                                onClick={() => setShowRequestModal(true)}
                            >
                                Invia richiesta <FileExportSVG />
                            </Button>
                        </Stack>
                    )}
                    {(splittedPath[1] === "users" &&
                        splittedPath[3] === undefined) ||
                    splittedPath[1] === "transactions" ? (
                        <Button
                            variant="contained"
                            style={{
                                width: "170px",
                                display: "flex",
                                color: "white",
                            }}
                            onClick={() => setVisibilityCsvDialog(true)}
                        >
                            ESPORTA .CSV
                        </Button>
                    ) : (
                        <></>
                    )}
                </AppBar>
                <ExportCSV
                    showModal={visibilityCsvDialog}
                    handleClose={() => setVisibilityCsvDialog(false)}
                    exportType={
                        splittedPath[3] === "info-wallet"
                            ? splittedPath[3]
                            : splittedPath[1]
                    }
                />
                <FormDialog
                    inputVisibility={user?.status === 1}
                    visibility={userAddress}
                    title={
                        user?.status === 1
                            ? "Vuoi bloccare l'utente?"
                            : "Vuoi sbloccare l'utente?"
                    }
                    onSubmitFunc={
                        user?.status === 1 ? manageLockUser : manageUnlockUser
                    }
                    onCloseFunc={closeDialog}
                />
            </Box>
        </>
    );
};

export default MTHeader;
