import Button from "@mui/lab/LoadingButton";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import React, { useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import useStyles from "./style";
import { useTransfer } from "../../hooks";
import { MenuItem, Select, SelectChangeEvent } from "@mui/material";
import {
    addDoc,
    collection,
    getDoc,
    getDocs,
    query,
    where,
} from "firebase/firestore";
import { firestore } from "../../firebase";
import useCheckAddress from "../../hooks/useCheckAddress";
import { toast } from "react-toastify";

type Inputs = {
    amount: number;
    reasonForPayment?: string;
    address: string;
};

const Payments: React.FC = () => {
    const classes = useStyles();

    const { executeTransfer, loader } = useTransfer();
    const { checkIfAddressExist } = useCheckAddress();
    const [reason, setReason] = useState<string>("bankwire");

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<Inputs>();

    const onSubmit: SubmitHandler<Inputs> = async data => {
        const { address, reasonForPayment, amount } = data;

        if (!(await checkIfAddressExist(address))) {
            toast.error("L'indirizzo inserito non corrisponde a nessun utente");
            return;
        }

        executeTransfer(address, amount.toString(), reasonForPayment)?.then(
            async res => {
                if (reasonForPayment === "bankwire") {
                    const q = query(
                        collection(firestore, "publicProfiles"),
                        where("walletAddress", "==", address),
                    );

                    const querySnapshot = await getDocs(q);
                    let userInfo: any = {};
                    querySnapshot.forEach(doc => {
                        userInfo = { id: doc.id, ...doc.data() };
                    });

                    if (userInfo) {
                        const paymentDocument = {
                            type: "Bonifico",
                            content: {
                                amount: amount.toString(),
                                createdAt: new Date().toISOString(),
                            },
                        };

                        const collectionPath =
                            userInfo?.userType === "consumer"
                                ? `consumers/${userInfo?.id}/payments`
                                : `merchants/${userInfo?.id}/payments`;

                        await addDoc(
                            collection(firestore, collectionPath),
                            paymentDocument,
                        );
                    }
                }
            },
        );
    };

    const handleChange = (event: SelectChangeEvent) => {
        setReason(event.target.value as string);
    };

    return (
        <div className={classes.flexContainer}>
            <Box
                sx={{
                    width: 400,
                    maxWidth: "100%",
                }}
            >
                <form
                    onSubmit={handleSubmit(onSubmit)}
                    className={classes.flexContainer}
                >
                    <TextField
                        fullWidth
                        label="Importo in MIT"
                        id="fullWidth"
                        type="number"
                        {...register("amount", { required: true })}
                        error={!!errors.amount}
                    />

                    <TextField
                        fullWidth
                        sx={{ marginTop: "30px" }}
                        label="address"
                        id="fullWidth"
                        {...register("address", { required: true })}
                        error={!!errors.address}
                    />

                    <Select
                        fullWidth
                        sx={{ marginTop: "30px" }}
                        labelId="reason-select-label"
                        id="reason-select"
                        value={reason}
                        label="Causale"
                        {...register("reasonForPayment")}
                        error={!!errors.reasonForPayment}
                        onChange={handleChange}
                    >
                        <MenuItem value="bankwire">Bonifico</MenuItem>
                        <MenuItem value="other">Altro</MenuItem>
                    </Select>

                    <Button
                        sx={{ color: "#ffff", marginTop: "30px" }}
                        variant="contained"
                        type="submit"
                        loading={loader}
                    >
                        Trasferisci MIT
                    </Button>
                </form>
            </Box>
        </div>
    );
};
export default Payments;
