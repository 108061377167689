import { Theme } from "@mui/material/styles";
import { createStyles, makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        primaryThemeColor: {
            color: theme.palette.primary.main,
        },
        accordion: {
            backgroundColor: "transparent",
            boxShadow: "none",
            position: "static",
        },
        accordionTitle: {
            color: theme.palette.primary.main,
            fontWeight: "bold",
        },
        accordionSummary: {
            borderBottom: "solid 2px black",
        },
        accordionDetails: {
            marginTop: "1rem",
        },
        flexContainer: {
            width: "100%",
            height: "100%",
            display: "flex",
            flexWrap: "wrap",
            flexDirection: "column",
        },
        inputsContainer: {
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            flexWrap: "wrap",
        },
        inputBlock: {
            width: "calc(33.3% - 1.33rem)",
            marginBottom: "2rem",
            "&:nth-child(3n -1)": {
                marginLeft: "2rem",
                marginRight: "2rem",
            },
        },
        error: {
            cursor: "pointer",
            color: "red",
            marginBottom: 0,
            marginTop: "10px",
            fontSize: "18px",
        },
        button: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "30px",
        },
    }),
);

export default useStyles;
