import { PayloadAction } from "@reduxjs/toolkit";
import { RequestData, AdminRequests } from "./types";
import { requestsInitialState } from "./requests.slice";

import { assign } from "lodash";

export const requestsReducer = {
    setLoader: {
        reducer: (
            state: AdminRequests,
            action: PayloadAction<{
                value: boolean;
            }>,
        ): void => {
            state.loader = action.payload.value;
        },
        prepare: (value: boolean) => ({
            payload: {
                value,
            },
        }),
    },
    addRequest: {
        reducer: (
            state: AdminRequests,
            action: PayloadAction<{
                requests: RequestData;
            }>,
        ): void => {
            state.list.push(action.payload.requests);
        },
        prepare: (requests: RequestData) => ({
            payload: {
                requests,
            },
        }),
    },
    updateRequestData: {
        reducer: (
            state: AdminRequests,
            action: PayloadAction<{
                requestData: RequestData;
            }>,
        ): void => {
            const { requestData } = action.payload;

            const requestToEdit = state.list.find(
                request => request.id === requestData.id,
            );

            assign(requestToEdit, requestData);
        },
        prepare: (requestData: RequestData) => ({
            payload: {
                requestData,
            },
        }),
    },
    reset: () => requestsInitialState,
};
