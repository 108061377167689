import { Theme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) => ({
    fieldsContainer: {
        height: "calc(100% - 80px)",
        overflowY: "scroll",
        paddingTop: "10px",
        paddingRight: "10px",
    },
    flexContainer: {
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginBottom: "35px",
    },
    deleteIcon: {
        color: theme.palette.primary.light,
    },
    form: {
        height: "100%",
    },
    flexModalHeader: {
        display: "flex",
        justifyContent: "end",
    },
    flexModalContainer: {
        padding: 20,
        display: "flex",
        flexDirection: "column",
    },
    modalContainer: {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        backgroundColor: "white",
        borderRadius: 20,
        minWidth: "600px",
        p: 4,
    },
}));

export default useStyles;
