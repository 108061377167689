import { useState } from "react";

import { updateDoc, doc } from "firebase/firestore";
import { firestore } from "../firebase";
import { Limits } from "../store/settings/types";

import { toast } from "react-toastify";

const useSettings = () => {
    const [loader, setLoader] = useState<boolean>(false);

    const updateLimits = async (limits: Limits) => {
        if (loader) {
            toast.warning("Attendere l'operazione in corso");
            return;
        }

        setLoader(true);
        const loadingToast = toast.loading("Operazione in corso");

        const docRef = doc(firestore, "settings", "limits");

        try {
            await updateDoc(docRef, limits);
            toast.success("Limiti aggiornati con successo!");
        } catch {
            toast.error("Aggiornamento limiti fallito");
        }

        toast.dismiss(loadingToast);
        setLoader(false);
    };

    return { updateLimits };
};

export default useSettings;
