import React from "react";
import { IconButton, Stack } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import DownloadIcon from "@mui/icons-material/Download";
import FileSaver from "file-saver";

import useStyles from "./style";
import { toast } from "react-toastify";
import { useStorage } from "../../../hooks";

type Props = {
    name: string;
    path: string;
    handleDelete: (path: string) => void;
};

const DocumentItem: React.FC<Props> = ({ name, path, handleDelete }) => {
    const classes = useStyles();
    const { getFileBlob } = useStorage();

    const downloadDocument = async () => {
        const splittedPath = path.split("/");
        const blob = await getFileBlob(path);
        if (blob) {
            FileSaver.saveAs(blob, splittedPath[3]);
        }
    };

    const deleteDocument = async () => {
        try {
            await handleDelete(path);
            toast.success("Documento eliminato con successo!", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        } catch (e) {
            toast.success("Errore durante l'eliminazione del documento!", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    };

    return (
        <Stack
            direction="row"
            justifyContent="space-evenly"
            alignItems="center"
            className={classes.inputBlock}
        >
            <p className={classes.documentName}>{name}</p>
            <div>
                <IconButton onClick={downloadDocument}>
                    <DownloadIcon />
                </IconButton>
                <IconButton onClick={deleteDocument}>
                    <DeleteIcon />
                </IconButton>
            </div>
        </Stack>
    );
};

export default DocumentItem;
