import { BrowserRouter, Route, Routes } from "react-router-dom";
import AnnualLimitsAndOperations from "../pages/OperationsLimits";
import UserDetail from "../pages/UserDetail";
import AdminPanel from "../pages/AdminPanel";
import Login from "../pages/Login";
import Payments from "../pages/Payments";
import ProfileSettings from "../pages/ProfileSettings";
import TransactionHistory from "../pages/TransacionsHistory";
import Users from "../pages/Users";
import VipLevels from "../pages/VipStatus";
import Layout from "../templates/Layout";
import CustomRoute from "../utils/components/CustomRoute";
import styles from "./App.module.scss";
import { useEffect } from "react";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { setEmail, setJwtToken } from "../store/user/user.slice";
import { getUsersLoader } from "../store/users/users.slice";
import { useSelector } from "react-redux";
import ResetPassword from "../pages/ResetPsw";
import { useAppDispatch } from "../utils/hooks";
import { LicenseInfo } from "@mui/x-license-pro";

LicenseInfo.setLicenseKey(
    "a855dbfc9a8fa7feca90d477c7314b3bT1JERVI6NDI5MzIsRVhQSVJZPTE2ODMxMjU3NTkwMDAsS0VZVkVSU0lPTj0x",
);
import { signOut } from "firebase/auth";
import { logout } from "../store/user/user.slice";

import { toast } from "react-toastify";

const App: React.FC = () => {
    const dispatch = useAppDispatch();

    const usersLoader = useSelector(getUsersLoader);

    useEffect(() => {
        const auth = getAuth();
        const unsubAuth = onAuthStateChanged(auth, async user => {
            if (user) {
                const { claims } = await user.getIdTokenResult();
                if (claims.role === "admin") {
                    const jwtToken = await user.getIdToken();
                    dispatch(setJwtToken(jwtToken));
                    if (user.email) {
                        dispatch(setEmail(user.email));
                    }
                } else {
                    signOut(auth)
                        .then(() => {
                            toast.error(
                                "L'account inserito non possiede i privilegi da admin",
                            );
                            dispatch(logout());
                        })
                        .catch(error => {
                            console.log(error);
                        });
                }
            }
        });
        return () => {
            unsubAuth();
        };
    }, []);

    return (
        <div className={styles.app}>
            <BrowserRouter>
                <Routes>
                    <Route
                        path="/"
                        element={
                            <CustomRoute requireAuth={false}>
                                <Login />
                            </CustomRoute>
                        }
                    />
                    {!usersLoader && (
                        <Route
                            path="users"
                            element={
                                <CustomRoute requireAuth={true}>
                                    <Layout>
                                        <Users />
                                    </Layout>
                                </CustomRoute>
                            }
                        />
                    )}
                    <Route
                        path="users/:category/info-wallet/:id"
                        element={
                            <CustomRoute requireAuth={true}>
                                <Layout>
                                    <UserDetail />
                                </Layout>
                            </CustomRoute>
                        }
                    />
                    <Route
                        path="transactions"
                        element={
                            <CustomRoute requireAuth={true}>
                                <Layout>
                                    <TransactionHistory />
                                </Layout>
                            </CustomRoute>
                        }
                    />
                    <Route
                        path="reimposta-password"
                        element={
                            <CustomRoute requireAuth={true}>
                                <Layout>
                                    <ResetPassword />
                                </Layout>
                            </CustomRoute>
                        }
                    />
                    <Route
                        path="/operations-limits"
                        element={
                            <CustomRoute requireAuth={true}>
                                <Layout>
                                    <AnnualLimitsAndOperations />
                                </Layout>
                            </CustomRoute>
                        }
                    />
                    <Route
                        path="admin-panel"
                        element={
                            <CustomRoute requireAuth={true}>
                                <Layout>
                                    <AdminPanel />
                                </Layout>
                            </CustomRoute>
                        }
                    />
                    <Route
                        path="profile-settings"
                        element={
                            <CustomRoute requireAuth={true}>
                                <Layout>
                                    <ProfileSettings />
                                </Layout>
                            </CustomRoute>
                        }
                    />
                    <Route
                        path="vip-status"
                        element={
                            <CustomRoute requireAuth={true}>
                                <Layout>
                                    <VipLevels />
                                </Layout>
                            </CustomRoute>
                        }
                    />
                    <Route
                        path="payments"
                        element={
                            <CustomRoute requireAuth={true}>
                                <Layout>
                                    <Payments />
                                </Layout>
                            </CustomRoute>
                        }
                    />
                </Routes>
            </BrowserRouter>
        </div>
    );
};

export default App;
