import { collection, getDocs, query } from "firebase/firestore";

import { firestore } from "../../firebase";
import { RequestData } from "./types";

// Functions that fetch firestore data
export const getFormattedRequests = async () => {
    const requestsRef = collection(firestore, "adminOperationsRequests");

    const requestsQuery = query(requestsRef);

    const requestsSnapshot = await getDocs(requestsQuery);

    const requestsData = requestsSnapshot.docs.map(doc => {
        return { id: doc.id, ...doc.data() };
    });

    return requestsData as RequestData[];
};
