import { Theme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) => ({
    flexContainer: {
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexWrap: "wrap",
        flexDirection: "column",
    },
    button: {
        marginTop: "50px",
        color: theme.palette.primary.light,
    },
    emailInput: {
        marginBottom: "20px",
    },
}));

export default useStyles;
