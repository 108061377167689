import { useEffect, useState } from "react";
import { ethers } from "ethers";
import { allowanceContract } from "../../utils/utils";

import { User } from "../../store/user/types";
import { useSelector } from "react-redux";

import { toast } from "react-toastify";

import { mitToken } from "../../contracts";

import { safeProxyAddress } from "../../constants";

type Allowance = {
    amount: number;
    resetTime: number;
    available: number;
};

const useAdminInfo = () => {
    const [loader, setLoader] = useState<boolean>(false);
    const [allowance, setAllowance] = useState<Allowance>({
        amount: 0,
        resetTime: 0,
        available: 0,
    });

    const {
        wallet: { privateKey, address },
    } = useSelector(({ user }: { user: User }) => user);

    if (!privateKey) {
        toast.error(
            "La chiave privata non é valida, le operazioni sulla blockchain sono bloccate",
            {
                toastId: "privateKeyError",
            },
        );
        return {
            loader: false,
            executeTransfer: () => null,
        };
    }

    const getAllowance = async () => {
        const allowance = await allowanceContract.allowances(
            safeProxyAddress,
            address,
            mitToken.contract_address,
        );

        const formattedAmount = ethers.utils.formatEther(
            allowance.amount.toString(),
        );

        const spent = ethers.utils.formatEther(allowance.spent.toString());

        return {
            amount: parseInt(formattedAmount),
            resetTime: allowance.resetTimeMin,
            available: parseInt(formattedAmount) - parseInt(spent),
        };
    };

    const initialize = async () => {
        const allowance = await getAllowance();
        setAllowance(allowance);

        allowanceContract.on("SetAllowance", () => {
            initialize();
        });

        return () => {
            allowanceContract.removeAllListeners();
        };
    };

    useEffect(() => {
        initialize();
    }, []);

    return { loader, allowance };
};

export default useAdminInfo;
