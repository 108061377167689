import { Theme } from "@mui/material/styles";
import { tableCellClasses } from "@mui/material/TableCell";
import { createStyles, makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) => {
    return createStyles({
        container: {
            height: "100%",
        },
        tableCell: {
            [`&.${tableCellClasses.head}`]: {
                backgroundColor: theme.palette.primary.main,
                color: theme.palette.common.white,
                fontWeight: "bold",
            },
            [`&.${tableCellClasses.body}`]: {
                fontSize: 14,
            },
        },
        detailLink: {
            color: "#3B0C3D",
            textDecoration: "underline",
            cursor: "pointer",
        },
        mitAmountContainer: {
            backgroundColor: "#fff",
            borderRadius: 30,
            width: 400,
            paddingTop: 5,
            paddingBottom: 5,
            paddingLeft: 20,
            paddingRight: 10,
            fontSize: 26,
            border: "solid 3px #E0E0E0",
            marginBottom: "30px",
        },
        mitAmount: {
            fontWeight: "bold",
            textAlign: "right",
            fontSize: 40,
            marginTop: 20,
            marginRight: 20,
        },
        titleMitAmount: {
            marginTop: 15,
        },
        flexEndContainer: {
            display: "flex",
            width: "calc(25% - 22.5px)",
        },
        boxContainer: {
            display: "flex",
            justifyContent: "space-between",
        },
    });
});

export default useStyles;
