import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Button, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { useStorage } from "../../../hooks";
import { Document } from "../../../store/users/types";
import ModalDocument from "../../ModalDocument";
import useStyles from "./style";

type Props = {
    userData: Document;
    userUid: string;
    isDelegate?: boolean;
};

const DocumentForm: React.FC<Props> = ({ userData, userUid, isDelegate }) => {
    const classes = useStyles();
    const [showDocument, setShowDocument] = useState(false);
    const [fileToShow, setFileToShow] = useState("");

    const { getFilePath } = useStorage();
    const currentPath = useLocation().pathname;
    const splittedPath = currentPath.split("/");
    const accountTypology = splittedPath[2];

    const returnFilePath = async (uid: string, file: string) => {
        const tier = isDelegate ? "tier_two" : "tier_one";
        return await getFilePath(
            `${accountTypology}s/${uid}/kwc/${tier}/${file}.jpg`,
        );
    };

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
        control,
    } = useForm<Document>({
        defaultValues: {
            type: userData.type || "",
            number: userData.number || "",
            issuingEntity: userData?.issuingEntity || "",
            issuingDate: new Date(userData.issuingDate.seconds * 1000),
            expirationDate: new Date(userData.expirationDate.seconds * 1000),
        },
    });

    const onSubmit: SubmitHandler<Document> = data => {
        reset(data);
        toast.success("Dati DB salvati!");
    };

    const disabled = userData ? true : false;

    const handleShowDocumentClick = (type: string) => {
        returnFilePath(userUid, type).then(value => {
            setFileToShow(value);
            setShowDocument(prevState => !prevState);
        });
    };

    return (
        <>
            {showDocument ? (
                <ModalDocument
                    open={showDocument}
                    handleClose={() => {
                        setShowDocument(false);
                        setFileToShow("");
                    }}
                    filePath={fileToShow}
                />
            ) : (
                <form
                    onSubmit={handleSubmit(onSubmit)}
                    className={classes.flexContainer}
                >
                    <div className={classes.inputsContainer}>
                        <div className={classes.inputBlock}>
                            <TextField
                                fullWidth
                                label="Tipo documento"
                                id="fullWidth"
                                {...register("type", {
                                    required: true,
                                })}
                                disabled={disabled}
                            />

                            <Typography
                                className={classes.error}
                                variant="h6"
                                gutterBottom
                                component="div"
                            >
                                {errors.type && (
                                    <span>This field is required</span>
                                )}
                            </Typography>
                        </div>

                        <div className={classes.inputBlock}>
                            <TextField
                                fullWidth
                                label="Numero documento"
                                id="fullWidth"
                                {...register("number", {
                                    required: true,
                                })}
                                disabled={disabled}
                            />

                            <Typography
                                className={classes.error}
                                variant="h6"
                                gutterBottom
                                component="div"
                            >
                                {errors.number && (
                                    <span>This field is required</span>
                                )}
                            </Typography>
                        </div>

                        <div className={classes.inputBlock}>
                            <TextField
                                fullWidth
                                label="Ente di rilascio"
                                id="fullWidth"
                                {...register("issuingEntity", {
                                    required: true,
                                })}
                                disabled={disabled}
                            />

                            <Typography
                                className={classes.error}
                                variant="h6"
                                gutterBottom
                                component="div"
                            >
                                {errors.issuingEntity && (
                                    <span>This field is required</span>
                                )}
                            </Typography>
                        </div>

                        <div className={classes.inputBlock}>
                            <Controller
                                control={control}
                                name={`issuingDate`}
                                render={({ field }) => (
                                    <LocalizationProvider
                                        dateAdapter={AdapterDayjs}
                                    >
                                        <DatePicker
                                            inputFormat="DD/MM/YYYY"
                                            label="Data emissione"
                                            value={field.value}
                                            disabled={disabled}
                                            className={classes.datePicker}
                                            onChange={newValue => {
                                                field.onChange(newValue);
                                            }}
                                            disableFuture
                                            InputProps={{
                                                disabled: true,
                                            }}
                                            renderInput={params => (
                                                <TextField
                                                    onKeyDown={e => {
                                                        e.preventDefault();
                                                    }}
                                                    className={classes.date}
                                                    {...params}
                                                />
                                            )}
                                        />
                                    </LocalizationProvider>
                                )}
                            />

                            <Typography
                                className={classes.error}
                                variant="h6"
                                gutterBottom
                                component="div"
                            >
                                {errors.issuingDate && (
                                    <span>This field is required</span>
                                )}
                            </Typography>
                        </div>

                        <div className={classes.inputBlock}>
                            <Controller
                                control={control}
                                name={`expirationDate`}
                                render={({ field }) => (
                                    <LocalizationProvider
                                        dateAdapter={AdapterDayjs}
                                    >
                                        <DatePicker
                                            inputFormat="DD/MM/YYYY"
                                            label="Data scadenza"
                                            disabled={disabled}
                                            value={field.value}
                                            className={classes.datePicker}
                                            onChange={newValue => {
                                                field.onChange(newValue);
                                            }}
                                            InputProps={{
                                                disabled: true,
                                            }}
                                            renderInput={params => (
                                                <TextField
                                                    onKeyDown={e => {
                                                        e.preventDefault();
                                                    }}
                                                    className={classes.date}
                                                    {...params}
                                                />
                                            )}
                                        />
                                    </LocalizationProvider>
                                )}
                            />

                            <Typography
                                className={classes.error}
                                variant="h6"
                                gutterBottom
                                component="div"
                            >
                                {errors.issuingDate && (
                                    <span>This field is required</span>
                                )}
                            </Typography>
                        </div>

                        <div className={classes.inputFullBlock}>
                            <Button
                                onClick={() =>
                                    handleShowDocumentClick(
                                        isDelegate
                                            ? "document_photo_back_delegate"
                                            : "document_photo_back",
                                    )
                                }
                                className={classes.documentButton}
                            >
                                Visualizza foto documento fronte
                            </Button>
                        </div>
                        <div className={classes.inputFullBlock}>
                            <Button
                                onClick={() =>
                                    handleShowDocumentClick(
                                        isDelegate
                                            ? "document_photo_front_delegate"
                                            : "document_photo_front",
                                    )
                                }
                                className={classes.documentButton}
                            >
                                Visualizza foto documento retro
                            </Button>
                        </div>
                        <div className={classes.inputFullBlock}>
                            <Button
                                onClick={() =>
                                    handleShowDocumentClick(
                                        isDelegate
                                            ? "profile_photo_delegate"
                                            : "profile_photo",
                                    )
                                }
                                className={classes.documentButton}
                            >
                                Visualizza foto viso
                            </Button>
                        </div>
                    </div>
                </form>
            )}
        </>
    );
};

export default DocumentForm;
