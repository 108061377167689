import React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import useStyles from "./style";
import { useValidation, useYupValidationResolver } from "../../hooks";
import { login } from "../../store/user/user.slice";
import { useAppDispatch } from "../../utils/hooks";

type Inputs = {
    email: string;
    password: string;
};

const Login: React.FC = () => {
    const classes = useStyles();
    const { required, validatePassword, validateEmail } = useValidation();

    const dispatch = useAppDispatch();

    const loginForm = {
        initialValues: {
            email: "",
            password: "",
        },
        validationSchema: Yup.object({
            email: required(validateEmail()),
            password: required(validatePassword()),
        }),
    };

    const { initialValues, validationSchema } = loginForm;

    const resolver = useYupValidationResolver(validationSchema);

    const formState = useForm<Inputs>({
        defaultValues: initialValues,
        resolver,
        mode: "onChange",
    });

    const {
        handleSubmit,
        formState: { errors },
        register,
        getValues,
    } = formState;

    const onSubmit = async () => {
        const credentials: { email: string; password: string } = getValues();

        dispatch(login(credentials));
    };

    return (
        <div className={classes.flexContainer}>
            <Box
                sx={{
                    width: 400,
                    maxWidth: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                }}
            >
                <h1>LOGIN</h1>
                <form
                    onSubmit={handleSubmit(onSubmit)}
                    className={classes.flexContainer}
                >
                    {/* register your input into the hook by invoking the "register" function */}
                    <TextField
                        fullWidth
                        label="Email"
                        id="fullWidth"
                        classes={{
                            root: classes.emailInput,
                        }}
                        {...register("email", { required: true })}
                        error={Boolean(errors.email)}
                        helperText={errors.email && "This field is required"}
                    />

                    <TextField
                        fullWidth
                        label="Password"
                        id="fullWidth"
                        type="password"
                        {...register("password", { required: true })}
                        error={Boolean(errors.password)}
                        helperText={errors.password && "This field is required"}
                    />

                    <Button
                        sx={{ color: "#ffff", marginTop: "30px" }}
                        classes={{
                            root: classes.button,
                        }}
                        variant="contained"
                        type="submit"
                    >
                        Log in
                    </Button>
                </form>
            </Box>
        </div>
    );
};
export default Login;
