import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { ethers } from "ethers";
import React, { useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { provider } from "../../constants";
import { useValidation, useYupValidationResolver } from "../../hooks";
import { User } from "../../store/user/types";
import { setWallet } from "../../store/user/user.slice";
import useStyles from "./style";
import { useResetPasswordMutation } from "../../services/firestoreRestApi";
import { getAuth } from "firebase/auth";
import FormDialog from "../../components/MTFormDialog";

type Inputs = {
    privateKey: string;
};

const Login: React.FC = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { validatePrivateKey, required } = useValidation();
    const { wallet, email } = useSelector(({ user }: { user: User }) => user);
    const [resetPassword] = useResetPasswordMutation();
    const [operation, setOperation] = useState("");

    const loginForm = {
        initialValues: {
            privateKey: wallet.privateKey || "",
        },
        validationSchema: Yup.object({
            privateKey: required(validatePrivateKey()),
        }),
    };

    const { initialValues, validationSchema } = loginForm;

    const resolver = useYupValidationResolver(validationSchema);

    const formState = useForm<Inputs>({
        defaultValues: initialValues,
        resolver,
        mode: "onChange",
    });

    const {
        handleSubmit,
        formState: { errors },
        register,
    } = formState;

    const onSubmit: SubmitHandler<Inputs> = data => {
        const adminWallet = new ethers.Wallet(data.privateKey, provider);
        if (adminWallet) {
            dispatch(
                setWallet({
                    privateKey: data.privateKey,
                    address: adminWallet.address,
                    mnemonic: adminWallet._mnemonic.name,
                }),
            );
            toast.success("Chiave privata salvata con successo!");
        } else {
            toast.error("Private key inesistente");
        }
    };

    const handleRecoverPassword = async () => {
        const auth = getAuth();
        if (auth.currentUser) {
            await resetPassword({
                accountType: "backoffice",
                lang: "it",
                email,
            }).then(() => {
                toast.success("Mail inviata");
            });
        } else {
            toast.error("Impossibile reperire mail utente");
        }
        closeDialog();
    };

    const closeDialog = () => {
        setOperation("");
    };

    return (
        <Box>
            <Typography
                className={classes.title}
                variant="h6"
                gutterBottom
                component="div"
            >
                Inserisci la tua chiave privata
            </Typography>
            <form
                onSubmit={handleSubmit(onSubmit)}
                className={classes.flexContainer}
            >
                <div className={classes.inputsContainer}>
                    <div className={classes.inputBlock}>
                        <TextField
                            fullWidth
                            label="Chiave privata"
                            id="fullWidth"
                            {...register("privateKey", { required: true })}
                        />

                        <Typography
                            className={classes.error}
                            variant="h6"
                            gutterBottom
                            component="div"
                        >
                            {errors.privateKey && (
                                <span>{errors.privateKey.message}</span>
                            )}
                        </Typography>
                    </div>
                    <div className={classes.inputBlock}>
                        <Button
                            classes={{
                                root: classes.button,
                            }}
                            variant="contained"
                            type="submit"
                            disabled={"privateKey" in errors}
                        >
                            Salva
                        </Button>
                    </div>
                </div>
                <div className={classes.inputsContainer}>
                    <div className={classes.inputBlock}>
                        <Button
                            classes={{
                                root: classes.resetButton,
                            }}
                            variant="contained"
                            onClick={() => {
                                setOperation("changePassword");
                            }}
                        >
                            Cambia password
                        </Button>
                    </div>
                </div>
            </form>
            <FormDialog
                inputVisibility={false}
                title="Vuoi cambiare password?"
                visibility={operation}
                onSubmitFunc={handleRecoverPassword}
                onCloseFunc={closeDialog}
            />
        </Box>
    );
};
export default Login;
