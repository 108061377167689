import { createStyles, makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => {
    return createStyles({
        container: {
            marginLeft: "280px",
        },
        form: {
            width: "500px",
        },
    });
});

export default useStyles;
