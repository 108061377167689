import {
    Button,
    Checkbox,
    FormControlLabel,
    TextField,
    Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { SubmitHandler, useForm, Controller } from "react-hook-form";
import { toast } from "react-toastify";
import { useCallbackState, useStorage } from "../../../hooks";
import ModalDocument from "../../ModalDocument";
import useStyles from "./style";

type Inputs = {
    annualVolumes: number;
    fundsProvenance: string;
    economicallyExposedPerson: boolean;
};

type Props = {
    userData?: any;
    userType?: string;
};

const getCategoryNameFromCode = (foundProvenanceCategory: string) => {
    switch (foundProvenanceCategory) {
        case "job_provenance":
            return "Reddito da lavoro dipendente";
        case "freelance_provenance":
            return "Reddito da libera professione";
        case "commercial_provenance":
            return "Reddito da attività commerciale/imprenditoriale";
        case "investment_provenance":
            return "Investimenti";
        case "financing_provenance":
            return "Finanziamenti";
        case "other_provenance":
            return "Altro (Non specificato)";
        case "savings_provenance":
            return "Risparmi";
    }
};

const RegulationsForm: React.FC<Props> = ({ userData, userType }) => {
    const classes = useStyles();
    const [showDocument, setShowDocument] = useState(false);
    const [file, setFile] = useCallbackState("");

    const { getAllFileUploaded, getFilePath } = useStorage();

    const {
        register,
        handleSubmit,
        formState: { errors },
        control,
        reset,
    } = useForm<Inputs>({
        defaultValues: {
            annualVolumes: userData?.tierTwo?.annualVolumes || 0,
            fundsProvenance:
                getCategoryNameFromCode(userData?.tierTwo?.fundsProvenance) ||
                "",
            economicallyExposedPerson:
                userData?.tierTwo?.beneficialOwner?.economicallyExposedPerson ||
                userData?.tierTwo?.economicallyExposedPerson
                    ? true
                    : false,
        },
    });

    const onSubmit: SubmitHandler<Inputs> = data => {
        reset(data);
        toast.success("Dati DB salvati!");
    };

    const disabled = userData ? true : false;

    const initialize = async () => {
        const list = await getAllFileUploaded(
            `${userType}s/${userData.id}/kwc/tier_two`,
        );

        if (list[0] && list[0].includes("kyc_bill_proof")) {
            const filePath = await getFilePath(list[0]);
            setFile(filePath);
        }
    };

    useEffect(() => {
        initialize();
    }, []);

    return (
        <>
            {showDocument ? (
                <ModalDocument
                    open={showDocument}
                    handleClose={() => setShowDocument(false)}
                    filePath={file}
                />
            ) : (
                <form
                    onSubmit={handleSubmit(onSubmit)}
                    className={classes.flexContainer}
                >
                    <div className={classes.inputsContainer}>
                        <div className={classes.inputBlock}>
                            <TextField
                                fullWidth
                                label="Volumi annuali"
                                id="fullWidth"
                                {...register("annualVolumes", {
                                    required: true,
                                })}
                                disabled={disabled}
                            />

                            <Typography
                                className={classes.error}
                                variant="h6"
                                gutterBottom
                                component="div"
                            >
                                {errors.annualVolumes && (
                                    <span>This field is required</span>
                                )}
                            </Typography>
                        </div>

                        <div className={classes.inputBlock}>
                            <TextField
                                fullWidth
                                label="Prevenienza fondi"
                                id="fullWidth"
                                {...register("fundsProvenance", {
                                    required: true,
                                })}
                                disabled={disabled}
                            />

                            <Typography
                                className={classes.error}
                                variant="h6"
                                gutterBottom
                                component="div"
                            >
                                {errors.fundsProvenance && (
                                    <span>This field is required</span>
                                )}
                            </Typography>
                        </div>

                        <div className={classes.inputFullBlock}>
                            <Controller
                                control={control}
                                name={`economicallyExposedPerson`}
                                render={({ field }) => (
                                    <FormControlLabel
                                        label="Persona politicamente esposta"
                                        control={
                                            <Checkbox
                                                checked={field.value}
                                                disabled={disabled}
                                            />
                                        }
                                    />
                                )}
                            />

                            <Typography
                                className={classes.error}
                                variant="h6"
                                gutterBottom
                                component="div"
                            >
                                {errors.economicallyExposedPerson && (
                                    <span>This field is required</span>
                                )}
                            </Typography>
                        </div>
                        {file && (
                            <div className={classes.inputFullBlock}>
                                <Button
                                    onClick={() =>
                                        setShowDocument(prevState => !prevState)
                                    }
                                    className={classes.documentButton}
                                >
                                    Visualizza foto fattura/bolletta nel luogo
                                    dell’attività*
                                </Button>
                            </div>
                        )}
                    </div>
                </form>
            )}
        </>
    );
};

export default RegulationsForm;
