import { useState } from "react";
import { ethers } from "ethers";
import { provider, personalDataContract } from "../../utils/utils";

import { updateDoc, doc, deleteField } from "firebase/firestore";
import { firestore } from "../../firebase";

import { User } from "../../store/user/types";
import { useSelector } from "react-redux";

import { toast } from "react-toastify";
import { useSendEmailNotificationApiMutation } from "../../services/firestoreRestApi";

const useManageUser = () => {
    const [loader, setLoader] = useState<boolean>(false);
    const [sendEmailNotification] = useSendEmailNotificationApiMutation();

    const {
        wallet: { privateKey },
    } = useSelector(({ user }: { user: User }) => user);

    if (!privateKey) {
        toast.error(
            "La chiave privata non é valida, le operazioni sulla blockchain sono bloccate",
            {
                toastId: "privateKeyError",
            },
        );
        return {
            manageUserStatus: () => null,
            activateUserOnBlockchain: () => null,
        };
    }

    const adminWallet = new ethers.Wallet(privateKey, provider);
    const personalDataCntSig = personalDataContract.connect(adminWallet);

    const manageUserStatus = async (
        userId: string,
        collectionName: string,
        address: string,
        action: "lock" | "unlock",
        motivation?: string,
    ) => {
        if (loader) {
            toast.warning("Attendere l'operazione in corso");
            return;
        }

        setLoader(true);
        const loadingToast = toast.loading("Operazione in corso");

        const docRef = doc(firestore, collectionName, userId);

        const data = {
            locked: action === "lock" ? motivation : deleteField(),
        };

        try {
            const txn =
                action === "lock"
                    ? await personalDataCntSig.suspendUser(address)
                    : await personalDataCntSig.enableSuspendedUser(address);

            await txn.wait();

            await updateDoc(docRef, data);

            toast.success(
                `L'utente é stato ${
                    action === "lock" ? "sospeso" : "sbloccato"
                }`,
            );
        } catch {
            toast.error(
                "Errore durante la sospensione dell'utente! Riprova più tardi.",
            );
        }

        toast.dismiss(loadingToast);
        setLoader(false);
    };

    const promoteTier = async (
        userId: string,
        collectionName: string,
        tierToPromote: number,
        lang: string,
    ) => {
        if (loader) {
            toast.warning("Attendere l'operazione in corso");
            return;
        }

        setLoader(true);
        const loadingToast = toast.loading("Operazione in corso");

        const docRef = doc(firestore, collectionName, userId);

        const data = {
            tier: tierToPromote,
            pendingVerificationRequest: false,
        };

        try {
            await updateDoc(docRef, data);
            await sendEmailNotification({
                notificationType: `tierPromoted_${lang}`,
                data: { tier: tierToPromote },
                uid: userId,
            });

            toast.success("Tier promosso con successo!");
        } catch {
            toast.error("Promozione tier fallita");
        }

        toast.dismiss(loadingToast);
        setLoader(false);
    };

    const rejectTier = async (
        userId: string,
        collectionName: string,
        tierToReject: number,
        userTier: number,
        email: string,
        lang: string,
    ) => {
        if (loader) {
            toast.warning("Attendere l'operazione in corso");
            return;
        }

        setLoader(true);
        const loadingToast = toast.loading("Operazione in corso");

        const docRef = doc(firestore, collectionName, userId);

        try {
            if (tierToReject === 1) {
                await updateDoc(docRef, {
                    tierOne: deleteField(),
                    pendingVerificationRequest: false,
                });
            } else {
                if (userTier === 0) {
                    await updateDoc(docRef, {
                        tierTwo: deleteField(),
                        pendingVerificationRequest: true,
                    });
                } else {
                    await updateDoc(docRef, {
                        tierTwo: deleteField(),
                        pendingVerificationRequest: false,
                    });
                }
            }

            await sendEmailNotification({
                notificationType: `tierRejected_${lang}`,
                data: { tier: tierToReject },
                uid: userId,
            });
            toast.success("Tier utente cancellato con successo");
        } catch {
            toast.error("Eliminazione tier fallita");
        }

        toast.dismiss(loadingToast);
        setLoader(false);
    };

    return { manageUserStatus, promoteTier, rejectTier };
};

export default useManageUser;
