import { encrypt, decrypt } from "crypto-js/aes";
import { enc } from "crypto-js";

import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { persistReducer, persistStore, createTransform } from "redux-persist";
import reducers from "./root-reducer";
import storage from "redux-persist/lib/storage";
import usersMiddleware from "./users/usersMiddleware";
import transfersMiddleware from "./transfers/transfersMiddleware";
import requestsMiddleware from "./requests/requests.middleware";
import paymentsMiddleware from "./users/payments.middleware";
import settingsMiddleware from "./settings/settings.middleware";

import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { firestoreRestApi } from "../services/firestoreRestApi";

import { User } from "./user/types";

// Get Encrypt key from env variables
const currentEnv = process.env.REACT_APP_ENV;
const cacheEncryptKey =
    process.env[`REACT_APP_CACHE_ENCRYPT_KEY_${currentEnv}`];

const EncryptWallet = createTransform(
    // Transform state on its way to being serialized and persisted.
    (inboundState: User) => {
        // Encrypt wallet.
        const encryptedWallet = encrypt(
            JSON.stringify(inboundState.wallet),
            cacheEncryptKey || "",
        ).toString();

        return { ...inboundState, wallet: encryptedWallet };
    },
    // Transform state being rehydrated
    outboundState => {
        // Decrypt wallet
        const bytes = decrypt(outboundState.wallet, cacheEncryptKey || "");
        const decryptedWallet = JSON.parse(bytes.toString(enc.Utf8));

        return { ...outboundState, wallet: decryptedWallet };
    },
    // Define which reducers this transform gets called for.
    { whitelist: ["user"] },
);

const persistConfig = {
    key: "root",
    storage,
    blacklist: [
        "users",
        "requests",
        "transfers",
        "settings",
        firestoreRestApi.reducerPath,
    ],
    transforms: [EncryptWallet],
};

const rootReducer = combineReducers({
    ...reducers,
    [firestoreRestApi.reducerPath]: firestoreRestApi.reducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
    reducer: persistedReducer,
    devTools: process.env.NODE_ENV !== "production",
    middleware: getDefaultMiddleware =>
        getDefaultMiddleware({ serializableCheck: false })
            .prepend([
                usersMiddleware.middleware,
                transfersMiddleware.middleware,
                requestsMiddleware.middleware,
                paymentsMiddleware.middleware,
                settingsMiddleware.middleware,
            ])
            .concat(firestoreRestApi.middleware),
});

export type RootState = ReturnType<typeof store.getState>;
type State = typeof store.dispatch;
export type AppDispatch = ThunkDispatch<State, any, AnyAction>;

export default store;
export const persistor = persistStore(store);
