import { createStyles, makeStyles } from "@mui/styles";

const useStyles = makeStyles(() =>
    createStyles({
        modalContainer: {
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "white",
            borderRadius: 20,
            p: 4,
        },
        flexModalHeader: {
            display: "flex",
            justifyContent: "end",
        },
        flexModalContainer: {
            padding: 20,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
        },
    }),
);

export default useStyles;
