import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { User } from "../../store/user/types";

type Props = {
    requireAuth: boolean;
    children: JSX.Element;
};

const CustomRoute: React.FC<Props> = ({ children, requireAuth }) => {
    const { jwtToken } = useSelector(({ user }: { user: User }) => user);

    if (!jwtToken && requireAuth) {
        return <Navigate to="/" />;
    } else if (jwtToken && !requireAuth) {
        return <Navigate to="/users" />;
    }

    return children;
};

export default CustomRoute;
