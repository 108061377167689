import { Theme } from "@mui/material/styles";
import { createStyles, makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) => {
    return createStyles({
        topSpace: {
            height: 70,
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            paddingRight: "2rem",
        },
        appBar: {
            alignItems: "center",
            paddingTop: theme.spacing(2),
            paddingBottom: theme.spacing(2),
            justifyContent: "space-between",
            flexDirection: "row",
            paddingLeft: "2rem",
            paddingRight: "2rem",
            // Override media queries injected by theme.mixins.toolbar
            "@media all": {
                minHeight: 100,
            },
        },
        button: {
            color: theme.palette.primary.light,
        },
    });
});

export default useStyles;
