import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import * as React from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import useStyles from "./style";

type FormData = {
    motivation: string;
};

type dialogProps = {
    visibility: string;
    inputVisibility: boolean;
    title: string;
    onSubmitFunc: (data: FormData) => void;
    onCloseFunc: () => void;
};

const FormDialog: React.FC<dialogProps> = ({
    title,
    visibility,
    onSubmitFunc,
    onCloseFunc,
    inputVisibility,
}) => {
    const classes = useStyles();

    const { register, handleSubmit } = useForm<FormData>();

    const onSubmit: SubmitHandler<FormData> = data => {
        onSubmitFunc(data);
    };

    return (
        <Dialog open={visibility ? true : false} className={classes.container}>
            <form onSubmit={handleSubmit(onSubmit)} className={classes.form}>
                <DialogTitle>{title}</DialogTitle>
                <DialogContent>
                    {inputVisibility && (
                        <TextField
                            autoFocus
                            margin="dense"
                            id="motivation"
                            label="Motivazione"
                            fullWidth
                            variant="standard"
                            {...register("motivation")}
                        />
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={onCloseFunc}>Annulla</Button>
                    <Button type="submit">Conferma</Button>
                </DialogActions>
            </form>
        </Dialog>
    );
};

export default FormDialog;
