import { ethers } from "ethers";
import { collection, getDocs, query, DocumentData } from "firebase/firestore";
import { UserInfo, Payment } from "./types";

import { firestore } from "../../firebase";

import { mitTokenContract } from "../../utils/utils";
import { personalDataContract } from "../../utils/utils";

export const formatUser = async (user: DocumentData) => {
    const status: { address: string; activityStatus: string } =
        await personalDataContract.registry(user.walletAddress);
    const mitBalance = await getWalletBalance(user.walletAddress);
    const payments: Payment[] = [];

    return {
        status: status.activityStatus === "ACTIVE" ? 1 : 2,
        mitBalance,
        payments,
        ...user,
    } as UserInfo;
};

// Functions that fetch firestore data
export const getUsers = async () => {
    const consumersRef = collection(firestore, "consumers");
    const merchantsRef = collection(firestore, "merchants");

    const consumersQuery = query(consumersRef);
    const merchantsQuery = query(merchantsRef);

    const consumersSnapshot = await getDocs(consumersQuery);
    const merchantsSnapshot = await getDocs(merchantsQuery);

    const consumersData = consumersSnapshot.docs.map(doc => {
        return { id: doc.id, category: "consumer", ...doc.data() };
    });
    const merchantsData = merchantsSnapshot.docs.map(doc => {
        return { id: doc.id, category: "merchant", ...doc.data() };
    });

    const formattedUsers = Promise.all(
        [...consumersData, ...merchantsData].map(async (user: DocumentData) => {
            return await formatUser(user);
        }),
    );

    return formattedUsers;
};

export const formatPayment = (payment: DocumentData) => {
    let formattedPayment;
    if (payment.type === "Paypal") {
        formattedPayment = {
            id: payment.id,
            transactionId: payment.content.id,
            paymentMethod: payment.type,
            mitAmount: payment.content.resource.amount.value * 10,
            euroAmount: payment.content.resource.amount.value,
            date: new Date(payment.content.create_time)
                .toISOString()
                .substring(0, 10),
        } as Payment;
    } else {
        formattedPayment = {
            id: payment.id,
            transactionId: payment.content.id,
            paymentMethod: payment.type,
            mitAmount: payment.content.event.data.pricing.local.amount * 10,
            euroAmount: payment.content.event.data.pricing.local.amount,
            date: new Date(payment.content.event.created_at)
                .toISOString()
                .substring(0, 10),
        } as Payment;
    }
    return formattedPayment;
};

export const getPayments = async (collectionName: string, userId: string) => {
    const paymentsRef = collection(
        firestore,
        `${collectionName}/${userId}/payments`,
    );

    const paymentsQuery = query(paymentsRef);

    const paymentsSnapshot = await getDocs(paymentsQuery);

    const paymentsData = paymentsSnapshot.docs.map(doc => {
        return { id: doc.id, ...doc.data() };
    });
    const formattedPayments = paymentsData.map(payment => {
        return formatPayment(payment);
    });

    return formattedPayments;
};

//Functions that fetch blockchain data
export const getWalletBalance = async (address: string) => {
    const balance = await mitTokenContract.balanceOf(address);

    return Number(ethers.utils.formatEther(balance));
};
