import { Theme } from "@mui/material/styles";
import { tableCellClasses } from "@mui/material/TableCell";
import { createStyles, makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) => {
    return createStyles({
        tableCell: {
            [`&.${tableCellClasses.head}`]: {
                backgroundColor: theme.palette.primary.main,
                color: theme.palette.common.white,
                fontWeight: "bold",
            },
            [`&.${tableCellClasses.body}`]: {
                fontSize: 14,
            },
        },
        tableRow: {
            backgroundColor: theme.palette.primary.light,
            // "&:nth-of-type(odd)": {
            //     backgroundColor: theme.palette.action.hover,
            // },
            "&.pending": {
                backgroundColor: theme.palette.error.light,
            },
            "&:hover": {
                backgroundColor: theme.palette.action.hover,
            },
            // hide last border
            "&:last-child td, &:last-child th": {
                border: 0,
            },
        },
        info: {
            marginLeft: "10px",
            textDecoration: "underline",
            cursor: "pointer",
        },
        actionsContainer: {
            display: "flex",
            alignItems: "center",
        },
        action: {
            display: "flex",
            alignItems: "center",
            marginRight: "1rem",
        },
        voidAction: {
            width: "133px",
        },
        active: {
            background: "green",
            color: "#ffffff",
            borderRadius: "20px",
            textAlign: "center",
            fontWeight: "bold",
            paddingRight: "20px",
            paddingLeft: "20px",
        },
        locked: {
            background: "red",
            color: "#ffffff",
            borderRadius: "20px",
            textAlign: "center",
            fontWeight: "bold",
            paddingRight: "20px",
            paddingLeft: "20px",
        },
        tableLoader: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
        },
    });
});

export default useStyles;
