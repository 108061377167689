import React from "react";
import { toast } from "react-toastify";
import { useCopyToClipboard } from "react-use";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { Stack } from "@mui/system";

type Props = {
    valueToCopy: string;
};

const ShowCopy: React.FC<Props> = ({ valueToCopy }) => {
    const [{ error }, copyToClipboard] = useCopyToClipboard();

    const slicedValue =
        valueToCopy.slice(0, 4).concat("....") + valueToCopy.slice(38);

    const handleClick = (event: any) => {
        event.stopPropagation();
        copyToClipboard(valueToCopy);
        if (!error) {
            toast.success("Testo copiato con successo");
        }
    };

    return (
        <Stack
            direction="row"
            justifyContent="end"
            alignItems="center"
            spacing={2}
        >
            <span>{slicedValue}</span>

            <div
                role="button"
                tabIndex={0}
                onClick={handleClick}
                onKeyDown={handleClick}
            >
                <ContentCopyIcon style={{ cursor: "pointer" }} />
            </div>
        </Stack>
    );
};

export default ShowCopy;
