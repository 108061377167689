import { collection, onSnapshot, QuerySnapshot } from "@firebase/firestore";
import { createListenerMiddleware, Unsubscribe } from "@reduxjs/toolkit";
import { firestore } from "../../firebase";

import { formatPayment } from "./fetchUtils";

import { Payment } from "./types";
import {
    fetchPayments,
    addPayment,
    updatePaymentData,
    resetPayments,
} from "./users.slice";

const paymentsMiddleware = createListenerMiddleware();

let unsubPayments: Unsubscribe;

paymentsMiddleware.startListening({
    actionCreator: fetchPayments.fulfilled,
    effect: async (action, listenerApi) => {
        // Firestore listeners
        unsubPayments = onSnapshot(
            collection(
                firestore,
                `${action.payload.collectionName}/${action.payload.userId}/payments`,
            ),
            (snapshot: QuerySnapshot) => {
                snapshot.docChanges().forEach(async change => {
                    if (change.type === "added") {
                        // Ignore first cycle of added documents
                        if (
                            !action.payload.payments.find(
                                payment => payment.id === change.doc.id,
                            )
                        ) {
                            const newDocData = change.doc.data();
                            const newDoc = {
                                id: change.doc.id,
                                ...newDocData,
                            } as Payment;

                            const formattedDoc = formatPayment(newDoc);

                            listenerApi.dispatch(
                                addPayment(action.payload.userId, formattedDoc),
                            );
                        }
                    }
                    if (change.type === "modified") {
                        const editedDocumentData = change.doc.data();
                        const editedDoc = {
                            id: change.doc.id,
                            ...editedDocumentData,
                        };

                        const formattedDoc = formatPayment(editedDoc);

                        listenerApi.dispatch(
                            updatePaymentData(
                                action.payload.userId,
                                formattedDoc,
                            ),
                        );
                    }
                });
            },
        );
    },
});

paymentsMiddleware.startListening({
    actionCreator: resetPayments,
    effect: async () => {
        unsubPayments();
    },
});

export default paymentsMiddleware;
