import React, { useRef } from "react";
import { Button, Stack, TextareaAutosize, Typography } from "@mui/material";
import { SubmitHandler, useForm } from "react-hook-form";
import { toast } from "react-toastify";

import DocumentItem from "./DocumentItem";
import useStyles from "./style";
import { useStorage } from "../../../hooks";
import { doc, setDoc } from "firebase/firestore";
import { firestore } from "../../../firebase";

type Inputs = {
    note: string;
};

type Props = {
    userType: string;
    userId: string;
    initialData: string;
};

const NoteDocuments: React.FC<Props> = ({ userType, userId, initialData }) => {
    const classes = useStyles();
    const { upload, deleteFile, files } = useStorage(
        `${userType}s/${userId}/info`,
    );
    let id = 0;

    const fileRef = useRef<HTMLInputElement | null>(null);

    const handleUploadFile = (event: any) => {
        const fileToUpload = event.target.files[0];
        try {
            upload([fileToUpload]);
        } catch (e) {
            console.log(e);
        }
    };

    const handleClickUpload = () => {
        if (fileRef.current != null) {
            fileRef.current.click();
        }
    };

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<Inputs>({
        defaultValues: {
            note: initialData,
        },
    });

    const onSubmit: SubmitHandler<Inputs> = async data => {
        try {
            await setDoc(doc(firestore, "adminNotes", userId), {
                notes: data,
            });
            toast.success("Note salvate!", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        } catch (e) {
            toast.error("Errore durante il salvataggio!", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    };

    return (
        <>
            <form
                onSubmit={handleSubmit(onSubmit)}
                className={classes.flexContainer}
            >
                <div className={classes.inputFullBlock}>
                    <TextareaAutosize
                        minRows={6}
                        placeholder="Note"
                        style={{ width: "100%" }}
                        {...register("note", {
                            required: true,
                        })}
                    />
                    <Typography
                        className={classes.error}
                        variant="h6"
                        gutterBottom
                        component="div"
                    >
                        {errors.note && <span>This field is required</span>}
                    </Typography>
                </div>

                <div className={classes.flexContainer}>
                    {files.map((storagePath: string) => {
                        const splitted = storagePath.split("/");
                        id++;
                        return (
                            <DocumentItem
                                key={id}
                                name={splitted[3]}
                                path={storagePath}
                                handleDelete={deleteFile}
                            />
                        );
                    })}
                </div>

                <div className={classes.inputFullBlock}>
                    <Stack className={classes.inputsContainer} spacing={2}>
                        <Button
                            className={classes.formButton}
                            type="submit"
                            variant="contained"
                        >
                            Salva Modifiche
                        </Button>
                        <Button
                            className={classes.uploadButton}
                            variant="outlined"
                            onClick={handleClickUpload}
                        >
                            <input
                                type="file"
                                name="upload"
                                ref={fileRef}
                                onChange={handleUploadFile}
                                hidden
                            />
                            Carica documento
                        </Button>
                    </Stack>
                </div>
            </form>
        </>
    );
};

export default NoteDocuments;
