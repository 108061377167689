import React, { useEffect, useState } from "react";
import { Button, IconButton, Modal, TextField } from "@mui/material";
import { Box } from "@mui/system";
import ClearIcon from "@mui/icons-material/Clear";
import { ethers } from "ethers";

import { provider, mitTokenContract } from "../../utils/utils";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { User } from "../../store/user/types";
import useStyles from "./style";

type Props = {
    open: boolean;
    handleClose: () => void;
};

const ModalEditLocking: React.FC<Props> = ({ open, handleClose }) => {
    const classes = useStyles();

    const {
        wallet: { privateKey },
    } = useSelector(({ user }: { user: User }) => user);

    const [lockingTime, setLockingTime] = useState(0);
    const adminWallet = new ethers.Wallet(privateKey, provider);
    const mitTokenCntSig = mitTokenContract.connect(adminWallet);

    const getTime = async () => {
        const lockingPeriod = await mitTokenContract.getLockingPeriod();
        const valueFormatted = lockingPeriod.toNumber();
        const valueParsed = parseInt(valueFormatted, 10);
        setLockingTime(valueParsed / 86400);
    };

    useEffect(() => {
        getTime();
    }, []);

    const handleClick = async () => {
        if (lockingTime % 1 === 0 && lockingTime > 0) {
            await mitTokenCntSig
                .setLockingPeriod(lockingTime * 86400)
                .then(() => {
                    toast.success("Dato aggiornato con successo");
                    handleClose();
                });
        } else {
            toast.error("I numeri decimali o inferiori a 1 non sono ammessi");
        }
    };

    return (
        <Modal open={open} onClose={handleClose}>
            <Box className={classes.modalContainer}>
                <div className={classes.flexModalHeader}>
                    <IconButton onClick={handleClose}>
                        <ClearIcon />
                    </IconButton>
                </div>
                <div className={classes.flexModalContainer}>
                    <h2>Modifica periodo di locking</h2>
                    <TextField
                        fullWidth
                        label="Periodo di locking (Inserire numero di giorni)"
                        id="fullWidth"
                        type="number"
                        value={lockingTime}
                        onChange={(e: any) => setLockingTime(e.target.value)}
                    />
                    <Button
                        sx={{
                            color: "#ffff",
                            marginTop: "35px",
                            width: "100%",
                        }}
                        variant="contained"
                        type="button"
                        onClick={handleClick}
                    >
                        Aggiorna
                    </Button>
                </div>
            </Box>
        </Modal>
    );
};

export default ModalEditLocking;
