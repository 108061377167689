import React from "react";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TextField, Typography } from "@mui/material";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import useStyles from "./style";

type PersonalData = {
    name: string;
    surname: string;
    email: string;
    birthDate: Date | string;
    birthPlace: string;
    fiscalCode: string;
    phone: string;
    address: string;
    streetNumber: string;
    city: string;
    cap: string;
    district: string;
    state: string;
    citizenship: string;
    job: string;
    economicActivity: string;
};

type Props = {
    userData?: any;
};

const PersonalDataForm: React.FC<Props> = ({ userData }) => {
    const classes = useStyles();

    let userEconomicActivity = "";
    if ("economicActivity" in userData!) {
        userEconomicActivity = userData.economicActivity;
    } else if ("business" in userData!) {
        userEconomicActivity = userData.business.economicActivitySector;
    }

    let birthDate = new Date();
    if ("birthDate" in userData!) {
        birthDate = new Date(userData.birthDate.seconds * 1000);
    }

    const {
        register,
        handleSubmit,
        formState: { errors },
        control,
    } = useForm<PersonalData>({
        defaultValues: {
            name: userData?.firstName || "",
            surname: userData?.lastName || "",
            birthDate: birthDate,
            birthPlace: userData?.birthplace || "",
            fiscalCode: userData?.fiscalCode || "",
            email: userData?.email || "",
            phone: userData?.phone || "",
            address: userData?.streetAddress || "",
            streetNumber: userData?.streetNumber || "",
            city: userData?.city || "",
            cap: userData?.cap || "",
            district: userData?.district || "",
            state: userData?.country || "",
            citizenship: userData?.citizenship || "",
            job: userData?.job || "",
            economicActivity: userEconomicActivity,
        },
    });

    const onSubmit: SubmitHandler<PersonalData> = () => {
        toast.success("Dati DB salvati!");
    };

    const disabled = userData ? true : false;

    return (
        <form
            onSubmit={handleSubmit(onSubmit)}
            className={classes.flexContainer}
        >
            <div className={classes.inputsContainer}>
                <div className={classes.inputBlock}>
                    <TextField
                        fullWidth
                        label="Nome"
                        id="fullWidth"
                        {...register("name", {
                            required: true,
                        })}
                        disabled={disabled}
                    />

                    <Typography
                        className={classes.error}
                        variant="h6"
                        gutterBottom
                        component="div"
                    >
                        {errors.name && <span>This field is required</span>}
                    </Typography>
                </div>

                <div className={classes.inputBlock}>
                    <TextField
                        fullWidth
                        label="Cognome"
                        id="fullWidth"
                        {...register("surname", {
                            required: true,
                        })}
                        disabled={disabled}
                    />

                    <Typography
                        className={classes.error}
                        variant="h6"
                        gutterBottom
                        component="div"
                    >
                        {errors.surname && <span>This field is required</span>}
                    </Typography>
                </div>

                <div className={classes.inputBlock}>
                    <Controller
                        control={control}
                        name={`birthDate`}
                        render={({ field }) => (
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    inputFormat="DD/MM/YYYY"
                                    label="Data di nascita"
                                    value={field.value}
                                    disabled={disabled}
                                    onChange={(newValue: Date | null) => {
                                        field.onChange(newValue);
                                    }}
                                    InputProps={{
                                        disabled: true,
                                    }}
                                    className={classes.birthDate}
                                    renderInput={(params: any) => (
                                        <TextField
                                            onKeyDown={e => {
                                                e.preventDefault();
                                            }}
                                            {...params}
                                        />
                                    )}
                                />
                            </LocalizationProvider>
                        )}
                    />

                    <Typography
                        className={classes.error}
                        variant="h6"
                        gutterBottom
                        component="div"
                    >
                        {errors.birthDate && (
                            <span>This field is required</span>
                        )}
                    </Typography>
                </div>

                <div className={classes.inputBlock}>
                    <TextField
                        fullWidth
                        label="Luogo di nascita"
                        id="fullWidth"
                        {...register("birthPlace", {
                            required: true,
                        })}
                        disabled={disabled}
                    />

                    <Typography
                        className={classes.error}
                        variant="h6"
                        gutterBottom
                        component="div"
                    >
                        {errors.birthPlace && (
                            <span>This field is required</span>
                        )}
                    </Typography>
                </div>

                <div className={classes.inputBlock}>
                    <TextField
                        fullWidth
                        label="Codice fiscale"
                        id="fullWidth"
                        {...register("fiscalCode", {
                            required: true,
                        })}
                        disabled={disabled}
                    />

                    <Typography
                        className={classes.error}
                        variant="h6"
                        gutterBottom
                        component="div"
                    >
                        {errors.fiscalCode && (
                            <span>This field is required</span>
                        )}
                    </Typography>
                </div>

                <div className={classes.inputBlock}>
                    <TextField
                        fullWidth
                        label="Indirizzo email"
                        id="fullWidth"
                        {...register("email", {
                            required: true,
                        })}
                        disabled={disabled}
                    />

                    <Typography
                        className={classes.error}
                        variant="h6"
                        gutterBottom
                        component="div"
                    >
                        {errors.email && <span>This field is required</span>}
                    </Typography>
                </div>

                <div className={classes.inputBlock}>
                    <TextField
                        fullWidth
                        label="Numero di telefono"
                        id="fullWidth"
                        {...register("phone", {
                            required: true,
                        })}
                        disabled={disabled}
                    />

                    <Typography
                        className={classes.error}
                        variant="h6"
                        gutterBottom
                        component="div"
                    >
                        {errors.phone && <span>This field is required</span>}
                    </Typography>
                </div>

                <div className={classes.inputBlock}>
                    <TextField
                        fullWidth
                        label="Indirizzo di residenza"
                        id="fullWidth"
                        {...register("address", {
                            required: true,
                        })}
                        disabled={disabled}
                    />

                    <Typography
                        className={classes.error}
                        variant="h6"
                        gutterBottom
                        component="div"
                    >
                        {errors.address && <span>This field is required</span>}
                    </Typography>
                </div>

                <div className={classes.inputBlock}>
                    <TextField
                        fullWidth
                        label="Civico"
                        id="fullWidth"
                        {...register("streetNumber", {
                            required: true,
                        })}
                        disabled={disabled}
                    />

                    <Typography
                        className={classes.error}
                        variant="h6"
                        gutterBottom
                        component="div"
                    >
                        {errors.streetNumber && (
                            <span>This field is required</span>
                        )}
                    </Typography>
                </div>

                <div className={classes.inputBlock}>
                    <TextField
                        fullWidth
                        label="Città"
                        id="fullWidth"
                        {...register("city", {
                            required: true,
                        })}
                        disabled={disabled}
                    />

                    <Typography
                        className={classes.error}
                        variant="h6"
                        gutterBottom
                        component="div"
                    >
                        {errors.city && <span>This field is required</span>}
                    </Typography>
                </div>

                <div className={classes.inputBlock}>
                    <TextField
                        fullWidth
                        label="CAP"
                        id="fullWidth"
                        {...register("cap", {
                            required: true,
                        })}
                        disabled={disabled}
                    />

                    <Typography
                        className={classes.error}
                        variant="h6"
                        gutterBottom
                        component="div"
                    >
                        {errors.cap && <span>This field is required</span>}
                    </Typography>
                </div>

                <div className={classes.inputBlock}>
                    <TextField
                        fullWidth
                        label="Provincia"
                        id="fullWidth"
                        {...register("district", {
                            required: true,
                        })}
                        disabled={disabled}
                    />

                    <Typography
                        className={classes.error}
                        variant="h6"
                        gutterBottom
                        component="div"
                    >
                        {errors.district && <span>This field is required</span>}
                    </Typography>
                </div>

                <div className={classes.inputBlock}>
                    <TextField
                        fullWidth
                        label="Stato"
                        id="fullWidth"
                        {...register("state", {
                            required: true,
                        })}
                        disabled={disabled}
                    />

                    <Typography
                        className={classes.error}
                        variant="h6"
                        gutterBottom
                        component="div"
                    >
                        {errors.state && <span>This field is required</span>}
                    </Typography>
                </div>

                <div className={classes.inputBlock}>
                    <TextField
                        fullWidth
                        label="Cittadinanza"
                        id="fullWidth"
                        {...register("citizenship", {
                            required: true,
                        })}
                        disabled={disabled}
                    />

                    <Typography
                        className={classes.error}
                        variant="h6"
                        gutterBottom
                        component="div"
                    >
                        {errors.citizenship && (
                            <span>This field is required</span>
                        )}
                    </Typography>
                </div>

                <div className={classes.inputBlock}>
                    <TextField
                        fullWidth
                        label="Professione"
                        id="fullWidth"
                        {...register("job", {
                            required: true,
                        })}
                        disabled={disabled}
                    />

                    <Typography
                        className={classes.error}
                        variant="h6"
                        gutterBottom
                        component="div"
                    >
                        {errors.job && <span>This field is required</span>}
                    </Typography>
                </div>

                <div className={classes.inputBlock}>
                    <TextField
                        fullWidth
                        label="Attività economica"
                        id="fullWidth"
                        {...register("economicActivity", {
                            required: true,
                        })}
                        disabled={disabled}
                    />

                    <Typography
                        className={classes.error}
                        variant="h6"
                        gutterBottom
                        component="div"
                    >
                        {errors.economicActivity && (
                            <span>This field is required</span>
                        )}
                    </Typography>
                </div>
            </div>
        </form>
    );
};

export default PersonalDataForm;
