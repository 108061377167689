import { collection, onSnapshot, QuerySnapshot } from "@firebase/firestore";
import { createListenerMiddleware, Unsubscribe } from "@reduxjs/toolkit";
import { firestore } from "../../firebase";
import { RequestData } from "./types";

import { setJwtToken, logout } from "../user/user.slice";
import {
    reset,
    updateRequestData,
    addRequest,
    fetchRequests,
} from "./requests.slice";

const requestsMiddleware = createListenerMiddleware();

let unsubRequests: Unsubscribe;

requestsMiddleware.startListening({
    actionCreator: setJwtToken,
    effect: async (_, listenerApi) => {
        listenerApi.dispatch(fetchRequests());
    },
});

requestsMiddleware.startListening({
    actionCreator: fetchRequests.fulfilled,
    effect: async (action, listenerApi) => {
        // Firestore listeners
        unsubRequests = onSnapshot(
            collection(firestore, "adminOperationsRequests"),
            (snapshot: QuerySnapshot) => {
                snapshot.docChanges().forEach(change => {
                    if (change.type === "added") {
                        // Ignore first cycle of added documents
                        if (
                            !action.payload.find(
                                request => request.id === change.doc.id,
                            )
                        ) {
                            const newDocData = change.doc.data();
                            const newDoc = {
                                id: change.doc.id,
                                ...newDocData,
                            } as RequestData;
                            listenerApi.dispatch(addRequest(newDoc));
                        }
                    }
                    if (change.type === "modified") {
                        const newDocData = change.doc.data();
                        const newDoc = {
                            id: change.doc.id,
                            ...newDocData,
                        } as RequestData;
                        listenerApi.dispatch(updateRequestData(newDoc));
                    }
                });
            },
        );
    },
});

requestsMiddleware.startListening({
    actionCreator: logout,
    effect: async (_, listenerApi) => {
        listenerApi.dispatch(reset());
        unsubRequests();
    },
});

export default requestsMiddleware;
