import { createStyles, makeStyles } from "@mui/styles";

const useStyles = makeStyles(() =>
    createStyles({
        flexContainer: {
            width: "100%",
            height: "100%",
            display: "flex",
            flexWrap: "wrap",
            flexDirection: "row",
            marginTop: "10px",
        },
        inputsContainer: {
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexWrap: "wrap",
        },
        inputBlock: {
            width: "calc(20% - 1.33rem)",
            marginBottom: "28px",
            marginLeft: "10px",
        },
        inputFullBlock: {
            width: "100%",
            marginBottom: "2rem",
        },
        error: {
            cursor: "pointer",
            color: "red",
            marginBottom: 0,
            marginTop: "10px",
            fontSize: "18px",
        },
        formButton: {
            color: "#fff",
            width: "190px",
        },
        uploadButton: {
            color: "#44c1be",
            width: "190px",
        },
        documentName: {
            textTransform: "capitalize",
        },
    }),
);

export default useStyles;
