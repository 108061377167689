import { createStyles, makeStyles } from "@mui/styles";

const useStyles = makeStyles(() =>
    createStyles({
        flexContainer: {
            width: "100%",
            height: "100%",
            display: "flex",
            flexWrap: "wrap",
            flexDirection: "column",
            marginTop: "10px",
        },
        form: {
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            flexWrap: "wrap",
        },
        inputsContainer: {
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            // justifyContent: "space-between",
            flexWrap: "wrap",
        },
        inputBlock: {
            width: "calc(33.3% - 1.33rem)",
            marginBottom: "28px",
            "&:nth-child(3n -1)": {
                marginLeft: "2rem",
                marginRight: "2rem",
            },
        },
        inputFullBlock: {
            width: "100%",
            marginBottom: "2rem",
        },
        error: {
            cursor: "pointer",
            color: "red",
            marginBottom: 0,
            marginTop: "10px",
            fontSize: "18px",
        },
        documentButton: {
            fontWeight: "bold",
            textTransform: "inherit",
            color: "#58595B",
        },
    }),
);

export default useStyles;
