import { PayloadAction } from "@reduxjs/toolkit";
import { Settings, Limits } from "./types";
import { settingsInitialState } from "./settings.slice";

export const settingsReducer = {
    setLoader: {
        reducer: (
            state: Settings,
            action: PayloadAction<{
                value: boolean;
            }>,
        ): void => {
            state.loader = action.payload.value;
        },
        prepare: (value: boolean) => ({
            payload: {
                value,
            },
        }),
    },
    updateLimitsData: {
        reducer: (
            state: Settings,
            action: PayloadAction<{
                limitsData: Limits;
            }>,
        ): void => {
            const { limitsData } = action.payload;

            state.limits = limitsData;
        },
        prepare: (limitsData: Limits) => ({
            payload: {
                limitsData,
            },
        }),
    },
    reset: () => settingsInitialState,
};
