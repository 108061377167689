import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
} from "@mui/material";
import React, { useState } from "react";
import RequestDetailModal from "../../components/MTRequestModalDetail";
import Box from "@mui/material/Box";

import { useSelector } from "react-redux";
import { User } from "../../store/user/types";

import { RequestData } from "../../store/requests/types";

import useStyles from "./style";
import ThumbButton from "../../components/MTThumbButton";

import { adminOperations } from "../../constants";
import { getRequests } from "../../store/requests/requests.slice";

import { useAdminOperations, useAdminInfo } from "../../hooks";
import ShowCopy from "../../components/ShowCopy";

const tableHead = [
    "Tipologia (?)",
    "Data",
    "Admin richiedente",
    "Voti positivi",
    "Dettagli",
    "Azione",
    "Esito",
];

const AdminPanel: React.FC = () => {
    const classes = useStyles();

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(25);
    const [showDetail, setShowDetail] = useState<string | undefined>("");

    const { confirmOperation, rejectOperation } = useAdminOperations();
    const { allowance } = useAdminInfo();

    const {
        wallet: { address },
    } = useSelector(({ user }: { user: User }) => user);

    const { list: data } = useSelector(getRequests);

    const tableRows =
        data?.map(dataDoc => {
            const id = dataDoc.id;
            const data = dataDoc as RequestData;

            const submitDate =
                dataDoc.submitDate instanceof Date
                    ? new Date()
                    : new Date(dataDoc.submitDate.seconds * 1000);

            const positiveVotes = data.signatures.filter(
                signature => signature.vote === "accepted",
            );

            return {
                id,
                typology: adminOperations[data.type],
                date: submitDate,
                requestingAdmin: data.requestingAdmin,
                voters: `${positiveVotes.length}/${data.threshold}`,
                status: data.status,
                myVoteStatus:
                    data.signatures.find(signature => {
                        return signature.signer === address;
                    })?.vote || "pending",
            };
        }) || [];

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement>,
    ) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };
    const handleReject = (id: string | undefined) => {
        const request = data.find(request => request.id === id);
        if (request && rejectOperation) {
            rejectOperation(request);
        }
    };

    const handleAccept = (id: string | undefined) => {
        const request = data.find(request => request.id === id);
        if (request && confirmOperation) {
            confirmOperation(request);
        }
    };

    return (
        <>
            {showDetail ? (
                <RequestDetailModal
                    request={data.find(request => request.id === showDetail)}
                    open={!!showDetail}
                    handleClose={() => setShowDetail("")}
                />
            ) : (
                <div className={classes.container}>
                    <div className={classes.boxContainer}>
                        <div className={classes.flexEndContainer}>
                            <Box className={classes.mitAmountContainer}>
                                <div className={classes.titleMitAmount}>
                                    Address wallet
                                </div>
                                <div className={classes.mitAmount}>
                                    <ShowCopy valueToCopy={address} />
                                </div>
                            </Box>
                        </div>
                        <div className={classes.flexEndContainer}>
                            <Box className={classes.mitAmountContainer}>
                                <div className={classes.titleMitAmount}>
                                    Restante
                                </div>
                                <div className={classes.mitAmount}>
                                    {`${allowance?.available || "-"} MIT`}
                                </div>
                            </Box>
                        </div>
                        <div className={classes.flexEndContainer}>
                            <Box className={classes.mitAmountContainer}>
                                <div className={classes.titleMitAmount}>
                                    Platfond
                                </div>
                                <div className={classes.mitAmount}>
                                    {`${allowance?.amount || "-"} MIT`}
                                </div>
                            </Box>
                        </div>
                        <div className={classes.flexEndContainer}>
                            <Box className={classes.mitAmountContainer}>
                                <div className={classes.titleMitAmount}>
                                    Tempo reset
                                </div>
                                <div className={classes.mitAmount}>
                                    {allowance
                                        ? `${allowance?.resetTime} min`
                                        : "-"}
                                </div>
                            </Box>
                        </div>
                    </div>
                    <Paper
                        sx={{
                            height: "calc(100% - 160px)",
                            width: "100%",
                            overflow: "hidden",
                        }}
                    >
                        <TableContainer
                            sx={{ height: "calc(100% - 50px)" }}
                            component={Paper}
                        >
                            <Table
                                sx={{ minWidth: 700 }}
                                stickyHeader
                                aria-label="sticky table"
                            >
                                <TableHead>
                                    <TableRow>
                                        {tableHead.map(columnTitle => (
                                            <TableCell
                                                className={classes.tableCell}
                                                key={columnTitle}
                                            >
                                                {columnTitle}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {tableRows &&
                                        tableRows
                                            .slice(
                                                page * rowsPerPage,
                                                page * rowsPerPage +
                                                    rowsPerPage,
                                            )
                                            .map(row => (
                                                <TableRow key={row.id}>
                                                    <TableCell
                                                        className={
                                                            classes.tableCell
                                                        }
                                                        align="left"
                                                    >
                                                        {row.typology}
                                                    </TableCell>
                                                    <TableCell
                                                        className={
                                                            classes.tableCell
                                                        }
                                                        align="left"
                                                    >
                                                        {row.date.toLocaleDateString()}
                                                    </TableCell>
                                                    <TableCell
                                                        className={
                                                            classes.tableCell
                                                        }
                                                        align="left"
                                                    >
                                                        {row.requestingAdmin
                                                            .slice(0, 10)
                                                            .concat("....") +
                                                            row.requestingAdmin.slice(
                                                                38,
                                                            )}
                                                    </TableCell>
                                                    <TableCell
                                                        className={
                                                            classes.tableCell
                                                        }
                                                        align="left"
                                                    >
                                                        {row.voters}
                                                    </TableCell>
                                                    <TableCell
                                                        className={
                                                            classes.tableCell
                                                        }
                                                        align="left"
                                                    >
                                                        <a
                                                            className={
                                                                classes.detailLink
                                                            }
                                                            onClick={() =>
                                                                setShowDetail(
                                                                    row.id,
                                                                )
                                                            }
                                                        >
                                                            Mostra dettagli
                                                        </a>
                                                    </TableCell>
                                                    <TableCell
                                                        className={
                                                            classes.tableCell
                                                        }
                                                        align="left"
                                                    >
                                                        {row.myVoteStatus ===
                                                            "pending" &&
                                                        row.status ===
                                                            "pending" ? (
                                                            <div>
                                                                <ThumbButton
                                                                    handleClick={() =>
                                                                        handleReject(
                                                                            row.id,
                                                                        )
                                                                    }
                                                                    thumbDirection="down"
                                                                    label="Rifiuta"
                                                                />
                                                                <ThumbButton
                                                                    handleClick={() =>
                                                                        handleAccept(
                                                                            row.id,
                                                                        )
                                                                    }
                                                                    thumbDirection="up"
                                                                    label="Accetta"
                                                                />
                                                            </div>
                                                        ) : (
                                                            <ThumbButton
                                                                isButton={false}
                                                                thumbDirection={
                                                                    (row.myVoteStatus as
                                                                        | "accepted"
                                                                        | "rejected") ===
                                                                    "accepted"
                                                                        ? "up"
                                                                        : "down"
                                                                }
                                                                label={
                                                                    (row.myVoteStatus as
                                                                        | "accepted"
                                                                        | "rejected") ===
                                                                    "accepted"
                                                                        ? "Accettato"
                                                                        : "Rifiutato"
                                                                }
                                                            />
                                                        )}
                                                    </TableCell>
                                                    <TableCell
                                                        className={
                                                            classes.tableCell
                                                        }
                                                        align="left"
                                                    >
                                                        <div>
                                                            {row.status !==
                                                            undefined ? (
                                                                <ThumbButton
                                                                    isButton={
                                                                        false
                                                                    }
                                                                    thumbDirection={
                                                                        row.status ===
                                                                        "accepted"
                                                                            ? "up"
                                                                            : row.status ===
                                                                              "rejected"
                                                                            ? "down"
                                                                            : null
                                                                    }
                                                                    label={
                                                                        row.status ===
                                                                        "accepted"
                                                                            ? "Accettato"
                                                                            : row.status ===
                                                                              "rejected"
                                                                            ? "Rifiutato"
                                                                            : "In attesa"
                                                                    }
                                                                />
                                                            ) : (
                                                                "-"
                                                            )}
                                                        </div>
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[25, 50, 100]}
                            component="div"
                            count={tableRows.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Paper>
                </div>
            )}
        </>
    );
};
export default AdminPanel;
