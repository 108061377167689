import React from "react";
import {
    Button,
    IconButton,
    Modal,
    TextField,
    Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import ClearIcon from "@mui/icons-material/Clear";

import useStyles from "./style";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useDiscounts } from "../../../hooks";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

type Props = {
    open: boolean;
    handleClose: () => void;
    merchantAddress: string;
};

type Inputs = {
    id: string;
    discountPercentage: number;
    vipLevel: string;
    description: string;
    expirationDate: Date;
};

const ModalCreateDiscount: React.FC<Props> = ({
    open,
    handleClose,
    merchantAddress,
}) => {
    const classes = useStyles();

    const { createDiscount } = useDiscounts(merchantAddress);

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
        control,
    } = useForm<Inputs>();

    const onSubmit: SubmitHandler<Inputs> = (data: any) => {
        const formattedExp = Number(data.expirationDate)
            ? data.expirationDate / 1000
            : new Date(data.expirationDate).getTime() / 1000;

        createDiscount({
            id: data.id,
            perc: data.discountPercentage,
            desc: data.description,
            vipLevel: data.vipLevel,
            exp: formattedExp,
        });

        reset(data);
        handleClose();
    };

    return (
        <Modal open={open} onClose={handleClose}>
            <Box className={classes.modalContainer}>
                <div className={classes.flexModalHeader}>
                    <IconButton onClick={handleClose}>
                        <ClearIcon />
                    </IconButton>
                </div>
                <Typography className={classes.titleContainer}>
                    Aggiungi sconto
                </Typography>
                <div className={classes.flexModalContainer}>
                    <form
                        onSubmit={handleSubmit(onSubmit)}
                        className={classes.formContainer}
                    >
                        <div className={classes.inputBlock}>
                            <TextField
                                fullWidth
                                label="ID sconto"
                                id="fullWidth"
                                {...register("id", {
                                    required: true,
                                })}
                            />

                            <Typography
                                className={classes.error}
                                variant="h6"
                                gutterBottom
                                component="div"
                            >
                                {errors.id && (
                                    <span>This field is required</span>
                                )}
                            </Typography>
                        </div>

                        <div className={classes.inputBlock}>
                            <TextField
                                fullWidth
                                label="Percentuale sconto"
                                id="fullWidth"
                                {...register("discountPercentage", {
                                    required: true,
                                })}
                            />

                            <Typography
                                className={classes.error}
                                variant="h6"
                                gutterBottom
                                component="div"
                            >
                                {errors.discountPercentage && (
                                    <span>This field is required</span>
                                )}
                            </Typography>
                        </div>

                        <div className={classes.inputBlock}>
                            <TextField
                                fullWidth
                                label="Livello VIP"
                                id="fullWidth"
                                {...register("vipLevel", {
                                    required: true,
                                })}
                            />

                            <Typography
                                className={classes.error}
                                variant="h6"
                                gutterBottom
                                component="div"
                            >
                                {errors.vipLevel && (
                                    <span>This field is required</span>
                                )}
                            </Typography>
                        </div>

                        <div className={classes.inputBlock}>
                            <TextField
                                fullWidth
                                label="Descrizione"
                                id="fullWidth"
                                {...register("description", {
                                    required: true,
                                })}
                            />

                            <Typography
                                className={classes.error}
                                variant="h6"
                                gutterBottom
                                component="div"
                            >
                                {errors.description && (
                                    <span>This field is required</span>
                                )}
                            </Typography>
                        </div>

                        <div className={classes.inputBlock}>
                            <Controller
                                control={control}
                                name={`expirationDate`}
                                render={({ field }) => (
                                    <LocalizationProvider
                                        dateAdapter={AdapterDayjs}
                                    >
                                        <DatePicker
                                            inputFormat="DD/MM/YYYY"
                                            label="Data scadenza"
                                            value={field.value}
                                            className={classes.datePicker}
                                            onChange={newValue => {
                                                field.onChange(newValue);
                                            }}
                                            InputProps={{
                                                disabled: true,
                                            }}
                                            renderInput={params => (
                                                <TextField
                                                    onKeyDown={e => {
                                                        e.preventDefault();
                                                    }}
                                                    className={classes.date}
                                                    {...params}
                                                />
                                            )}
                                        />
                                    </LocalizationProvider>
                                )}
                            />

                            <Typography
                                className={classes.error}
                                variant="h6"
                                gutterBottom
                                component="div"
                            >
                                {errors.expirationDate && (
                                    <span>This field is required</span>
                                )}
                            </Typography>
                        </div>
                        <div className={classes.buttonContainer}>
                            <Button
                                variant="contained"
                                type="submit"
                                className={classes.button}
                            >
                                Aggiungi sconto
                            </Button>
                        </div>
                    </form>
                </div>
            </Box>
        </Modal>
    );
};

export default ModalCreateDiscount;
