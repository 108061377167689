import { createStyles, makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => {
    return createStyles({
        bannerContainer: {
            backgroundColor: "#fff",
            border: "solid 1px",
            borderColor: "#44C1BE",
            margin: 30,
            padding: 20,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
        },
        buttonsContainer: {
            display: "flex",
        },
        button: {
            color: "#fff",
            marginTop: 10,
            width: "250px",
            margin: "18px",
        },
    });
});

export default useStyles;
