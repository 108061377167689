import * as React from "react";
import Box from "@mui/material/Box";
import useStyles from "./style";
import { Button } from "@mui/material";

type Props = {
    handleApprove: () => void;
    handleDisapprove: () => void;
    handleCallRequest: () => void;
    requestedTier: number;
};

const CheckKycBanner: React.FC<Props> = ({
    requestedTier,
    handleApprove,
    handleDisapprove,
    handleCallRequest,
}) => {
    const classes = useStyles();

    return (
        <Box className={classes.bannerContainer}>
            <p>
                {` L’utente ha richiesto la promozione al Tier ${requestedTier}`}
            </p>
            <div className={classes.buttonsContainer}>
                <Button
                    variant="contained"
                    className={classes.button}
                    onClick={handleApprove}
                >
                    Verifica dati
                </Button>
                <Button
                    variant="contained"
                    className={classes.button}
                    onClick={handleDisapprove}
                >
                    Rifiuta approvazione
                </Button>
                <Button
                    variant="contained"
                    className={classes.button}
                    onClick={handleCallRequest}
                >
                    Richiedi incontro video
                </Button>
            </div>
        </Box>
    );
};

export default CheckKycBanner;
