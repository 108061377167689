import PersonIcon from "@mui/icons-material/Person";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { Link } from "react-router-dom";
import { menu } from "../../constants";
import useStyles from "./style";

const MTDrawer: React.FC = () => {
    const classes = useStyles();

    return (
        <Box sx={{ display: "flex" }}>
            <Drawer
                className={classes.drawer}
                variant="permanent"
                anchor="left"
            >
                <Box className={classes.boxLogo}>
                    <PersonIcon className={classes.circleUser} />
                    <ListItemText
                        sx={{
                            "& .MuiTypography-root": {
                                fontWeight: "bold",
                                fontSize: "1.5rem",
                                marginTop: "0.5rem",
                            },
                        }}
                        className={classes.text}
                        primary={"Super User"}
                    />
                    <ListItemText
                        sx={{
                            marginTop: "0",
                        }}
                        className={classes.text}
                        primary={"Admin"}
                    />
                </Box>
                <List>
                    {menu.map(option => (
                        <Link
                            key={option.text}
                            to={option.path}
                            style={{ textDecoration: "none" }}
                        >
                            <ListItem button>
                                <ListItemIcon>
                                    <option.icon className={classes.icon} />
                                </ListItemIcon>
                                <ListItemText
                                    sx={{
                                        "& .MuiTypography-root": {
                                            fontWeight: "bold",
                                        },
                                    }}
                                    className={classes.text}
                                    primary={option.text}
                                />
                            </ListItem>
                        </Link>
                    ))}
                </List>
            </Drawer>
        </Box>
    );
};

export default MTDrawer;
