import React, { useEffect, useState } from "react";
import { Tab, Tabs } from "@mui/material";

import MTFilterBar from "../../components/MTFilterBar";
import MTUsersTable from "../../components/MTUsersTable";
import FormDialog from "../../components/MTFormDialog";
import useStyles from "./style";
import { UserInfo } from "../../store/users/types";

import { useSelector } from "react-redux";
import { getUsersState } from "../../store/users/users.slice";
import useManageUser from "../../hooks/blockchain/useManageUser";

const tableHead = [
    "Categoria",
    "Wallet address",
    "Nome",
    "Codice fiscale",
    "Saldo MIT",
    "Stato",
    "Azioni",
];

const filters = [
    {
        key: "address",
        text: "Ricerca wallet",
        inputType: "input",
    },
    {
        key: "name",
        text: "Ricerca nome",
        inputType: "input",
    },
    {
        key: "surname",
        text: "Ricerca cognome",
        inputType: "input",
    },
    {
        key: "email",
        text: "Ricerca Email",
        inputType: "input",
    },
];

type SelectedAddress = {
    address: string;
    action: string;
    userType: string;
};

const Users: React.FC = () => {
    const classes = useStyles();
    const [formattedData, setFormattedData] = useState<UserInfo[]>([]);
    const [activeFilters, setActiveFilters] = useState<{
        name: string;
        surname: string;
        email: string;
        address: string;
    }>({ name: "", surname: "", email: "", address: "" });
    const [selectedAddress, setDialogVisibility] = useState<SelectedAddress>({
        address: "",
        action: "",
        userType: "",
    });

    const [activeTab, setActiveTab] = useState("all");

    const { list: users } = useSelector(getUsersState);
    const { manageUserStatus } = useManageUser();

    const openDialog = (address: string, action: string, userType: string) => {
        setDialogVisibility({
            address: address,
            action: action,
            userType: userType,
        });
    };

    const closeDialog = () => {
        setDialogVisibility({ address: "", action: "", userType: "" });
    };

    const lock = (data: { motivation: string }) => {
        const user = users.find(
            user => user.walletAddress === selectedAddress.address,
        );

        const collectionName =
            selectedAddress.userType === "consumer" ? "consumers" : "merchants";

        if (user?.id) {
            manageUserStatus(
                user.id,
                collectionName,
                selectedAddress.address,
                "lock",
                data.motivation,
            );
        }

        closeDialog();
    };

    const unlock = () => {
        const user = users.find(
            user => user.walletAddress === selectedAddress.address,
        );

        const collectionName =
            selectedAddress.userType === "consumer" ? "consumers" : "merchants";

        if (user?.id) {
            manageUserStatus(
                user.id,
                collectionName,
                selectedAddress.address,
                "unlock",
            );
        }

        closeDialog();
    };

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setActiveTab(newValue);
    };

    useEffect(() => {
        let filteredData;

        if (
            (activeFilters.address ||
                activeFilters.name ||
                activeFilters.surname ||
                activeFilters.email) &&
            users
        ) {
            filteredData = users.filter(user => {
                return (
                    ((user.walletAddress &&
                        user.walletAddress.includes(activeFilters.address)) ||
                        !activeFilters.address) &&
                    ((user.tierOne?.email &&
                        user.tierOne.email
                            .toLowerCase()
                            .includes(activeFilters.email.toLowerCase())) ||
                        !activeFilters.email) &&
                    ((user.tierOne?.firstName &&
                        user.tierOne?.firstName
                            .toLowerCase()
                            .includes(activeFilters.name.toLowerCase())) ||
                        !activeFilters.name) &&
                    ((user.tierOne?.lastName &&
                        user.tierOne?.lastName
                            .toLowerCase()
                            .includes(activeFilters.surname.toLowerCase())) ||
                        !activeFilters.surname)
                );
            });
            setFormattedData(filteredData);
        } else {
            setFormattedData(users);
        }
    }, [activeFilters, users]);

    const filteredData =
        activeTab === "all"
            ? formattedData
            : formattedData.filter(user => user.pendingVerificationRequest);

    return (
        <div className={classes.container}>
            <Tabs
                value={activeTab}
                onChange={handleChange}
                sx={{ mb: "20px" }}
                variant="fullWidth"
            >
                <Tab value="all" label="Tutti gli utenti" />
                <Tab value="waiting" label="In attesa" />
            </Tabs>
            <MTFilterBar filters={filters} setFilters={setActiveFilters} />
            <MTUsersTable
                tableHead={tableHead}
                tableRows={filteredData}
                lockUser={openDialog}
                unlockUser={openDialog}
            />
            <FormDialog
                inputVisibility={selectedAddress.action === "lock"}
                title={
                    selectedAddress.action === "lock"
                        ? "Bloccare l'utente?"
                        : "Sbloccare l'utente?"
                }
                visibility={selectedAddress.address}
                onSubmitFunc={selectedAddress.action === "lock" ? lock : unlock}
                onCloseFunc={closeDialog}
            />
        </div>
    );
};
export default Users;
