import { ethers } from "ethers";
import { mitToken, personalData, roleManager, allowance } from "../contracts";
import { provider } from "../constants";

const personalDataContract = new ethers.Contract(
    personalData.contract_address,
    personalData.abi,
    provider,
);

const roleManagerContract = new ethers.Contract(
    roleManager.contract_address,
    roleManager.abi,
    provider,
);

const mitTokenContract = new ethers.Contract(
    mitToken.contract_address,
    mitToken.abi,
    provider,
);

const allowanceContract = new ethers.Contract(
    allowance.contract_address,
    allowance.abi,
    provider,
);

export {
    provider,
    personalDataContract,
    roleManagerContract,
    mitTokenContract,
    allowanceContract,
};
