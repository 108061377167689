import { PayloadAction } from "@reduxjs/toolkit";
import { Users, UserInfo, Payment } from "./types";
import { usersInitialState } from "./users.slice";

import { assign } from "lodash";

export const usersReducer = {
    setLoader: {
        reducer: (
            state: Users,
            action: PayloadAction<{
                value: boolean;
            }>,
        ): void => {
            state.loader = action.payload.value;
        },
        prepare: (value: boolean) => ({
            payload: {
                value,
            },
        }),
    },

    addUser: {
        reducer: (
            state: Users,
            action: PayloadAction<{
                user: UserInfo;
            }>,
        ): void => {
            state.list.push(action.payload.user);
        },
        prepare: (user: UserInfo) => ({
            payload: {
                user,
            },
        }),
    },

    updateUserData: {
        reducer: (
            state: Users,
            action: PayloadAction<{
                userData: Partial<UserInfo>;
            }>,
        ): void => {
            const { userData } = action.payload;

            state.list = state.list.map(user =>
                user.id === userData.id
                    ? ({
                          category: user.category,
                          status: user.status,
                          mitBalance: user.mitBalance,
                          ...userData,
                      } as UserInfo)
                    : user,
            );
        },
        prepare: (userData: Partial<UserInfo>) => ({
            payload: {
                userData,
            },
        }),
    },

    updateUserStatus: {
        reducer: (
            state: Users,
            action: PayloadAction<{
                userData: { walletAddress: string; status: number };
            }>,
        ): void => {
            const { userData } = action.payload;

            const userToEdit = state.list.find(
                user => user.walletAddress === userData.walletAddress,
            );

            if (userToEdit) {
                userToEdit.status = userData.status;
            }
        },
        prepare: (userData: { walletAddress: string; status: number }) => ({
            payload: {
                userData,
            },
        }),
    },

    updateUserBalance: {
        reducer: (
            state: Users,
            action: PayloadAction<{
                userData: { walletAddress: string; mitBalance: number };
            }>,
        ): void => {
            const { userData } = action.payload;

            const userToEdit = state.list.find(
                user => user.walletAddress === userData.walletAddress,
            );

            if (userToEdit) {
                userToEdit.mitBalance = userData.mitBalance;
            }
        },
        prepare: (userData: { walletAddress: string; mitBalance: number }) => ({
            payload: {
                userData,
            },
        }),
    },

    addPayment: {
        reducer: (
            state: Users,
            action: PayloadAction<{
                userId: string;
                paymentData: Payment;
            }>,
        ): void => {
            const { paymentData, userId } = action.payload;

            const userToEdit = state.list.find(user => user.id === userId);

            if (userToEdit?.payments) {
                userToEdit.payments.push(paymentData);
            }
        },
        prepare: (userId: string, paymentData: Payment) => ({
            payload: {
                userId,
                paymentData,
            },
        }),
    },

    updatePaymentData: {
        reducer: (
            state: Users,
            action: PayloadAction<{
                userId: string;
                paymentData: Partial<Payment>;
            }>,
        ): void => {
            const { userId, paymentData } = action.payload;

            const userToEdit = state.list.find(user => user.id === userId);

            if (userToEdit?.payments) {
                assign(userToEdit.payments, paymentData);
            }
        },
        prepare: (userId: string, paymentData: Partial<Payment>) => ({
            payload: {
                userId,
                paymentData,
            },
        }),
    },

    resetPayments: {
        reducer: (
            state: Users,
            action: PayloadAction<{
                userId: string;
            }>,
        ): void => {
            const { userId } = action.payload;

            const userToEdit = state.list.find(user => user.id === userId);

            if (userToEdit?.payments) {
                userToEdit.payments = [];
            }
        },
        prepare: (userId: string) => ({
            payload: {
                userId,
            },
        }),
    },

    reset: () => usersInitialState,
};
