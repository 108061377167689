import React, { useState } from "react";
import { DateRange } from "@mui/lab";
import { DateRangePicker } from "@mui/x-date-pickers-pro";
import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
    TextField,
} from "@mui/material";
import { Box } from "@mui/system";
import { CloseDialog } from "../../assets/icons";
import { CSVDownload, CSVLink } from "react-csv";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { getTransfers } from "../../store/transfers/transfers.slice";
import { getUsersState } from "../../store/users/users.slice";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { UserInfo, Document } from "../../store/users/types";
import { firestore } from "../../firebase";
import {
    collection,
    collectionGroup,
    getDocs,
    query,
} from "firebase/firestore";
import { useCallbackState } from "../../hooks";

interface Reason {
    from: string;
    reason: string;
    to: string;
}

type Props = {
    showModal: boolean;
    handleClose: () => void;
    exportType: string;
};

const getUserDataFormatted = (user: UserInfo) => {
    let vatNumber = "";
    let document: Document = {
        type: "",
        number: "",
        issuingEntity: "",
        expirationDate: undefined,
        issuingDate: undefined,
    };
    const tierOne = user.tierOne;

    if (tierOne) {
        if (user.category === "merchant" && "business" in tierOne) {
            vatNumber = tierOne?.business.fiscalCode;
        }
        if ("identificationDocument" in tierOne) {
            document = tierOne.identificationDocument;
        }
    }

    return [
        user.category,
        user?.id || "",
        tierOne?.firstName || "",
        tierOne?.lastName || "",
        tierOne?.fiscalCode || "",
        vatNumber,
        !tierOne?.birthDate
            ? ""
            : new Date(
                  tierOne?.birthDate?.seconds * 1000,
              ).toLocaleDateString() || "",
        tierOne?.birthplace || "",
        tierOne?.cap || "",
        tierOne?.citizenship || "",
        tierOne?.city || "",
        tierOne?.country || "",
        tierOne?.streetAddress || "",
        tierOne?.streetNumber || "",
        tierOne?.phone || "",
        tierOne?.job || "",
        document.type,
        document.number,
        !document.expirationDate
            ? ""
            : new Date(document.expirationDate?.seconds).toLocaleDateString(),
        !document.issuingDate
            ? ""
            : new Date(document.issuingDate?.seconds).toLocaleDateString(),
        document.issuingEntity,
        user?.mitBalance + " MIT" || "",
        user?.walletAddress || "",
        user.status === 1 ? "ATTIVO" : "NON ATTIVO",
    ];
};

const userCSVColumns = [
    "Tipo utente",
    "ID",
    "Nome",
    "Cognome",
    "Codice fiscale",
    "P.IVA",
    "Data di nascita",
    "Luogo di nascita",
    "CAP",
    "Cittadinanza",
    "Città",
    "Paese",
    "Indirizzo",
    "Civico",
    "Telefono",
    "Professione",
    "Tipo di documento",
    "Numero documento",
    "Scadenza documento",
    "Rilascio documento",
    "Ente di rilascio",
    "Wallet balance",
    "Wallet address",
    "Stato",
];

const ExportCSV: React.FC<Props> = ({ showModal, handleClose, exportType }) => {
    const [dateRange, setDateRange] = useState<DateRange<Date>>([null, null]);
    const [data, setData] = useCallbackState([[""]]);
    const [isLoading, setIsLoading] = useState(true);
    const [userTransaction, setUserTransaction] = useCallbackState([[""]]);
    const { list: transfers } = useSelector(getTransfers);
    const { list: users } = useSelector(getUsersState);
    const currentPath = useLocation().pathname;

    const splittedPath = currentPath.split("/");
    const userAddress = splittedPath[4];

    const getUserNameFromAddress = (address: string) => {
        const user = users.find(user => user.walletAddress === address);
        if (user && user["tierOne"]) {
            return `${user?.tierOne?.firstName} ${user?.tierOne?.lastName}`;
        }
        return "";
    };

    const getPurchasesInfo = async () => {
        const info: any = [];
        const ref = query(collectionGroup(firestore, "payments"));
        const snapshotsDocs = await getDocs(ref);
        snapshotsDocs.forEach(doc => {
            const data = doc.data();
            let amount = "0";
            let paymentType = "Paypal";
            let cryptoValue = 0;
            let createdAt = "";
            if (data) {
                if (data.type === "Paypal") {
                    amount = data.content.resource.amount.value;
                    createdAt = data.content.create_time;
                } else if (data.type === "Bonifico") {
                    paymentType = "Bonifico";
                    amount = (data.content.amount / 10).toString();
                    createdAt = data.content.createdAt;
                } else {
                    amount =
                        data.content.event.data.payments[0].net.local.amount;
                    paymentType =
                        data.content.event.data.payments[0].net.crypto.currency;
                    cryptoValue =
                        data.content.event.data.exchange_rates[
                            `${paymentType}-USD`
                        ];
                    createdAt = data.content.event.created_at;
                }

                info.push({
                    userId: doc.ref.parent.parent?.id,
                    type: paymentType,
                    amount: parseInt(amount, 10),
                    cryptoValue: cryptoValue,
                    createdAt: new Date(createdAt).getTime() / 1000,
                });
            }
        });

        return info;
    };

    const getReasonsForPayments = async () => {
        const queryTransfersReasons = query(
            collection(firestore, "transfersReasons"),
        );

        const querySnapshot = await getDocs(queryTransfersReasons);
        const result = new Map();
        querySnapshot.forEach(doc => {
            result.set(doc.id, doc.data());
        });
        return result;
    };

    const getExportData = async () => {
        switch (exportType) {
            case "transactions":
                {
                    setIsLoading(true);
                    const reasons = await getReasonsForPayments();
                    let transactions = transfers;
                    if (dateRange[0] && dateRange[1]) {
                        const dateStringFrom =
                            Date.parse(dateRange[0]!.toString()) / 1000;
                        const dateStringTo =
                            Date.parse(dateRange[1]!.toString()) / 1000 + 86399;

                        const newdata = transfers.filter((txn: any) => {
                            return (
                                txn.timestamp >= dateStringFrom &&
                                txn.timestamp <= dateStringTo
                            );
                        });

                        transactions = newdata;
                    }

                    const formattedValues: any = transactions.map(
                        (txn: any) => {
                            const values = Object.values(txn);
                            let date = "";
                            if (typeof values[3] === "number") {
                                const timestamp: number = values[3] * 1000;
                                date = new Date(timestamp).toLocaleDateString();
                            }

                            const from = getUserNameFromAddress(
                                values[1] as string,
                            );

                            const to = getUserNameFromAddress(
                                values[2] as string,
                            );

                            let reason = "";
                            const paymentInfo = reasons.get(values[0]);
                            if (paymentInfo) {
                                reason = paymentInfo.reason as string;
                            }

                            return [
                                values[0],
                                from ? from : values[1],
                                to ? to : values[2],
                                date,
                                values[4],
                                reason,
                            ];
                        },
                    );
                    setData(
                        [
                            [
                                "id",
                                "from",
                                "to",
                                "timestamp",
                                "amount",
                                "reason",
                            ],
                            ...formattedValues,
                        ],
                        () => {
                            setIsLoading(false);
                        },
                    );
                }
                break;
            case "users":
            case "info-wallet":
                {
                    let values: any = [];
                    if (!userAddress) {
                        let usersFiltered = users;
                        if (dateRange[0] && dateRange[1]) {
                            const dateStringFrom =
                                Date.parse(dateRange[0]!.toString()) / 1000;
                            const dateStringTo =
                                Date.parse(dateRange[1]!.toString()) / 1000 +
                                86399;

                            usersFiltered = users.filter(
                                (singleUser: UserInfo) => {
                                    if (
                                        singleUser["created_at"] !== undefined
                                    ) {
                                        return (
                                            singleUser.created_at.seconds >=
                                                dateStringFrom &&
                                            singleUser.created_at.seconds <=
                                                dateStringTo
                                        );
                                    }
                                },
                            );
                        }

                        values = usersFiltered.map((user: UserInfo) =>
                            getUserDataFormatted(user),
                        );

                        setData([userCSVColumns, ...values]);
                    } else {
                        const user: UserInfo | undefined = users.find(
                            user => user.walletAddress === userAddress,
                        );

                        if (user) values = getUserDataFormatted(user);
                        setData([userCSVColumns, values]);
                    }
                }
                break;
        }
    };

    const formatPurchases = async () => {
        setIsLoading(true);
        const purchasesRows = await getPurchasesInfo();
        const purchases = new Map();
        let userId: string | undefined;
        if (userAddress) {
            userId = users.find(user => user.walletAddress === userAddress)?.id;
        }

        let transactionsRows = userId
            ? purchasesRows.filter(
                  (purchase: any) => purchase.userId === userId,
              )
            : purchasesRows;

        if (dateRange[0] && dateRange[1]) {
            const dateStringFrom = Date.parse(dateRange[0]!.toString()) / 1000;
            const dateStringTo =
                Date.parse(dateRange[1]!.toString()) / 1000 + 86399;

            transactionsRows = transactionsRows.filter((txn: any) => {
                return (
                    txn.createdAt >= dateStringFrom &&
                    txn.createdAt <= dateStringTo
                );
            });
        }

        transactionsRows.map((transfer: any) => {
            const user = users.find(user => user.id === transfer.userId);

            if (user) {
                if (
                    !purchases.get(`${user.walletAddress} - ${transfer.type}`)
                ) {
                    purchases.set(`${user.walletAddress} - ${transfer.type}`, {
                        type: transfer.type,
                        name: `${user.tierOne?.firstName} ${user.tierOne?.lastName}`,
                        amount: parseInt(transfer.amount, 10),
                        cryptoValue: transfer.cryptoValue,
                        mitBalance: user.mitBalance,
                        numberOfOperations: 1,
                    });
                } else {
                    purchases.get(
                        `${user.walletAddress} - ${transfer.type}`,
                    ).amount += transfer.amount;
                    purchases.get(`${user.walletAddress} - ${transfer.type}`)
                        .numberOfOperations++;
                }
            }
        });

        const values = [];

        for (const [key, value] of purchases) {
            values.push([
                key.substring(0, key.search("-")),
                value.type,
                value.name,
                value.numberOfOperations,
                value.amount * 10 === 0 ? "" : value.amount * 10,
                value.amount === 0 ? "" : value.amount,
                value.mitBalance === 0 ? "" : value.mitBalance,
                value.mitBalance / 10 === 0 ? "" : value.mitBalance / 10,
            ]);
        }

        setUserTransaction(
            [
                [
                    "Wallet",
                    "Tipo di pagamento",
                    "Nome utente",
                    "Numero di operazioni",
                    "Valore MIT",
                    "Valore EUR",
                    "Bilancio MIT utente",
                    "Bilancio EUR utente",
                ],
                ...values,
            ],
            () => {
                setIsLoading(false);
            },
        );
    };

    const getFileName = (type: string) => {
        let userName = "";

        if (type === "info-wallet" || type === "info-wallet-transactions") {
            const user = users.find(user => user.walletAddress === userAddress);
            userName = user?.tierOne?.firstName + "_" + user?.tierOne?.lastName;
        }

        const today = new Date().toLocaleDateString();

        switch (type) {
            case "info-wallet-transactions":
                return `user-transactions-${userName}-${today}.csv`;
            case "info-wallet":
                return `user-${userName}-${today}.csv`;
            default:
                return `${type}-${today}.csv`;
        }
    };

    return (
        <Dialog open={showModal}>
            <Box sx={{ mb: "2rem" }}>
                <DialogTitle
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        fontWeight: "bold",
                        fontSize: "28px",
                        marginTop: "36px",
                    }}
                >
                    Esporta .csv
                </DialogTitle>
                <div
                    style={{
                        position: "absolute",
                        top: "24px",
                        right: "24px",
                    }}
                >
                    <IconButton
                        sx={{ display: "flex" }}
                        onClick={() => {
                            setIsLoading(true);
                            handleClose();
                        }}
                    >
                        <CloseDialog />
                    </IconButton>
                </div>
                <DialogContentText
                    sx={{
                        textAlign: "center",
                        typography: "subtitle2",
                        fontWeight: "bold",
                        marginX: "70px",
                    }}
                >
                    Fai click sul pulsante sottostante per scaricare il csv con
                    i dati
                </DialogContentText>
                <DialogContent sx={{ marginX: "8rem" }}>
                    <Box
                        sx={{
                            mt: "1.5rem",
                        }}
                    >
                        <LocalizationProvider
                            dateAdapter={AdapterDayjs}
                            localeText={{
                                start: "Data da",
                                end: "Data a",
                            }}
                        >
                            <DateRangePicker
                                value={dateRange}
                                onChange={newValue => {
                                    setDateRange(newValue);
                                }}
                                renderInput={(startProps, endProps) => (
                                    <>
                                        <TextField {...startProps} />
                                        <Box sx={{ mx: 2 }}> a </Box>
                                        <TextField {...endProps} />
                                    </>
                                )}
                            />
                        </LocalizationProvider>
                    </Box>
                </DialogContent>
                <DialogActions sx={{ justifyContent: "center" }}>
                    <>
                        {exportType === "info-wallet" ||
                            (exportType === "users" && (
                                <CSVLink
                                    data={data}
                                    onClick={() => getExportData()}
                                    filename={getFileName(exportType)}
                                    style={{
                                        color: "#fff",
                                        marginBottom: "36px",
                                        textDecoration: "none",
                                        backgroundColor: "#44c1be",
                                        padding: 20,
                                        borderRadius: 5,
                                    }}
                                >
                                    ESPORTA DATI ANAGRAFICI
                                </CSVLink>
                            ))}
                        {exportType === "transactions" &&
                            (transfers.length > 0 ? (
                                <>
                                    <Button
                                        variant="contained"
                                        type="submit"
                                        sx={{
                                            color: "#fff",
                                            marginBottom: "36px",
                                            marginLeft: "5px",
                                            textDecoration: "none",
                                            backgroundColor: "#44c1be",
                                            padding: 2,
                                            borderRadius: 1,
                                        }}
                                        onClick={() => getExportData()}
                                    >
                                        ESPORTA CSV
                                    </Button>
                                    {!isLoading && (
                                        <CSVDownload
                                            data={data}
                                            filename="transactions.csv"
                                            target="_blank"
                                        />
                                    )}
                                </>
                            ) : (
                                <CircularProgress />
                            ))}
                        {(exportType === "users" ||
                            exportType === "info-wallet") &&
                            (transfers.length > 0 ? (
                                <>
                                    <Button
                                        variant="contained"
                                        type="submit"
                                        sx={{
                                            color: "#fff",
                                            marginBottom: "36px",
                                            marginLeft: "5px",
                                            textDecoration: "none",
                                            backgroundColor: "#44c1be",
                                            padding: 2,
                                            borderRadius: 1,
                                        }}
                                        onClick={async () =>
                                            await formatPurchases()
                                        }
                                    >
                                        Scarica transazioni
                                    </Button>
                                    {!isLoading && (
                                        <CSVDownload
                                            data={userTransaction}
                                            filename="purchases.csv"
                                            target="_blank"
                                        />
                                    )}
                                </>
                            ) : (
                                <div
                                    style={{
                                        width: "150px",
                                        height: "100px",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                    }}
                                >
                                    <CircularProgress />
                                </div>
                            ))}
                    </>
                </DialogActions>
            </Box>
        </Dialog>
    );
};

export default ExportCSV;
