import { yupResolver } from "@hookform/resolvers/yup";
import DeleteIcon from "@mui/icons-material/Delete";
import SaveIcon from "@mui/icons-material/Save";
import Button from "@mui/lab/LoadingButton";
import Fab from "@mui/material/Fab";
import TextField from "@mui/material/TextField";
import { useEffect, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { FormData, validationSchema } from "./formData";
import useStyles from "./style";
import { useManageVipStatus } from "../../hooks";
import { ButtonGroup } from "@mui/material";
import ModalEditLocking from "./ModalEditLocking";

const VipLevels: React.FC = () => {
    const classes = useStyles();
    const [openDialog, setOpenDialog] = useState(false);

    const { vipLevels, createVipLevel, editVipLevel, deleteVipLevel } =
        useManageVipStatus();

    useEffect(() => {
        reset({ vipLevels: vipLevels });
    }, [vipLevels]);

    const {
        control,
        register,
        reset,
        getValues,
        formState: { errors },
    } = useForm<FormData>({
        resolver: yupResolver(validationSchema),
        defaultValues: {
            vipLevels: vipLevels,
        },
        mode: "onChange",
    });
    const {
        fields,
        append: appendField,
        remove: removeLastField,
    } = useFieldArray({
        control, // control props comes from useForm (optional: if you are using FormContext)
        name: "vipLevels", // unique name for your Field Array
        // keyName: "id", default to "id", you can change the key name
    });

    const editLevel = async (data: {
        index: number;
        levelName: string;
        amount: number;
    }) => {
        if (data.index < vipLevels.length) {
            editVipLevel(data.index, data.levelName, data.amount);
        } else {
            await createVipLevel(data.levelName, data.amount);
        }
    };

    const deleteLevel = async (data: { index: number; levelName: string }) => {
        if (data.index == vipLevels.length - 1) {
            deleteVipLevel(data.levelName);
        } else {
            removeLastField(data.index);
        }
    };

    return (
        <>
            <ModalEditLocking
                open={openDialog}
                handleClose={() => setOpenDialog(false)}
            />
            <div className={classes.form}>
                <form className={classes.form}>
                    <ButtonGroup
                        variant="contained"
                        aria-label="outlined primary button group"
                        sx={{ marginBottom: "35px" }}
                    >
                        <Button
                            sx={{ color: "#ffff" }}
                            variant="contained"
                            type="button"
                            onClick={() => {
                                if (fields.length == vipLevels.length + 1) {
                                    toast(`Livello già aggiunto!`, {
                                        type: "warning",
                                        position: "top-right",
                                        autoClose: 2500,
                                        hideProgressBar: false,
                                        closeOnClick: true,
                                        pauseOnHover: true,
                                        draggable: true,
                                        progress: undefined,
                                    });
                                    return;
                                }
                                appendField({
                                    name: "",
                                    level: 0,
                                    threshold: 0,
                                });
                            }}
                        >
                            Aggiungi livello
                        </Button>
                        <Button
                            sx={{ color: "#ffff" }}
                            variant="contained"
                            type="button"
                            onClick={() => setOpenDialog(true)}
                        >
                            Modifica locking time
                        </Button>
                    </ButtonGroup>
                    <div className={classes.fieldsContainer}>
                        {fields.map((field, index) => (
                            <div
                                className={classes.flexContainer}
                                key={field.id}
                            >
                                <TextField
                                    fullWidth
                                    sx={{ paddingRight: "30px" }}
                                    label="Nome livello"
                                    id="fullWidth"
                                    {...register(`vipLevels.${index}.name`, {
                                        required: true,
                                    })}
                                    error={!!errors.vipLevels?.[index]?.name}
                                />
                                <TextField
                                    fullWidth
                                    label="Importo"
                                    id="fullWidth"
                                    type="number"
                                    {...register(
                                        `vipLevels.${index}.threshold`,
                                        {
                                            required: true,
                                        },
                                    )}
                                    error={
                                        !!errors.vipLevels?.[index]?.threshold
                                    }
                                />
                                <Fab
                                    color="primary"
                                    sx={{
                                        width: "120px",
                                        height: "55px",
                                        marginLeft: "30px",
                                    }}
                                    onClick={async () => {
                                        editLevel({
                                            index: index,
                                            levelName: getValues(
                                                `vipLevels.${index}.name`,
                                            ),
                                            amount: getValues(
                                                `vipLevels.${index}.threshold`,
                                            ),
                                        });
                                    }}
                                >
                                    <SaveIcon
                                        sx={{
                                            fontSize: "28px",
                                        }}
                                        className={classes.deleteIcon}
                                    />
                                </Fab>
                                {fields.length === index + 1 && (
                                    <Fab
                                        color="primary"
                                        sx={{
                                            width: "120px",
                                            height: "55px",
                                            marginLeft: "30px",
                                        }}
                                        onClick={() =>
                                            deleteLevel({
                                                index: index,
                                                levelName: getValues(
                                                    `vipLevels.${index}.name`,
                                                ),
                                            })
                                        }
                                    >
                                        <DeleteIcon
                                            sx={{
                                                fontSize: "28px",
                                            }}
                                            className={classes.deleteIcon}
                                        />
                                    </Fab>
                                )}
                            </div>
                        ))}
                    </div>
                </form>
            </div>
        </>
    );
};
export default VipLevels;
