import React from "react";
import { TextField, Typography } from "@mui/material";
import { SubmitHandler, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import useStyles from "./style";
import { Business } from "../../../store/users/types";

type Inputs = {
    commercialName: string;
    businessName: string;
    email: string;
    phone: string;
    address: string;
    streetNumber: string;
    district: string;
    city: string;
    state: string;
    postalCode: string;
    activitySector: string;
    vatNumber: string;
    description: string;
};

type Props = {
    userData?: Business;
};

const CompanyInfoForm: React.FC<Props> = ({ userData }) => {
    const classes = useStyles();

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<Inputs>({
        defaultValues: {
            commercialName: userData?.commercialName || "",
            businessName: userData?.businessName || "",
            email: userData?.email || "",
            phone: userData?.phone || "",
            address: userData?.address || "",
            streetNumber: userData?.streetNumber || "",
            district: userData?.province || "",
            city: userData?.city || "",
            state: userData?.state || "",
            postalCode: userData?.cap || "",
            activitySector: userData?.economicActivitySector || "",
            vatNumber: userData?.fiscalCode || "",
            description: userData?.economicActivityDescription || "",
        },
    });

    const onSubmit: SubmitHandler<Inputs> = () => {
        toast.success("Dati DB salvati!");
    };

    const disabled = userData ? true : false;

    return (
        <form
            onSubmit={handleSubmit(onSubmit)}
            className={classes.flexContainer}
        >
            <div className={classes.inputsContainer}>
                <div className={classes.inputBlock}>
                    <TextField
                        fullWidth
                        label="Nome commerciale"
                        id="fullWidth"
                        {...register("commercialName", {
                            required: true,
                        })}
                        disabled={disabled}
                    />

                    <Typography
                        className={classes.error}
                        variant="h6"
                        gutterBottom
                        component="div"
                    >
                        {errors.commercialName && (
                            <span>This field is required</span>
                        )}
                    </Typography>
                </div>

                <div className={classes.inputBlock}>
                    <TextField
                        fullWidth
                        label="Ragione sociale"
                        id="fullWidth"
                        {...register("businessName", {
                            required: true,
                        })}
                        disabled={disabled}
                    />

                    <Typography
                        className={classes.error}
                        variant="h6"
                        gutterBottom
                        component="div"
                    >
                        {errors.businessName && (
                            <span>This field is required</span>
                        )}
                    </Typography>
                </div>

                <div className={classes.inputBlock}>
                    <TextField
                        fullWidth
                        label="Email aziendale"
                        id="fullWidth"
                        {...register("email", {
                            required: true,
                        })}
                        disabled={disabled}
                    />

                    <Typography
                        className={classes.error}
                        variant="h6"
                        gutterBottom
                        component="div"
                    >
                        {errors.email && <span>This field is required</span>}
                    </Typography>
                </div>

                <div className={classes.inputBlock}>
                    <TextField
                        fullWidth
                        label="Telefono aziendale"
                        id="fullWidth"
                        {...register("phone", {
                            required: true,
                        })}
                        disabled={disabled}
                    />

                    <Typography
                        className={classes.error}
                        variant="h6"
                        gutterBottom
                        component="div"
                    >
                        {errors.phone && <span>This field is required</span>}
                    </Typography>
                </div>

                <div className={classes.inputBlock}>
                    <TextField
                        fullWidth
                        label="Indirizzo sede legale"
                        id="fullWidth"
                        {...register("address", {
                            required: true,
                        })}
                        disabled={disabled}
                    />

                    <Typography
                        className={classes.error}
                        variant="h6"
                        gutterBottom
                        component="div"
                    >
                        {errors.address && <span>This field is required</span>}
                    </Typography>
                </div>

                <div className={classes.inputBlock}>
                    <TextField
                        fullWidth
                        label="Civico sede legale"
                        id="fullWidth"
                        {...register("streetNumber", {
                            required: true,
                        })}
                        disabled={disabled}
                    />

                    <Typography
                        className={classes.error}
                        variant="h6"
                        gutterBottom
                        component="div"
                    >
                        {errors.streetNumber && (
                            <span>This field is required</span>
                        )}
                    </Typography>
                </div>

                <div className={classes.inputBlock}>
                    <TextField
                        fullWidth
                        label="Provincia"
                        id="fullWidth"
                        {...register("district", {
                            required: true,
                        })}
                        disabled={disabled}
                    />

                    <Typography
                        className={classes.error}
                        variant="h6"
                        gutterBottom
                        component="div"
                    >
                        {errors.district && <span>This field is required</span>}
                    </Typography>
                </div>

                <div className={classes.inputBlock}>
                    <TextField
                        fullWidth
                        label="Città"
                        id="fullWidth"
                        {...register("city", {
                            required: true,
                        })}
                        disabled={disabled}
                    />

                    <Typography
                        className={classes.error}
                        variant="h6"
                        gutterBottom
                        component="div"
                    >
                        {errors.city && <span>This field is required</span>}
                    </Typography>
                </div>

                <div className={classes.inputBlock}>
                    <TextField
                        fullWidth
                        label="CAP"
                        id="fullWidth"
                        {...register("postalCode", {
                            required: true,
                        })}
                        disabled={disabled}
                    />

                    <Typography
                        className={classes.error}
                        variant="h6"
                        gutterBottom
                        component="div"
                    >
                        {errors.postalCode && (
                            <span>This field is required</span>
                        )}
                    </Typography>
                </div>

                <div className={classes.inputBlock}>
                    <TextField
                        fullWidth
                        label="Stato"
                        id="fullWidth"
                        {...register("state", {
                            required: true,
                        })}
                        disabled={disabled}
                    />

                    <Typography
                        className={classes.error}
                        variant="h6"
                        gutterBottom
                        component="div"
                    >
                        {errors.state && <span>This field is required</span>}
                    </Typography>
                </div>

                <div className={classes.inputBlock}>
                    <TextField
                        fullWidth
                        label="activitySector"
                        id="fullWidth"
                        {...register("state", {
                            required: true,
                        })}
                        disabled={disabled}
                    />

                    <Typography
                        className={classes.error}
                        variant="h6"
                        gutterBottom
                        component="div"
                    >
                        {errors.activitySector && (
                            <span>This field is required</span>
                        )}
                    </Typography>
                </div>

                <div className={classes.inputBlock}>
                    <TextField
                        fullWidth
                        label="Codice fiscale impresa / VAT number"
                        id="fullWidth"
                        {...register("vatNumber", {
                            required: true,
                        })}
                        disabled={disabled}
                    />

                    <Typography
                        className={classes.error}
                        variant="h6"
                        gutterBottom
                        component="div"
                    >
                        {errors.vatNumber && (
                            <span>This field is required</span>
                        )}
                    </Typography>
                </div>

                <div className={classes.inputFullBlock}>
                    <TextField
                        fullWidth
                        label="Descrizione azienda"
                        id="fullWidth"
                        {...register("description", {
                            required: true,
                        })}
                        disabled={disabled}
                    />

                    <Typography
                        className={classes.error}
                        variant="h6"
                        gutterBottom
                        component="div"
                    >
                        {errors.description && (
                            <span>This field is required</span>
                        )}
                    </Typography>
                </div>
            </div>
        </form>
    );
};

export default CompanyInfoForm;
