import React from "react";
import { CircularProgress } from "@mui/material";
import FormLimits from "./FormLimits";
import { useSelector } from "react-redux";
import { getSettingsState } from "../../store/settings/settings.slice";

const OperationsLimits: React.FC = () => {
    const { loader, limits } = useSelector(getSettingsState);

    return loader ? <CircularProgress /> : <FormLimits formData={limits} />;
};
export default OperationsLimits;
