import React from "react";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Button,
    TextField,
    Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useValidation, useYupValidationResolver } from "../../hooks";
import useStyles from "./style";
import { Limits } from "../../store/settings/types";
import { useSettings } from "../../hooks";

type Props = {
    formData: Limits;
};

const FormLimits: React.FC<Props> = ({ formData }) => {
    const classes = useStyles();
    const { required, validateNumber } = useValidation();

    const { updateLimits } = useSettings();

    const onSubmit = (data: Limits) => {
        updateLimits(data);
    };

    const limitsForm = {
        initialValues: {
            maxVolume: formData.maxVolume || 0,
            annualLimitVolumesStudents:
                formData.annualLimitVolumesStudents || 0,
            annualLimitVolumesRetirees:
                formData.annualLimitVolumesRetirees || 0,
            annualLimitVolumesWorkers: formData.annualLimitVolumesWorkers || 0,
            annualLimitVolumesBusiness:
                formData.annualLimitVolumesBusiness || 0,
            maxSingleOperation: formData.maxSingleOperation || 0,
            maxSingleOperationBusiness:
                formData.maxSingleOperationBusiness || 0,
            maxSingleOperationRetirees:
                formData.maxSingleOperationRetirees || 0,
            maxSingleOperationStudents:
                formData.maxSingleOperationStudents || 0,
            maxSingleOperationWorkers: formData.maxSingleOperationWorkers || 0,
            maxSingleOperationUnemployed:
                formData.maxSingleOperationUnemployed || 0,
            annualLimitVolumesUnemployed:
                formData.annualLimitVolumesUnemployed || 0,
        },
        validationSchema: Yup.object({
            maxVolume: required(validateNumber()),
            annualLimitVolumesStudents: required(validateNumber()),
            annualLimitVolumesRetirees: required(validateNumber()),
            annualLimitVolumesWorkers: required(validateNumber()),
            annualLimitVolumesBusiness: required(validateNumber()),
            annualLimitVolumesUnemployed: required(validateNumber()),
            maxSingleOperation: required(validateNumber()),
            maxSingleOperationBusiness: required(validateNumber()),
            maxSingleOperationRetirees: required(validateNumber()),
            maxSingleOperationStudents: required(validateNumber()),
            maxSingleOperationWorkers: required(validateNumber()),
            maxSingleOperationUnemployed: required(validateNumber()),
        }),
    };

    const { initialValues, validationSchema } = limitsForm;

    const resolver = useYupValidationResolver(validationSchema);

    const formState = useForm<Limits>({
        defaultValues: initialValues,
        resolver,
        mode: "onChange",
    });

    const {
        handleSubmit,
        formState: { errors },
        register,
    } = formState;

    return (
        <form
            style={{
                display: "flex",
                justifyContent: "space-between",
                flexDirection: "column",
            }}
            onSubmit={handleSubmit(onSubmit)}
        >
            <div>
                <Accordion elevation={0} className={classes.accordion}>
                    <AccordionSummary
                        className={classes.accordionSummary}
                        expandIcon={
                            <ExpandMoreIcon
                                className={classes.primaryThemeColor}
                            />
                        }
                    >
                        <Typography className={classes.accordionTitle}>
                            Valore massimo operazioni
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails className={classes.accordionDetails}>
                        <div className={classes.inputsContainer}>
                            <div className={classes.inputBlock}>
                                <TextField
                                    fullWidth
                                    label="Valore massimo singola operazione utenti non verificati"
                                    id="maxSingleOperation"
                                    {...register("maxSingleOperation")}
                                />

                                <Typography
                                    className={classes.error}
                                    variant="h6"
                                    gutterBottom
                                    component="div"
                                >
                                    {errors.maxSingleOperation && (
                                        <p>
                                            {errors.maxSingleOperation.message}
                                        </p>
                                    )}
                                </Typography>
                            </div>
                            <div className={classes.inputBlock}>
                                <TextField
                                    fullWidth
                                    label="Valore massimo singola operazione utenti non lavoratori"
                                    id="maxSingleOperationUnemployed"
                                    {...register(
                                        "maxSingleOperationUnemployed",
                                    )}
                                />

                                <Typography
                                    className={classes.error}
                                    variant="h6"
                                    gutterBottom
                                    component="div"
                                >
                                    {errors.maxSingleOperation && (
                                        <p>
                                            {errors.maxSingleOperation.message}
                                        </p>
                                    )}
                                </Typography>
                            </div>
                            <div className={classes.inputBlock}>
                                <TextField
                                    fullWidth
                                    label="Valore massimo singola operazione studenti"
                                    id="maxSingleOperationStudents"
                                    {...register("maxSingleOperationStudents")}
                                />

                                <Typography
                                    className={classes.error}
                                    variant="h6"
                                    gutterBottom
                                    component="div"
                                >
                                    {errors.maxSingleOperationStudents && (
                                        <p>
                                            {
                                                errors
                                                    .maxSingleOperationStudents
                                                    .message
                                            }
                                        </p>
                                    )}
                                </Typography>
                            </div>
                            <div className={classes.inputBlock}>
                                <TextField
                                    fullWidth
                                    label="Valore massimo singola operazione pensionati"
                                    id="maxSingleOperationRetirees"
                                    {...register("maxSingleOperationRetirees")}
                                />

                                <Typography
                                    className={classes.error}
                                    variant="h6"
                                    gutterBottom
                                    component="div"
                                >
                                    {errors.maxSingleOperationRetirees && (
                                        <p>
                                            {
                                                errors
                                                    .maxSingleOperationRetirees
                                                    .message
                                            }
                                        </p>
                                    )}
                                </Typography>
                            </div>
                            <div className={classes.inputBlock}>
                                <TextField
                                    fullWidth
                                    label="Valore massimo singola operazione lavoratori"
                                    id="maxSingleOperationWorkers"
                                    {...register("maxSingleOperationWorkers")}
                                />

                                <Typography
                                    className={classes.error}
                                    variant="h6"
                                    gutterBottom
                                    component="div"
                                >
                                    {errors.maxSingleOperationWorkers && (
                                        <p>
                                            {
                                                errors.maxSingleOperationWorkers
                                                    .message
                                            }
                                        </p>
                                    )}
                                </Typography>
                            </div>
                            <div className={classes.inputBlock}>
                                <TextField
                                    fullWidth
                                    label="Valore massimo singola operazione business"
                                    id="maxSingleOperationBusiness"
                                    {...register("maxSingleOperationBusiness")}
                                />

                                <Typography
                                    className={classes.error}
                                    variant="h6"
                                    gutterBottom
                                    component="div"
                                >
                                    {errors.maxSingleOperationBusiness && (
                                        <p>
                                            {
                                                errors
                                                    .maxSingleOperationBusiness
                                                    .message
                                            }
                                        </p>
                                    )}
                                </Typography>
                            </div>
                        </div>
                    </AccordionDetails>
                </Accordion>
                <Accordion elevation={0} className={classes.accordion}>
                    <AccordionSummary
                        className={classes.accordionSummary}
                        expandIcon={
                            <ExpandMoreIcon
                                className={classes.primaryThemeColor}
                            />
                        }
                    >
                        <Typography className={classes.accordionTitle}>
                            Limiti annui
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails className={classes.accordionDetails}>
                        <div className={classes.inputsContainer}>
                            <div className={classes.inputBlock}>
                                <TextField
                                    fullWidth
                                    label="Limite volume utenti non verificati"
                                    id="maxVolume"
                                    {...register("maxVolume")}
                                />
                                <Typography
                                    className={classes.error}
                                    variant="h6"
                                    gutterBottom
                                    component="div"
                                >
                                    {errors.maxVolume &&
                                        errors.maxVolume.message}
                                </Typography>
                            </div>
                            <div className={classes.inputBlock}>
                                <TextField
                                    fullWidth
                                    label="Limite volume utenti non lavoratori"
                                    id="annualLimitVolumesUnemployed"
                                    {...register(
                                        "annualLimitVolumesUnemployed",
                                    )}
                                />
                                <Typography
                                    className={classes.error}
                                    variant="h6"
                                    gutterBottom
                                    component="div"
                                >
                                    {errors.maxVolume &&
                                        errors.maxVolume.message}
                                </Typography>
                            </div>
                            <div className={classes.inputBlock}>
                                <TextField
                                    fullWidth
                                    label="Limite volumi annui studenti"
                                    id="annualLimitVolumesStudents"
                                    {...register("annualLimitVolumesStudents")}
                                />
                                <Typography
                                    className={classes.error}
                                    variant="h6"
                                    gutterBottom
                                    component="div"
                                >
                                    {errors.annualLimitVolumesStudents &&
                                        errors.annualLimitVolumesStudents
                                            .message}
                                </Typography>
                            </div>
                            <div className={classes.inputBlock}>
                                <TextField
                                    fullWidth
                                    label="Limite volumi annui pensionati"
                                    id="annualLimitVolumesRetirees"
                                    {...register("annualLimitVolumesRetirees")}
                                />
                                <Typography
                                    className={classes.error}
                                    variant="h6"
                                    gutterBottom
                                    component="div"
                                >
                                    {errors.annualLimitVolumesRetirees &&
                                        errors.annualLimitVolumesRetirees
                                            .message}
                                </Typography>
                            </div>
                            <div className={classes.inputBlock}>
                                <TextField
                                    fullWidth
                                    label="Limite volumi annui lavoratori"
                                    id="annualLimitVolumesWorkers"
                                    {...register("annualLimitVolumesWorkers")}
                                />
                                <Typography
                                    className={classes.error}
                                    variant="h6"
                                    gutterBottom
                                    component="div"
                                >
                                    {errors.annualLimitVolumesWorkers &&
                                        errors.annualLimitVolumesWorkers
                                            .message}
                                </Typography>
                            </div>
                            <div className={classes.inputBlock}>
                                <TextField
                                    fullWidth
                                    label="Limite volumi annui business"
                                    id="annualLimitVolumesBusiness"
                                    {...register("annualLimitVolumesBusiness")}
                                />
                                <Typography
                                    className={classes.error}
                                    variant="h6"
                                    gutterBottom
                                    component="div"
                                >
                                    {errors.annualLimitVolumesBusiness && (
                                        <p>
                                            {
                                                errors
                                                    .annualLimitVolumesBusiness
                                                    .message
                                            }
                                        </p>
                                    )}
                                </Typography>
                            </div>
                        </div>
                    </AccordionDetails>
                </Accordion>
            </div>
            <div className={classes.button}>
                <Button type="submit" variant="outlined">
                    Salva modifiche
                </Button>
            </div>
        </form>
    );
};

export default FormLimits;
