import React from "react";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { RequestData, AdminRequests } from "./types";
import { RootState } from "../store";
import { requestsReducer } from "./requests.reducer";

import { getFormattedRequests } from "./fetchUtils";

// Define initial state
export const requestsInitialState: AdminRequests = {
    loader: true,
    list: [],
};

// State slice
export const requestsSlice = createSlice({
    name: "requests",
    initialState: requestsInitialState,
    reducers: requestsReducer,
    extraReducers: builder => {
        let loadingToast: React.ReactText;

        builder.addCase(fetchRequests.pending, () => {
            loadingToast = toast.loading("Caricamento richieste in corso");
        });

        builder.addCase(fetchRequests.fulfilled, (state, { payload }) => {
            state.list = state.list.concat(payload);

            toast.dismiss(loadingToast);
            toast.success("Dati richieste caricati!");

            state.loader = false;
        });

        builder.addCase(fetchRequests.rejected, state => {
            toast.dismiss(loadingToast);
            toast.error("Errore caricamento dati richieste");

            state.loader = false;
        });
    },
});

// Action creators
export const {
    actions: { setLoader, addRequest, updateRequestData, reset },
} = requestsSlice;

// Getters
export const getRequests = (state: RootState): AdminRequests => state.requests;

// Async thunks
export const fetchRequests = createAsyncThunk<RequestData[], void>(
    "requests/fetchRequests",
    async (_, { rejectWithValue }) => {
        let requests: RequestData[] = [];

        try {
            requests = await getFormattedRequests();
        } catch (error) {
            rejectWithValue(error);
        }

        return requests;
    },
);
