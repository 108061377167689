import { createStyles, makeStyles } from "@mui/styles";

const useStyles = makeStyles(() =>
    createStyles({
        tableCell: {
            fontWeight: "bold",
            color: "white",
        },
        tableRowHeader: {
            backgroundColor: "#44c1be",
        },
        table: {
            minWidth: 650,
            marginTop: 20,
            marginBottom: 20,
        },
        buttonContainer: {
            width: "100%",
            display: "flex",
            justifyContent: "center",
        },
        button: {
            color: "white",
        },
        modalContainer: {
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 600,
            backgroundColor: "white",
            borderRadius: 20,
            p: 4,
        },
        flexModalHeader: {
            display: "flex",
            justifyContent: "end",
            alignItems: "center",
        },
        flexModalContainer: {
            padding: 20,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
        },
        titleContainer: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontWeight: "bold",
            fontSize: 24,
        },
        inputsContainer: {
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            // justifyContent: "space-between",
            flexWrap: "wrap",
        },
        inputBlock: {
            width: "100%",
            marginBottom: "2rem",
        },
        error: {
            cursor: "pointer",
            color: "red",
            marginBottom: 0,
            marginTop: "10px",
            fontSize: "18px",
        },
        formContainer: {
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            flexWrap: "wrap",
        },
        date: {
            width: "100%",
        },
        datePicker: {
            width: "100%",
        },
    }),
);

export default useStyles;
