import { createStyles, makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => {
    return createStyles({
        thumbsup: {
            color: "#09A822",
            height: 16,
        },
        thumbdown: {
            color: "#DB1508",
            height: 16,
        },
        capitalizeText: {
            textTransform: "capitalize",
            fontWeight: "bold",
            color: "#3B0C3D",
        },
    });
});

export default useStyles;
