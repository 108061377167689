import { createStyles, makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => {
    return createStyles({
        modalContainer: {
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 570,
            backgroundColor: "white",
            borderRadius: 20,
        },
        flexModalHeader: {
            display: "flex",
            justifyContent: "end",
            alignItems: "center",
        },
        flexModalContainer: {
            padding: 20,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
        },
        modal: {
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 570,
            backgroundColor: "white",
            textAlign: "center",
            overflowY: "scroll",
        },
        flexContainer: {
            width: "100%",
            height: "100%",
            display: "flex",
            flexWrap: "wrap",
            flexDirection: "column",
            marginTop: "10px",
        },
        headerFlex: {
            display: "flex",
            justifyContent: "end",
            alignItems: "center",
        },
        inputsContainer: {
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexWrap: "wrap",
        },
        modalTitle: {
            fontWeight: "bold",
            fontSize: 30,
            textAlign: "center",
            margin: 0,
        },
        inputFullBlock: {
            width: 330,
            marginTop: 10,
        },
        error: {
            cursor: "pointer",
            color: "red",
            marginBottom: 0,
            marginTop: "10px",
            fontSize: "18px",
        },
        button: {
            color: "#fff",
            marginTop: 30,
        },
    });
});

export default useStyles;
