import * as Yup from "yup";
import { regex } from "../constants";

const validateEmail = (errorMessage: string) =>
    Yup.string().email(errorMessage);

const validateNumber = (errorMessage: string) =>
    Yup.number().typeError(errorMessage);

const validatePrivateKey = (errorMessage: string) =>
    Yup.string().test("is-private-key-valid", errorMessage, (value = "") => {
        const isValid = regex.privateKey.test(value);

        if (!isValid) {
            return false;
        }

        return true;
    });

const validatePassword = (
    errorMessageMinLength: string,
    errorMessageMaxLength: string,
    errorMessageAtLeastOneNumber: string,
    errorMessageAtLeastOneLowercaseChar: string,
    errorMessageAtLeastOneUppercaseChar: string,
) =>
    Yup.string()
        .min(6, errorMessageMinLength)
        .max(16, errorMessageMaxLength)
        .matches(/\d/g, errorMessageAtLeastOneNumber)
        .matches(/[a-z]/g, errorMessageAtLeastOneLowercaseChar)
        .matches(/[A-Z]/g, errorMessageAtLeastOneUppercaseChar);

const required = (validation: Yup.AnySchema, errorMessage: string) =>
    validation.required(errorMessage);

export const useValidation = () => {
    return {
        validateEmail: () => validateEmail("Email non valida"),
        validatePassword: () =>
            validatePassword(
                "Password troppo corta, minimo 6 caratteri",
                "Password troppo lunga, massimo 16 caratteri",
                "La password deve contenere almeno un numero",
                "La password deve contenere lmeno una lettera minuscola",
                "La password deve contenere lmeno una lettera maiuscola",
            ),
        required: (validation: Yup.AnySchema) =>
            required(validation, "Il dato è obbligatorio"),
        validatePrivateKey: () =>
            validatePrivateKey("Chiave privata non valida"),
        validateNumber: () => validateNumber("Il valore non è numerico"),
    };
};

export default useValidation;
