import React from "react";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { mitTokenContract, provider } from "../../utils/utils";
import { transfersReducer } from "./transfers.reducer";
import { FormattedTransfer, Transfers } from "./types";
import { RootState } from "../store";

import { formatTransfer } from "./fetchUtils";

// Define initial state
export const transfersInitialState: Transfers = {
    loader: false,
    list: [],
    fromBlock: 0,
};

// State slice
export const transfersSlice = createSlice({
    name: "transfers",
    initialState: transfersInitialState,
    reducers: transfersReducer,
    extraReducers: builder => {
        let loadingToast: React.ReactText;

        builder.addCase(fetchTransfers.pending, state => {
            loadingToast = toast.loading("Caricamento trasferimenti in corso");

            state.loader = true;
        });

        builder.addCase(fetchTransfers.fulfilled, (state, { payload }) => {
            state.list = state.list.concat(payload);

            toast.dismiss(loadingToast);
            toast.success("Dati trasferimenti caricati!");

            state.loader = false;
        });

        builder.addCase(fetchTransfers.rejected, state => {
            toast.dismiss(loadingToast);
            toast.error("Errore caricamento dati trasferimenti");

            state.loader = false;
        });
    },
});

// Action creators
export const {
    actions: { setLoader, addTransfer, setFromBlock },
} = transfersSlice;

// Getters
export const getTransfers = (state: RootState): Transfers => state.transfers;

// Async thunks
export const fetchTransfers = createAsyncThunk<FormattedTransfer[], void>(
    "transfers/fetchTransfers",
    async (_, { rejectWithValue, getState, dispatch }) => {
        const {
            transfers: { fromBlock },
        } = getState() as { transfers: { fromBlock: number } };

        let transfers: FormattedTransfer[] = [];

        const transferEvent = mitTokenContract.filters.Transfer();

        try {
            const currentBlock = await provider.getBlockNumber();
            dispatch(setFromBlock(currentBlock + 1));

            const result = await mitTokenContract.queryFilter(
                transferEvent,
                fromBlock,
                "latest",
            );

            transfers = await Promise.all(
                result
                    .filter(transfer => transfer.logIndex === 0)
                    .map(async transfer => {
                        return await formatTransfer(transfer);
                    }),
            );
        } catch (error) {
            rejectWithValue(error);
        }

        return transfers;
    },
);
