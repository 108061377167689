import * as yup from "yup";
import { VipLevel } from "../../utils/types";

export type FormData = {
    vipLevels: VipLevel[];
};

export const validationSchema = yup.object().shape({
    vipLevels: yup.array(
        yup.object().shape({
            levelName: yup.string().required(),
            amount: yup.number().required(),
        }),
    ),
});

export const defaultValues = {
    vipLevels: [
        {
            levelName: "Basic",
            amount: 100,
        },
        {
            levelName: "Super",
            amount: 200,
        },
    ],
};
