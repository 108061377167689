import { useState } from "react";
import { ethers } from "ethers";
import { provider, allowanceContract } from "../../utils/utils";
import { mitToken } from "../../contracts";

import { User } from "../../store/user/types";
import { useSelector } from "react-redux";

import { safeProxyAddress } from "../../constants";

import { toast } from "react-toastify";
import { firestore } from "../../firebase";
import { doc, setDoc } from "firebase/firestore";

const overrides = {
    gasLimit: 4000000,
    gasPrice: 0,
};

const useManageUser = () => {
    const [loader, setLoader] = useState<boolean>(false);

    const {
        wallet: { privateKey },
    } = useSelector(({ user }: { user: User }) => user);

    if (!privateKey) {
        toast.error(
            "La chiave privata non é valida, le operazioni sulla blockchain sono bloccate",
            {
                toastId: "privateKeyError",
            },
        );
        return {
            loader: false,
            executeTransfer: () => null,
        };
    }

    const adminWallet = new ethers.Wallet(privateKey, provider);
    const allowanceCntSig = allowanceContract.connect(adminWallet);

    const executeTransfer = async (
        address: string,
        amount: string,
        reason?: string,
    ) => {
        if (loader) {
            toast.warning("Attendere l'operazione in corso");
            return;
        }

        setLoader(true);
        const loadingToast = toast.loading("Operazione in corso");

        const formattedAmount = ethers.utils.parseUnits(amount).toString();

        try {
            const txn = await allowanceCntSig.executeAllowanceTransfer(
                safeProxyAddress,
                mitToken.contract_address,
                address,
                formattedAmount,
                ethers.constants.AddressZero,
                0,
                adminWallet.address,
                "0x",
                overrides,
            );

            if (reason) {
                const transferReason = {
                    from: adminWallet.address,
                    to: address,
                    reason,
                };

                const docRef = doc(firestore, "transfersReasons", txn.hash);

                await setDoc(docRef, transferReason);
            }

            await txn.wait();

            toast.success(`Il trasferimento é stato eseguito`);
        } catch (e){
            console.log()
            toast.error(`Errore durante il trasferimento! Riprova più tardi.`);
        }

        toast.dismiss(loadingToast);
        setLoader(false);
    };

    return { executeTransfer, loader };
};

export default useManageUser;
