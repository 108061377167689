const currentEnv = process.env.REACT_APP_ENV;

const vipStatusABI = {
    contract_address:
        process.env[`REACT_APP_VIP_STATUS_ADDRESS_${currentEnv}`] || "",
    abi: [
        {
            inputs: [
                {
                    internalType: "address",
                    name: "initialRoleManagerAddress",
                    type: "address",
                },
            ],
            stateMutability: "nonpayable",
            type: "constructor",
        },
        {
            anonymous: false,
            inputs: [
                {
                    indexed: true,
                    internalType: "string",
                    name: "id",
                    type: "string",
                },
                {
                    indexed: true,
                    internalType: "address",
                    name: "merchant",
                    type: "address",
                },
                {
                    indexed: false,
                    internalType: "uint256",
                    name: "perc",
                    type: "uint256",
                },
                {
                    indexed: false,
                    internalType: "string",
                    name: "desc",
                    type: "string",
                },
                {
                    indexed: false,
                    internalType: "string",
                    name: "vipLevel",
                    type: "string",
                },
                {
                    indexed: false,
                    internalType: "uint256",
                    name: "exp",
                    type: "uint256",
                },
            ],
            name: "NewOffer",
            type: "event",
        },
        {
            anonymous: false,
            inputs: [
                {
                    indexed: false,
                    internalType: "string",
                    name: "name",
                    type: "string",
                },
                {
                    indexed: false,
                    internalType: "uint256",
                    name: "threshold",
                    type: "uint256",
                },
                {
                    indexed: false,
                    internalType: "address",
                    name: "by",
                    type: "address",
                },
            ],
            name: "NewVipLevelCreated",
            type: "event",
        },
        {
            anonymous: false,
            inputs: [
                {
                    indexed: false,
                    internalType: "address",
                    name: "account",
                    type: "address",
                },
            ],
            name: "Paused",
            type: "event",
        },
        {
            anonymous: false,
            inputs: [
                {
                    indexed: false,
                    internalType: "address",
                    name: "account",
                    type: "address",
                },
            ],
            name: "Unpaused",
            type: "event",
        },
        {
            anonymous: false,
            inputs: [
                {
                    indexed: true,
                    internalType: "address",
                    name: "addr",
                    type: "address",
                },
                {
                    indexed: true,
                    internalType: "string",
                    name: "id",
                    type: "string",
                },
            ],
            name: "UserRedeemedOffer",
            type: "event",
        },
        {
            anonymous: false,
            inputs: [
                {
                    indexed: false,
                    internalType: "string",
                    name: "name",
                    type: "string",
                },
                {
                    indexed: false,
                    internalType: "uint256",
                    name: "threshold",
                    type: "uint256",
                },
                {
                    indexed: false,
                    internalType: "address",
                    name: "by",
                    type: "address",
                },
            ],
            name: "VipLevelDeleted",
            type: "event",
        },
        {
            anonymous: false,
            inputs: [
                {
                    indexed: false,
                    internalType: "string",
                    name: "name",
                    type: "string",
                },
                {
                    indexed: false,
                    internalType: "uint256",
                    name: "threshold",
                    type: "uint256",
                },
                {
                    indexed: false,
                    internalType: "address",
                    name: "by",
                    type: "address",
                },
            ],
            name: "VipLevelUpdated",
            type: "event",
        },
        {
            anonymous: false,
            inputs: [
                {
                    indexed: false,
                    internalType: "address",
                    name: "newRoleManagerAddress",
                    type: "address",
                },
            ],
            name: "newRoleManagerSet",
            type: "event",
        },
        {
            inputs: [],
            name: "ACTIVE",
            outputs: [
                {
                    internalType: "bytes32",
                    name: "",
                    type: "bytes32",
                },
            ],
            stateMutability: "view",
            type: "function",
        },
        {
            inputs: [],
            name: "ADMIN",
            outputs: [
                {
                    internalType: "bytes32",
                    name: "",
                    type: "bytes32",
                },
            ],
            stateMutability: "view",
            type: "function",
        },
        {
            inputs: [],
            name: "PENDING",
            outputs: [
                {
                    internalType: "bytes32",
                    name: "",
                    type: "bytes32",
                },
            ],
            stateMutability: "view",
            type: "function",
        },
        {
            inputs: [],
            name: "SUSPENDED",
            outputs: [
                {
                    internalType: "bytes32",
                    name: "",
                    type: "bytes32",
                },
            ],
            stateMutability: "view",
            type: "function",
        },
        {
            inputs: [
                {
                    internalType: "string",
                    name: "roleName",
                    type: "string",
                },
            ],
            name: "addAccountRole",
            outputs: [],
            stateMutability: "nonpayable",
            type: "function",
        },
        {
            inputs: [
                {
                    internalType: "bytes32",
                    name: "roleHash",
                    type: "bytes32",
                },
            ],
            name: "addVipLevelRole",
            outputs: [],
            stateMutability: "nonpayable",
            type: "function",
        },
        {
            inputs: [
                {
                    internalType: "string",
                    name: "name",
                    type: "string",
                },
                {
                    internalType: "uint256",
                    name: "threshold",
                    type: "uint256",
                },
            ],
            name: "createVipLevel",
            outputs: [],
            stateMutability: "nonpayable",
            type: "function",
        },
        {
            inputs: [
                {
                    internalType: "string",
                    name: "roleName",
                    type: "string",
                },
            ],
            name: "deleteAccountRole",
            outputs: [],
            stateMutability: "nonpayable",
            type: "function",
        },
        {
            inputs: [],
            name: "deleteVipLevel",
            outputs: [],
            stateMutability: "nonpayable",
            type: "function",
        },
        {
            inputs: [],
            name: "deleteVipLevelRole",
            outputs: [],
            stateMutability: "nonpayable",
            type: "function",
        },
        {
            inputs: [
                {
                    internalType: "address",
                    name: "merchant",
                    type: "address",
                },
            ],
            name: "getOffersByMerchant",
            outputs: [
                {
                    internalType: "string[]",
                    name: "",
                    type: "string[]",
                },
            ],
            stateMutability: "view",
            type: "function",
        },
        {
            inputs: [],
            name: "getOffersIDs",
            outputs: [
                {
                    internalType: "string[]",
                    name: "",
                    type: "string[]",
                },
            ],
            stateMutability: "view",
            type: "function",
        },
        {
            inputs: [
                {
                    internalType: "address",
                    name: "consumer",
                    type: "address",
                },
            ],
            name: "getOffersRedeemedByUser",
            outputs: [
                {
                    internalType: "string[]",
                    name: "",
                    type: "string[]",
                },
            ],
            stateMutability: "view",
            type: "function",
        },
        {
            inputs: [],
            name: "getVipLevels",
            outputs: [
                {
                    components: [
                        {
                            internalType: "string",
                            name: "name",
                            type: "string",
                        },
                        {
                            internalType: "uint256",
                            name: "threshold",
                            type: "uint256",
                        },
                    ],
                    internalType: "struct VipStatus.VipLevelInfo[]",
                    name: "",
                    type: "tuple[]",
                },
            ],
            stateMutability: "view",
            type: "function",
        },
        {
            inputs: [
                {
                    internalType: "string",
                    name: "name",
                    type: "string",
                },
                {
                    internalType: "uint8",
                    name: "firstThreshold",
                    type: "uint8",
                },
            ],
            name: "initialize",
            outputs: [],
            stateMutability: "nonpayable",
            type: "function",
        },
        {
            inputs: [
                {
                    internalType: "string",
                    name: "id",
                    type: "string",
                },
                {
                    internalType: "address",
                    name: "merchant",
                    type: "address",
                },
                {
                    internalType: "uint256",
                    name: "perc",
                    type: "uint256",
                },
                {
                    internalType: "string",
                    name: "desc",
                    type: "string",
                },
                {
                    internalType: "string",
                    name: "vipLevel",
                    type: "string",
                },
                {
                    internalType: "uint256",
                    name: "exp",
                    type: "uint256",
                },
            ],
            name: "modifyOrAddOffer",
            outputs: [],
            stateMutability: "nonpayable",
            type: "function",
        },
        {
            inputs: [
                {
                    internalType: "string",
                    name: "",
                    type: "string",
                },
            ],
            name: "offers",
            outputs: [
                {
                    internalType: "uint256",
                    name: "perc",
                    type: "uint256",
                },
                {
                    internalType: "string",
                    name: "description",
                    type: "string",
                },
                {
                    internalType: "address",
                    name: "merchant",
                    type: "address",
                },
                {
                    internalType: "string",
                    name: "vipLevel",
                    type: "string",
                },
                {
                    internalType: "uint256",
                    name: "expiryDate",
                    type: "uint256",
                },
            ],
            stateMutability: "view",
            type: "function",
        },
        {
            inputs: [],
            name: "pause",
            outputs: [],
            stateMutability: "nonpayable",
            type: "function",
        },
        {
            inputs: [],
            name: "paused",
            outputs: [
                {
                    internalType: "bool",
                    name: "",
                    type: "bool",
                },
            ],
            stateMutability: "view",
            type: "function",
        },
        {
            inputs: [
                {
                    internalType: "string",
                    name: "id",
                    type: "string",
                },
            ],
            name: "redeemOffer",
            outputs: [],
            stateMutability: "nonpayable",
            type: "function",
        },
        {
            inputs: [
                {
                    internalType: "bytes32[]",
                    name: "allAccountRoles",
                    type: "bytes32[]",
                },
            ],
            name: "setAccountRoles",
            outputs: [],
            stateMutability: "nonpayable",
            type: "function",
        },
        {
            inputs: [
                {
                    internalType: "address",
                    name: "newRoleManagerAddress",
                    type: "address",
                },
            ],
            name: "setNewRoleManager",
            outputs: [],
            stateMutability: "nonpayable",
            type: "function",
        },
        {
            inputs: [
                {
                    internalType: "address",
                    name: "newSafeAddress",
                    type: "address",
                },
            ],
            name: "setSafeAddress",
            outputs: [],
            stateMutability: "nonpayable",
            type: "function",
        },
        {
            inputs: [
                {
                    internalType: "bytes32[]",
                    name: "vipLevels",
                    type: "bytes32[]",
                },
            ],
            name: "setVipLevels",
            outputs: [],
            stateMutability: "nonpayable",
            type: "function",
        },
        {
            inputs: [],
            name: "unpause",
            outputs: [],
            stateMutability: "nonpayable",
            type: "function",
        },
        {
            inputs: [
                {
                    internalType: "uint256",
                    name: "index",
                    type: "uint256",
                },
                {
                    internalType: "string",
                    name: "name",
                    type: "string",
                },
                {
                    internalType: "uint256",
                    name: "threshold",
                    type: "uint256",
                },
            ],
            name: "updateVipLevel",
            outputs: [],
            stateMutability: "nonpayable",
            type: "function",
        },
    ],
};

export default vipStatusABI;
