import {
    deleteObject,
    getBlob,
    getDownloadURL,
    getStorage,
    listAll,
    ref,
    uploadBytes,
} from "firebase/storage";
import { useEffect, useState } from "react";
import { usePrevious } from "../utils/hooks";

const useStorage = (uploadPath?: string) => {
    const storage = getStorage();

    const [returnPathLoader, setReturnPathLoader] = useState(false);
    const [areFilesToBeReloaded, setAreFilesToBeReloaded] = useState(false);
    const [files, setFiles] = useState([""]);
    const prevAreFilesToBeReloaded = usePrevious(areFilesToBeReloaded);

    const upload = async (files: File[]) => {
        await Promise.all(
            Object.values(files).map((file, key) => {
                const storageRef: any = ref(
                    storage,
                    `${uploadPath}/${file.name}`,
                );
                return uploadBytes(storageRef, files[key]);
            }),
        );

        setAreFilesToBeReloaded(true);
    };

    const getAllFileUploaded = async (path: string) => {
        const listRef = ref(storage, path);
        const files: any = [];

        const list = await listAll(listRef).catch(error => {
            console.log(error);
        });

        if (list) {
            list.items.forEach(async (itemRef: any) => {
                const storagePath = itemRef["_location"]["path_"];
                files.push(storagePath);
            });
        }

        return files;
    };

    const deleteFile = async (path: string) => {
        const desertRef = ref(storage, path);

        await deleteObject(desertRef).catch(error => {
            console.log(error);
        });

        setAreFilesToBeReloaded(true);
    };

    const getFilePath = async (filePath: string) => {
        setReturnPathLoader(true);
        const storage = getStorage();
        const fileRef = ref(storage, filePath);

        let path = "";

        try {
            path = await getDownloadURL(fileRef);
        } catch (error) {
            path = "";
        }

        return path;
    };

    const getFileBlob = async (filePath: string) => {
        const storage = getStorage();
        const fileRef = ref(storage, filePath);

        let blob: Blob | undefined = undefined;

        try {
            blob = await getBlob(fileRef);
        } catch (error) {
            console.log(error);
        }

        return blob;
    };

    const initialize = async () => {
        let uploadedFiles = [""];
        if (uploadPath) {
            uploadedFiles = await getAllFileUploaded(uploadPath);
        }
        setFiles(uploadedFiles);
        setAreFilesToBeReloaded(false);
    };

    useEffect(() => {
        if (
            (prevAreFilesToBeReloaded === false &&
                areFilesToBeReloaded === true) ||
            !prevAreFilesToBeReloaded
        ) {
            initialize();
        }
    }, [areFilesToBeReloaded]);

    return {
        upload,
        files,
        getFilePath,
        getFileBlob,
        deleteFile,
        returnPathLoader,
        getAllFileUploaded,
    };
};

export default useStorage;
