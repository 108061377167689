import React from "react";
import { IconButton, Modal } from "@mui/material";
import { Box } from "@mui/system";
import ClearIcon from "@mui/icons-material/Clear";

import useStyles from "./style";

type Props = {
    open: boolean;
    handleClose: () => void;
    filePath: string;
};

const ModalDocument: React.FC<Props> = ({ open, handleClose, filePath }) => {
    const classes = useStyles();

    return (
        <Modal open={open} onClose={handleClose}>
            <Box className={classes.modalContainer}>
                <div className={classes.flexModalHeader}>
                    <IconButton onClick={handleClose}>
                        <ClearIcon />
                    </IconButton>
                </div>
                <div className={classes.flexModalContainer}>
                    <img src={filePath} width="auto" height="700px" />
                </div>
            </Box>
        </Modal>
    );
};

export default ModalDocument;
