import { useState, useEffect } from "react";
import { ethers } from "ethers";
import { toast } from "react-toastify";

import { User } from "../../store/user/types";
import { useSelector } from "react-redux";
import vipStatusABI from "../../contracts/vipStatus";
import { firestore } from "../../firebase";

import { getUsersState } from "../../store/users/users.slice";

import { doc, setDoc, collection } from "firebase/firestore";

import { provider } from "../../constants";

const vipStatusContract = new ethers.Contract(
    vipStatusABI.contract_address,
    vipStatusABI.abi,
    provider,
);

const overrides = {
    gasLimit: 4000000,
    gasPrice: 0,
};

type OfferData = {
    id: string;
    perc: number;
    desc: string;
    vipLevel: string;
    exp: number;
};

type FormattedOffer = {
    offerId: string;
    discountAmount: number;
    vipLevel: string;
    description: string;
    expirationDate: string;
    status: boolean;
};

const useDiscounts = (address: string) => {
    const {
        wallet: { privateKey },
    } = useSelector(({ user }: { user: User }) => user);

    const [loader, setLoader] = useState(false);
    const [offers, setOffers] = useState<FormattedOffer[]>([]);

    const { list: users } = useSelector(getUsersState);

    const merchantUid = users.find(user => user.walletAddress === address)?.id;

    if (!privateKey) {
        toast.error(
            "La chiave privata non é valida, le operazioni sulla blockchain sono bloccate",
            {
                toastId: "privateKeyError",
            },
        );
        return {
            offers: [],
            createDiscount: () => null,
        };
    }

    const adminWallet = new ethers.Wallet(privateKey, provider);
    const contractWithSigner = vipStatusContract.connect(adminWallet);

    const createDiscount: (offer: OfferData) => void = async ({
        id,
        perc,
        desc,
        vipLevel,
        exp,
    }) => {
        if (loader) {
            toast.warning("Attendere l'operazione in corso");
            return;
        }

        setLoader(true);
        const loadingToast = toast.loading("Operazione in corso");

        const docRef = doc(collection(firestore, "discounts"));

        const txn = await contractWithSigner.modifyOrAddOffer(
            docRef.id,
            address,
            perc,
            desc,
            `LEVEL_${vipLevel}`,
            exp,
            overrides,
        );

        try {
            await txn.wait();
            await setDoc(docRef, {
                codeToRedeem: id,
                merchantRef: merchantUid,
            });
            toast.success(`Offerta creata con successo!`);
        } catch (error) {
            console.log(error);
            toast.error(
                `Errore durante la creazione dell'offerta! Riprova più tardi.`,
            );
        }

        toast.dismiss(loadingToast);
        setLoader(false);
    };

    const getOffers = async () => {
        const offers = await vipStatusContract
            .getOffersByMerchant(address)
            .then(async (offers: string[]) => {
                const today = new Date();

                const formattedData = await Promise.all(
                    offers.map(async (offerId: string) => {
                        const offer = await vipStatusContract.offers(offerId);

                        const expirationDate = new Date(
                            offer.expiryDate.toNumber() * 1000,
                        );

                        return {
                            offerId: offerId,
                            discountAmount: `${offer.perc.toNumber()}%`,
                            vipLevel: offer.vipLevel.split("_").pop(),
                            description: offer.description,
                            expirationDate: new Date(
                                offer.expiryDate.toNumber() * 1000,
                            ).toLocaleDateString(),
                            status: today > expirationDate,
                        };
                    }),
                );

                return formattedData;
            });

        return offers;
    };

    const initialize = async () => {
        const offers = await getOffers();

        setOffers(offers);
    };

    useEffect(() => {
        initialize();

        vipStatusContract.on("NewOffer", () => {
            initialize();
        });

        return () => {
            vipStatusContract.removeAllListeners();
        };
    }, []);

    return {
        offers,
        createDiscount,
    };
};

export default useDiscounts;
