import { Theme } from "@mui/material/styles";
import { tableCellClasses } from "@mui/material/TableCell";
import { createStyles, makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) => {
    return createStyles({
        tableCell: {
            [`&.${tableCellClasses.head}`]: {
                backgroundColor: theme.palette.primary.main,
                color: theme.palette.common.white,
                fontWeight: "bold",
            },
            [`&.${tableCellClasses.body}`]: {
                fontSize: 14,
            },
        },
        tableRow: {
            "&:nth-of-type(odd)": {
                backgroundColor: theme.palette.action.hover,
            },
            // hide last border
            "&:last-child td, &:last-child th": {
                border: 0,
            },
        },
        info: {
            marginLeft: "10px",
            textDecoration: "underline",
            cursor: "pointer",
        },
        detailsLink: {
            display: "flex",
            alignItems: "center",
        },
    });
});

export default useStyles;
