import { doc, getDoc } from "firebase/firestore";

import { firestore } from "../../firebase";
import { Limits } from "./types";

// Functions that fetch firestore data
export const getLimits = async () => {
    const docRef = doc(firestore, "settings", "limits");

    const snapshot = await getDoc(docRef);

    const formattedDoc = snapshot.data();

    return formattedDoc as Limits;
};
