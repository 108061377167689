import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
    flexContainer: {
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexWrap: "wrap",
        flexDirection: "column",
    },
}));

export default useStyles;
