import { PayloadAction } from "@reduxjs/toolkit";
import { User } from "./types";

export const userReducer = {
    setJwtToken: {
        reducer: (
            state: User,
            action: PayloadAction<{
                jwtToken: string;
            }>,
        ): void => {
            state.jwtToken = action.payload.jwtToken;
        },
        prepare: (jwtToken: string) => ({
            payload: {
                jwtToken,
            },
        }),
    },
    setEmail: {
        reducer: (
            state: User,
            action: PayloadAction<{
                email: string;
            }>,
        ): void => {
            state.email = action.payload.email;
        },
        prepare: (email: string) => ({
            payload: {
                email,
            },
        }),
    },
    setWallet: {
        reducer: (
            state: User,
            action: PayloadAction<{
                wallet: {
                    address: string;
                    privateKey: string;
                    mnemonic: string;
                };
            }>,
        ): void => {
            state.wallet = action.payload.wallet;
        },
        prepare: (wallet: {
            address: string;
            privateKey: string;
            mnemonic: string;
        }) => ({
            payload: {
                wallet,
            },
        }),
    },
    logout: (state: User) => {
        state.jwtToken = "";
    },
};
