import { Theme } from "@mui/material/styles";
import { createStyles, makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) => {
    return createStyles({
        drawer: {
            width: theme.drawerWidth,
            flexShrink: 0,
            "& .MuiDrawer-paper": {
                width: theme.drawerWidth,
                boxSizing: "border-box",
                position: "relative",
                backgroundColor: theme.palette.primary.main,
            },
        },
        icon: {
            color: theme.palette.primary.light,
        },
        text: {
            color: theme.palette.primary.light,
        },
        circleUser: {
            color: theme.palette.primary.light,
            borderRadius: "50%",
            border: `0.5rem solid ${theme.palette.primary.light}`,
            padding: "0.5rem",
            fontSize: "8rem",
        },
        boxLogo: {
            paddingTop: "1.5rem",
            display: "flex",
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
        },
    });
});

export default useStyles;
