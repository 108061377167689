import { onSnapshot, doc } from "@firebase/firestore";
import { createListenerMiddleware, Unsubscribe } from "@reduxjs/toolkit";
import { firestore } from "../../firebase";
import { Limits } from "./types";

import { setJwtToken, logout } from "../user/user.slice";
import { reset, fetchLimits, updateLimitsData } from "./settings.slice";

const settingsMiddleware = createListenerMiddleware();

let unsubLimits: Unsubscribe;

settingsMiddleware.startListening({
    actionCreator: setJwtToken,
    effect: async (_, listenerApi) => {
        listenerApi.dispatch(fetchLimits());
    },
});

settingsMiddleware.startListening({
    actionCreator: fetchLimits.fulfilled,
    effect: async (_, listenerApi) => {
        // Firestore listeners
        unsubLimits = onSnapshot(doc(firestore, "settings", "limits"), doc => {
            const newDocData = doc.data() as Limits;

            if (newDocData) {
                listenerApi.dispatch(updateLimitsData(newDocData));
            }
        });
    },
});

settingsMiddleware.startListening({
    actionCreator: logout,
    effect: async (_, listenerApi) => {
        listenerApi.dispatch(reset());
        unsubLimits();
    },
});

export default settingsMiddleware;
