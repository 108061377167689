import { PayloadAction } from "@reduxjs/toolkit";
import { Transfers, FormattedTransfer } from "./types";

export const transfersReducer = {
    setLoader: {
        reducer: (
            state: Transfers,
            action: PayloadAction<{
                value: boolean;
            }>,
        ): void => {
            state.loader = action.payload.value;
        },
        prepare: (value: boolean) => ({
            payload: {
                value,
            },
        }),
    },
    setFromBlock: {
        reducer: (
            state: Transfers,
            action: PayloadAction<{
                value: number;
            }>,
        ): void => {
            state.fromBlock = action.payload.value;
        },
        prepare: (value: number) => ({
            payload: {
                value,
            },
        }),
    },
    addTransfer: {
        reducer: (
            state: Transfers,
            action: PayloadAction<{
                transfers: FormattedTransfer;
            }>,
        ): void => {
            state.list.push(action.payload.transfers);
        },
        prepare: (transfers: FormattedTransfer) => ({
            payload: {
                transfers,
            },
        }),
    },
};
