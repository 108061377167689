import { collection, getDocs, query, where } from "firebase/firestore";
import { firestore } from "../firebase";

const useCheckAddress = () => {
    const checkIfAddressExist = async (reference: string) => {
        const publicProfilesRef = collection(firestore, "publicProfiles");

        // const fieldToSearch = reference[0] === '@' ? 'nickname' : 'walletAddress';
        // const q = query(publicProfilesRef, where(fieldToSearch, '==', reference));

        const q = query(
            publicProfilesRef,
            where("walletAddress", "==", reference),
        );

        const querySnapshot = await getDocs(q);

        if (querySnapshot.docs.length > 0) {
            return true;
        }

        return false;
    };

    return { checkIfAddressExist };
};

export default useCheckAddress;
