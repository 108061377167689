import Typography from "@mui/material/Typography";
import React from "react";
import MTDrawer from "../components/MTDrawer";
import MTHeader from "../components/MTHeader";
import styles from "./Layout.module.scss";

type Props = {
    children: JSX.Element;
};

const Layout: React.FC<Props> = ({ children }) => {
    return (
        <React.Fragment>
            <MTDrawer />
            <div className={styles.navigationWrapper}>
                <MTHeader />
                <main className={styles.mainContent}>{children}</main>
                <div className={styles.footer}>
                    <Typography
                        sx={{
                            fontSize: 15,
                            marginBottom: 0,
                            lineHeight: 2,
                        }}
                        variant="h6"
                        gutterBottom
                        component="div"
                    >
                        Copyright mitgate 2021
                    </Typography>
                </div>
            </div>
        </React.Fragment>
    );
};
export default Layout;
