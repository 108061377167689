import React from "react";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { Settings, Limits } from "./types";
import { RootState } from "../store";
import { settingsReducer } from "./settings.reducer";

import { getLimits } from "./fetchUtils";

// Define initial state
export const settingsInitialState: Settings = {
    loader: true,
    limits: {
        maxVolume: 0,
        annualLimitVolumesBusiness: 0,
        annualLimitVolumesRetirees: 0,
        annualLimitVolumesStudents: 0,
        annualLimitVolumesWorkers: 0,
        annualLimitVolumesUnemployed: 0,
        maxSingleOperation: 0,
        maxSingleOperationBusiness: 0,
        maxSingleOperationRetirees: 0,
        maxSingleOperationStudents: 0,
        maxSingleOperationWorkers: 0,
        maxSingleOperationUnemployed: 0,
    },
};

// State slice
export const settingsSlice = createSlice({
    name: "settings",
    initialState: settingsInitialState,
    reducers: settingsReducer,
    extraReducers: builder => {
        let loadingToast: React.ReactText;

        builder.addCase(fetchLimits.pending, () => {
            loadingToast = toast.loading("Caricamento limiti in corso");
        });

        builder.addCase(fetchLimits.fulfilled, (state, { payload }) => {
            state.limits = payload;

            toast.dismiss(loadingToast);
            toast.success("Dati limiti caricati!");

            state.loader = false;
        });

        builder.addCase(fetchLimits.rejected, state => {
            toast.dismiss(loadingToast);
            toast.error("Errore caricamento dati limiti");

            state.loader = false;
        });
    },
});

// Action creators
export const {
    actions: { updateLimitsData, reset },
} = settingsSlice;

// Getters
export const getSettingsState = (state: RootState): Settings => state.settings;

// Async thunks
export const fetchLimits = createAsyncThunk<Limits, void>(
    "settings/fetchLimits",
    async (_, { rejectWithValue }) => {
        let limits: Limits = {
            maxVolume: 0,
            annualLimitVolumesBusiness: 0,
            annualLimitVolumesRetirees: 0,
            annualLimitVolumesStudents: 0,
            annualLimitVolumesWorkers: 0,
            annualLimitVolumesUnemployed: 0,
            maxSingleOperation: 0,
            maxSingleOperationBusiness: 0,
            maxSingleOperationRetirees: 0,
            maxSingleOperationStudents: 0,
            maxSingleOperationWorkers: 0,
            maxSingleOperationUnemployed: 0,
        };

        try {
            limits = await getLimits();
        } catch (error) {
            rejectWithValue(error);
        }

        return limits;
    },
);
