import React, { useState } from "react";
import {
    Button,
    IconButton,
    MenuItem,
    Modal,
    Select,
    SelectChangeEvent,
} from "@mui/material";
import { Box } from "@mui/system";
import ClearIcon from "@mui/icons-material/Clear";

import useStyles from "./style";
import MinimumVotations from "./RequestForms/MinimumVotations";
import EditPlafondLimit from "./RequestForms/EditPlafondLimit";
import AccreditMit from "./RequestForms/AccreditMit";

type Props = {
    open: boolean;
    handleClose: () => void;
};

const CreateRequestModal: React.FC<Props> = ({ open, handleClose }) => {
    const classes = useStyles();
    const [type, setType] = useState("minVotes");

    const handleChange = (event: SelectChangeEvent) => {
        setType(event.target.value as string);
    };

    const handleResetLimit = () => {
        //TODO: manage reset platfond limit
    };

    let componentToRender;

    switch (type) {
        case "minVotes":
            componentToRender = <MinimumVotations handleClose={handleClose} />;
            break;
        case "resetPlatfondLimit":
            componentToRender = (
                <Button
                    variant="contained"
                    onClick={handleResetLimit}
                    className={classes.button}
                >
                    Invia richiesta
                </Button>
            );
            break;
        case "editPlatfondLimit":
            componentToRender = <EditPlafondLimit handleClose={handleClose} />;
            break;
        case "accreditMit":
            componentToRender = <AccreditMit handleClose={handleClose} />;
            break;
    }

    return (
        <Modal open={open} onClose={handleClose}>
            <Box className={classes.modalContainer}>
                <div className={classes.flexModalHeader}>
                    <IconButton onClick={handleClose}>
                        <ClearIcon />
                    </IconButton>
                </div>
                <h5 className={classes.modalTitle}>Invia richiesta</h5>
                <div className={classes.flexModalContainer}>
                    <p>
                        Seleziona tipologia richiesta da inviare al board degli
                        admin
                    </p>
                    <div className={classes.inputFullBlock}>
                        <Select
                            value={type}
                            className={classes.inputFullBlock}
                            onChange={handleChange}
                        >
                            <MenuItem value="minVotes">
                                Votazioni minime
                            </MenuItem>
                            <MenuItem value="editPlatfondLimit">
                                Modifica limite plafond
                            </MenuItem>
                            <MenuItem value="accreditMit">
                                Accredito MIT
                            </MenuItem>
                        </Select>
                    </div>
                    {componentToRender}
                </div>
            </Box>
        </Modal>
    );
};

export default CreateRequestModal;
